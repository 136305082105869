<template>
  <div id="backend-side-bar">
<div class="sidebar" data-color="purple" data-background-color="white" data-image="../../assets/img/sidebar-1.jpg">
      <!--
        Tip 1: You can change the color of the sidebar using: data-color="purple | azure | green | orange | danger"

        Tip 2: you can also add an image using data-image tag
    -->
      <div class="logo"  align="center"><a href="/">
      <img class="logo-dark" :src="school_logo" alt="" width="50px"/>
      <br> <span style="margin-top: 10px !important; padding-top: 10px !important; font-size:20px; font-weight: bold;">{{school_name}}</span>
      </a></div>
      <div class="sidebar-wrapper">
        <ul class="nav">
         <li class="nav-item active">
            <a class="nav-link"  v-on:click="linkToPortfolio(links.admin_dashboard.value)">
              <i class="material-icons">dashboard</i>
              <p>{{ links.admin_dashboard.name }}</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-on:click="linkToPortfolio(links.admin_craete_results.value)">
              <i class="material-icons">language</i>
              <p>{{ links.admin_craete_results.name }}</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-on:click="linkToPortfolio(links.admin_view_results.value)">
              <i class="material-icons">language</i>
              <p>{{ links.admin_view_results.name }}</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  v-on:click="linkToPortfolio(links.admin_craete_account.value)">
              <i class="material-icons">person</i>
              <p>{{ links.admin_craete_account.name }}</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  v-on:click="linkToPortfolio(links.admin_view_accounts.value)">
              <i class="material-icons">library_books</i>
              <p>{{ links.admin_view_accounts.name }}</p>
            </a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link" v-on:click="linkToPortfolio(links.view_school_result_reports.value)">
              <i class="material-icons">language</i>
              <p>{{ links.view_school_result_reports.name }}</p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  v-on:click="linkToPortfolio(links.admin_change_password.value)">
              <i class="material-icons">insert_link</i>
              <p>{{ links.admin_change_password.name }}</p>
            </a>-
          </li>
          <!-- Strat CPanel Menus -->
          <li class="nav-item" style="background-color:#cccccc">
            <a class="nav-link"  href="#">
              <i class="material-icons">insert_link</i>
              <p align="justify"><b>Go to CPANEL Account</b></p>
            </a>-
          </li>
          <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/school-web/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Cpanel Dashboard</p>
            </a>-
          </li>
          <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/subscription/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Pay Subscription</p>
            </a>-
          </li>
          <!-- <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/payment/school-transaction-list/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Subscriptions & Transactions</p>
            </a>-
          </li> -->
          <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/referral/school-list/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Partners/Affiliates</p>
            </a>-
          </li>
          <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/ticket/school/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Open Tickets</p>
            </a>-
          </li>
          <!-- <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/api/profile/school-profile/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>View School profile</p>
            </a>-
          </li> -->
          <!-- <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/api/profile/school-edit/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Edit your School profile</p>
            </a>-
          </li> -->
           <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/email-settings/add-details/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Add Email Details</p>
            </a>-
          </li>
          <li class="nav-item" >
            <a class="nav-link"  href="http://www.ijcschool.com.ng/api/profile/school-bank-details/" target="blank">
              <i class="material-icons">insert_link</i>
              <p>Add Bank Details</p>
            </a>-
          </li>
          <!-- End CPanel Menus -->
           <li class="nav-item">
            <a class="nav-link"   @click="onLogout">
              <i class="material-icons">eject</i>
              <p>{{ links.logout.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
 <script type="application/javascript" defer src="../../assets/js/core/jquery.min.js"></script>
  <script type="application/javascript" defer src="../../assets/js/core/popper.min.js"></script>
  <script type="application/javascript" defer src="../../assets/js/core/bootstrap-material-design.min.js"></script>
  <script type="application/javascript" defer src="../../assets/js/plugins/perfect-scrollbar.jquery.min.js"></script>
  <!-- Plugin for the momentJs  -->
  <script type="application/javascript" defer src="../../assets/js/plugins/moment.min.js"></script>
  <!--  Plugin for Sweet Alert -->
  <script type="application/javascript" defer src="../../assets/js/plugins/sweetalert2.js"></script>
  <!-- Forms Validations Plugin -->
  <script type="application/javascript" defer src="../../assets/js/plugins/jquery.validate.min.js"></script>
  <!-- Plugin for the Wizard, full documentation here: https://github.com/VinceG/twitter-bootstrap-wizard -->
  <script type="application/javascript" defer src="../../assets/js/plugins/jquery.bootstrap-wizard.js"></script>
  <!--	Plugin for Select, full documentation here: http://silviomoreto.github.io/bootstrap-select -->
  <script type="application/javascript" defer src="../../assets/js/plugins/bootstrap-selectpicker.js"></script>
  <!--  Plugin for the DateTimePicker, full documentation here: https://eonasdan.github.io/bootstrap-datetimepicker/ -->
  <script type="application/javascript" defer src="../../assets/js/plugins/bootstrap-datetimepicker.min.js"></script>
  <!--  DataTables.net Plugin, full documentation here: https://datatables.net/  -->
  <script type="application/javascript" defer src="../../assets/js/plugins/jquery.dataTables.min.js"></script>
  <!--	Plugin for Tags, full documentation here: https://github.com/bootstrap-tagsinput/bootstrap-tagsinputs  -->
  <script type="application/javascript" defer src="../../assets/js/plugins/bootstrap-tagsinput.js"></script>
  <!-- Plugin for Fileupload, full documentation here: http://www.jasny.net/bootstrap/javascript/#fileinput -->
  <script type="application/javascript" defer src="../../assets/js/plugins/jasny-bootstrap.min.js"></script>
  <!--  Full Calendar Plugin, full documentation here: https://github.com/fullcalendar/fullcalendar    -->
  <script type="application/javascript" defer src="../../assets/js/plugins/fullcalendar.min.js"></script>
  <!-- Vector Map plugin, full documentation here: http://jvectormap.com/documentation/ -->
  <script type="application/javascript" defer src="../../assets/js/plugins/jquery-jvectormap.js"></script>
  <!--  Plugin for the Sliders, full documentation here: http://refreshless.com/nouislider/ -->
  <script type="application/javascript" defer src="../../assets/js/plugins/nouislider.min.js"></script>
  <!-- Include a polyfill for ES6 Promises (optional) for IE11, UC Browser and Android browser support SweetAlert -->
  <script type="application/javascript" defer src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.4.1/core.js"></script>
  <!-- Library for adding dinamically elements -->
  <script type="application/javascript" defer src="../../assets/js/plugins/arrive.min.js"></script>
  <!--  Google Maps Plugin    -->
  <script type="application/javascript" defer src="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"></script>
  <!-- Chartist JS -->
  <script type="application/javascript" defer src="../../assets/js/plugins/chartist.min.js"></script>
  <!--  Notifications Plugin    -->
  <script type="application/javascript" defer src="../../assets/js/plugins/bootstrap-notify.js"></script>
  <!-- Control Center for Material Dashboard: parallax effects, scripts for the example pages etc -->
    <script type="application/javascript" defer src="../../assets/js/material-dashboard.js?v=2.1.2"></script>
    <script type="application/javascript" defer>
      $(document).ready(function() {
          $().ready(function() {
            $sidebar = $('.sidebar');

            $sidebar_img_container = $sidebar.find('.sidebar-background');

            $full_page = $('.full-page');

            $sidebar_responsive = $('body > .navbar-collapse');

            window_width = $(window).width();

            fixed_plugin_open = $('.sidebar .sidebar-wrapper .nav li.active a p').html();

            if (window_width > 767 && fixed_plugin_open == 'Dashboard') {
              if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
                $('.fixed-plugin .dropdown').addClass('open');
              }

            }

            $('.fixed-plugin a').click(function(event) {
              // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
              if ($(this).hasClass('switch-trigger')) {
                if (event.stopPropagation) {
                  event.stopPropagation();
                } else if (window.event) {
                  window.event.cancelBubble = true;
                }
              }
            });

            $('.fixed-plugin .active-color span').click(function() {
              $full_page_background = $('.full-page-background');

              $(this).siblings().removeClass('active');
              $(this).addClass('active');

              var new_color = $(this).data('color');

              if ($sidebar.length != 0) {
                $sidebar.attr('data-color', new_color);
              }

              if ($full_page.length != 0) {
                $full_page.attr('filter-color', new_color);
              }

              if ($sidebar_responsive.length != 0) {
                $sidebar_responsive.attr('data-color', new_color);
              }
            });

            $('.fixed-plugin .background-color .badge').click(function() {
              $(this).siblings().removeClass('active');
              $(this).addClass('active');

              var new_color = $(this).data('background-color');

              if ($sidebar.length != 0) {
                $sidebar.attr('data-background-color', new_color);
              }
            });

            $('.fixed-plugin .img-holder').click(function() {
              $full_page_background = $('.full-page-background');

              $(this).parent('li').siblings().removeClass('active');
              $(this).parent('li').addClass('active');


              var new_image = $(this).find("img").attr('src');

              if ($sidebar_img_container.length != 0 && $('.switch-sidebar-image input:checked').length != 0) {
                $sidebar_img_container.fadeOut('fast', function() {
                  $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
                  $sidebar_img_container.fadeIn('fast');
                });
              }

              if ($full_page_background.length != 0 && $('.switch-sidebar-image input:checked').length != 0) {
                var new_image_full_page = $('.fixed-plugin li.active .img-holder').find('img').data('src');

                $full_page_background.fadeOut('fast', function() {
                  $full_page_background.css('background-image', 'url("' + new_image_full_page + '")');
                  $full_page_background.fadeIn('fast');
                });
              }

              if ($('.switch-sidebar-image input:checked').length == 0) {
                var new_image = $('.fixed-plugin li.active .img-holder').find("img").attr('src');
                var new_image_full_page = $('.fixed-plugin li.active .img-holder').find('img').data('src');

                $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
                $full_page_background.css('background-image', 'url("' + new_image_full_page + '")');
              }

              if ($sidebar_responsive.length != 0) {
                $sidebar_responsive.css('background-image', 'url("' + new_image + '")');
              }
            });

            $('.switch-sidebar-image input').change(function() {
              $full_page_background = $('.full-page-background');

              $input = $(this);

              if ($input.is(':checked')) {
                if ($sidebar_img_container.length != 0) {
                  $sidebar_img_container.fadeIn('fast');
                  $sidebar.attr('data-image', '#');
                }

                if ($full_page_background.length != 0) {
                  $full_page_background.fadeIn('fast');
                  $full_page.attr('data-image', '#');
                }

                background_image = true;
              } else {
                if ($sidebar_img_container.length != 0) {
                  $sidebar.removeAttr('data-image');
                  $sidebar_img_container.fadeOut('fast');
                }

                if ($full_page_background.length != 0) {
                  $full_page.removeAttr('data-image', '#');
                  $full_page_background.fadeOut('fast');
                }

                background_image = false;
              }
            });

            $('.switch-sidebar-mini input').change(function() {
              $body = $('body');

              $input = $(this);

              if (md.misc.sidebar_mini_active == true) {
                $('body').removeClass('sidebar-mini');
                md.misc.sidebar_mini_active = false;

                $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();

              } else {

                $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar('destroy');

                setTimeout(function() {
                  $('body').addClass('sidebar-mini');

                  md.misc.sidebar_mini_active = true;
                }, 300);
              }

              // we simulate the window Resize so the charts will get updated in realtime.
              var simulateWindowResize = setInterval(function() {
                window.dispatchEvent(new Event('resize'));
              }, 180);

              // we stop the simulation of Window Resize after the animations are completed
              setTimeout(function() {
                clearInterval(simulateWindowResize);
              }, 1000);

            });
          });
        });
    </script>
  </div>
</template>

<script>
import '@/assets/css/material-dashboard.css';
import '@/assets/css/material-dashboard.css?v=2.1.2';

export default {
    name: "BackendSideBar",
 data () {
    return {
      links: {
        admin_dashboard: {
          name: '',
          value: ''
        },
        admin_craete_account: {
          name: '',
          value: ''
        },
         admin_craete_results: {
          name: '',
          value: ''
        },
         view_school_result_reports: {
          name: '',
          value: ''
        },
         admin_view_results: {
          name: '',
          value: ''
        },
         admin_view_accounts: {
          name: '',
          value: ''
        },
        admin_change_password: {
          name: '',
          value: ''
        },
        logout: {
          name: '',
         // value: ''
        }
      },
        school_name: '',
        school_logo: ''
    }
  },
  created () {
    this.links.admin_dashboard = {
      name: 'Dashboard',
      value: 'admin_dashboard'
    }
    this.links.admin_craete_account = {
      name: 'Create Accounts',
      value: 'admin_craete_account'
    }
     this.links.admin_craete_results = {
      name: 'Create New Result Templates',
      value: 'admin_craete_results'
    }
     this.links.view_school_result_reports = {
      name: 'View/Edit School Result Reports',
      value: 'view_school_result_reports'
    }
    this.links.admin_view_results = {
      name: 'View & Edit Result Templates',
      value: 'admin_view_results'
    }
     this.links.admin_view_accounts = {
      name: 'View & Edit Accounts',
      value: 'admin_view_accounts'
    }
    this.links.admin_change_password = {
      name: 'Change Password',
      value: 'admin_change_password'
    }
  this.links.logout = {
      name: 'Logout',
      //value: 'logout'
    }
    const { school_logo, school_name } = JSON.parse(localStorage.getItem('domainDetails'))
    this.school_name = school_name
    this.school_logo = school_logo
  },
  methods: {
    linkToPortfolio: function (name) {
      this.$router.push({ name: name })
    },
    onLogout () {
        this.$store.dispatch('logout')
      }
  }
}
</script>

<style scoped>
.sidebar, .sidebar-wrapper {
  width: 320px !important;
}
</style>