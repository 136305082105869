<template>
  <div>
    <footer class="footer footer-one">
      <div
        class="primary-footer brand-bg"
        style="padding-bottom: 100px !important"
      >
        <div class="container">
          <a
            href="#top"
            class="
              page-scroll
              btn-floating btn-large
              pink
              back-top
              waves-effect waves-light
            "
            data-section="#top"
          >
            <i class="material-icons">&#xE316;</i>
          </a>

          <div class="row">
            <div
              class="col-md-4 widget clearfix"
              id="address_id"
              style="margin-left: 30px; margin-bottm: 30px !important"
            >
              <h2 class="white-text contact_us" id="">Contact Us</h2>

              <ul class="footer-list contact_us" style="" align="left">
                <li class="contact_us">
                  <strong>Address :</strong>
                  {{ sch_details.street_address }}, {{ sch_details.city }},
                  {{ sch_details.lga }}, {{ sch_details.landmark }},
                  {{ sch_details.postal_code }}, {{ sch_details.state }},
                  {{ sch_details.country }}.
                </li>
                <br />
                <li class="contact_us">
                  <strong>Call or SMS</strong> <strong>:</strong>
                  {{ sch_details.phone_number }}
                </li>
                <br />
                <li class="contact_us">
                  <strong>Whatsapp Us</strong> <strong>:</strong>
                  {{ sch_details.whatsapp_number }}
                </li>
                <br />
                <li class="contact_us"><strong>Email</strong> : {{ email }}</li>
                <br />
                <li class="contact_us">
                  <strong>School Website</strong> :
                  {{ sch_details.school_website }}
                </li>
                <!-- <li class="contact_us">
                  <strong>Monday - Friday :</strong> 9:00 am - 10:00 pm <br />
                  <strong>Saturday - Sunday :</strong> Closed
                </li> -->
              </ul>
            </div>

            <!-- /.col-md-3 -->

            <div class="col-md-3 widget">
              <p></p>
            </div>
            <!-- /.col-md-3 -->

            <div class="col-md-4 widget">
              <p>
                <img
                  src="assets/img/app-card.png"
                  alt="app card"
                  style="float: left"
                  width="90%"
                  height="NaN"
                />
              </p>
              <hr />
              <a
                v-if="is_whatsapp"
                :href="'https://api.whatsapp.com/send?phone=' + is_whatsapp +'&text=How can we help you?'"
                class="float"
                target="_blank"
              >
                <i class="fa fa-whatsapp my-float"></i>
              </a>
            </div>
            <!-- /.col-md-3 -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </div>
      <!-- /.primary-footer -->

      <div class="secondary-footer brand-bg darken-2">
        <div class="container">
          <span class="copy-text"
            >Copyright &copy; 2021 <a href="#">School Manager</a> &nbsp; |
            &nbsp; All Rights Reserved.</span
          >
        </div>
        <!-- /.container -->
      </div>
      <!-- /.secondary-footer -->
    </footer>

    <!-- Preloader -->
    <div id="preloader">
      <div class="preloader-position">
        <img src="assets/img/logo-colored.png" alt="logo" />
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
    </div>
    <!-- End Preloader -->
  </div>
</template>

<script>
import http from "../../common/http-common";

export default {
  name: "Footer",
  data() {
    return {
      school_id: "",
      sch_details: "",
      email: "",
      is_whatsapp: null,
    };
  },
  created() {
    this.getSchoolDetails();

    this.is_whatsapp = this.sch_details.whatsapp_number
  },
  methods: {
    getSchoolDetails() {
      if (localStorage.getItem("domainDetails")) {
        const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
        this.school_id = school_id;

        http
          .get(
            "result-template/get-more-school-details/" + this.school_id + "/"
          )
          .then((res) => {
            res.data.data.map((data) => {
              this.sch_details = data;
              this.email = res.data.email;
            });
          });
      }
    },
  },
};
</script>

<style>
#address_id li {
  color: #fff !important;
}
.footer-list li {
  color: #fff;
  text-align: left;
  justify-content: left;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
.contact_us {
  text-align: left;
  justify-content: left;
  color: #fff !important;
}
</style>
