<template>
  <div id="admin-veiw-results">
    <div class="row">
      <div class="col">
        <h2>Admin Veiw Results</h2>
      </div>
    </div>
    <!-- Start School Logo & School Details Section -->
    <div
      id="result-to-pdf"
      ref="ResultToPDF"
      align="center"
      width="100%"
      style="margin-left: 5%; margin-right: 5%"
    >
      <table width="100%" align="center">
        <tr>
          <td>
            <!-- Start Result Table -->
            <table width="100%" align="center">
              <tr>
                <td colspan="3">
                  <table
                    width="100%"
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                    align="center"
                  >
                    <tr>
                      <td>
                        <div align="center">
                          <img
                            :src="sch_details.school_banner"
                            style="width: 80%"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div align="center">
                          {{ sch_details.street_address }},
                          {{ sch_details.city }}, {{ sch_details.lga }},
                          {{ sch_details.landmark }},
                          {{ sch_details.postal_code }},
                          {{ sch_details.state }}, {{ sch_details.country }}.
                        </div>
                      </td>
                    </tr>
                    <tr v-if="sch_details.school_motto">
                      <td>
                        <div align="center">
                          <b>School Motto: </b> {{ sch_details.school_motto }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div align="center">
                          REPORT SHEET FOR
                          <span class="bold-text"
                            ><b>{{ session_term.sch_terms }}</b></span
                          >
                          |
                          <span class="bold-text"
                            ><b>{{ session_term.sch_session }}</b></span
                          >
                          SESSION
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              <!-- Start Student Details Section -->
              <tr width="100%" valign="top">
                <!-- Start Left Top -->
                <td width="50%">
                  <table
                    width="100%"
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <tr>
                      <td width="40%">
                        <table
                          border="1"
                          cellpadding="5"
                          cellspacing="5"
                          bordercolor="#CCCCCC"
                        >
                          <tr v-for="left in top_left" :key="left.id">
                            <td style="font-weight: bold !important">
                              <span :id="'top-left' + left.id">
                                {{ left.left_key }}</span
                              >:
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td width="5%"></td>
                      <td width="55%">
                        <table
                          border="1"
                          cellpadding="5"
                          cellspacing="5"
                          bordercolor="#CCCCCC"
                        >
                          <tr v-for="left in top_left" :key="left.id">
                            <td v-if="left.left_key != 'Student ID'">
                              {{ left.left_value }}:
                            </td>
                            <td v-else>{{ student_user }}:</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
                <td width="5%"></td>
                <!-- Start Right Top -->
                <td width="45%">
                  <table
                    width="100%"
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <tr>
                      <td width="55%">
                        <table
                          border="1"
                          cellpadding="5"
                          cellspacing="5"
                          bordercolor="#CCCCCC"
                        >
                          <tr v-for="right in top_right" :key="right.id">
                            <td style="font-weight: bold !important">
                              <span :id="'right-rignt' + right.id">
                                {{ right.right_key }}</span
                              >:
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td width="5%"></td>
                      <td width="40%">
                        <table
                          border="1"
                          cellpadding="5"
                          cellspacing="5"
                          bordercolor="#CCCCCC"
                        >
                          <tr v-for="right in top_right" :key="right.id">
                            <td v-if="right.right_key == 'Term Average'">{{ right.right_value }} %</td>

                            <td v-else-if="session_term.sch_terms == 'Third Term' && right.right_key == 'Yearly Aggregate'">{{ right.right_value }} </td>

                            <td v-else-if="session_term.sch_terms == 'Third Term' && right.right_key == 'Yearly Average'">{{ right.right_value }} %</td>

                            <td v-else-if="session_term.sch_terms == 'Third Term' && right.right_key == 'Yearly Grade%'">{{ right.right_value }} </td>

                            <td v-else>{{ right.right_value }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="3">
                  <table
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <thead
                      align="center"
                      v-for="sub_head in subject_heading"
                      :key="sub_head.sub_head"
                    >
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        SUBJECTS
                      </th>

                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        <span id="ca_score">{{ sub_head.ca_score }} </span>
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        <span id="exam_score">{{ sub_head.exam_score }} </span>
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        <span id="term_total">{{ sub_head.term_total }} </span>
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        <span id="highest_in_class"
                          >{{ sub_head.highest_in_class }}
                        </span>
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        <span id="grade">{{ sub_head.grade }} </span>
                      </th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="contents in subject_contents"
                        :key="contents.subject"
                      >
                        <td style="font-weight: bold !important">
                          <span :id="'subject-content' + contents.id">{{
                            contents.subject
                          }}</span>
                        </td>
                        <td>{{ contents.ca_score }}</td>
                        <td>{{ contents.exam_score }}</td>
                        <td>{{ contents.term_total }}</td>
                        <td>{{ contents.highest_in_class }}</td>
                        <td>{{ contents.grade }}</td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td colspan="5" style="font-weight: bold !important">
                          GRADING:-
                          <span v-for="grade in grades" :key="grade.id">
                            <span
                              >{{ grade.grade }}: {{ grade.mark_from }} -
                          {{ grade.mark_to }} = {{ grade.comment }},
                            </span>
                          </span>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              <tr align="center" width="100%">
                <!-- first column -->
                <td width="50%">
                  <table
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <thead align="center">
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                        width="200px"
                      >
                        {{ assessment_heading_one.assessment }}
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        1
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        2
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        3
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        4
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        5
                      </th>
                    </thead>
                    <!-- <tbody>
                  <tr>
                    <td colspan="6"></td>
                  </tr>
                </tbody> -->
                    <tbody
                      v-for="content in assessment_contents"
                      :key="content.contents"
                    >
                      <tr
                        v-if="
                          content.heading_id ==
                          assessment_heading_one.heading_id
                        "
                      >
                        <td style="font-weight: bold !important">
                          <span :id="'assessment-content' + content.id">
                            {{ content.contents }}</span
                          >
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 1"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 2"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 3"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 4"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 5"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td width="5%"></td>
                <!-- second column -->
                <td width="45%">
                  <table
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <thead align="center">
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                        width="200px"
                      >
                        {{ assessment_heading_two.assessment }}
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        1
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        2
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        3
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        4
                      </th>
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                      >
                        5
                      </th>
                    </thead>
                    <!--  <tbody>
                  <tr>
                    <td colspan="6"></td>
                  </tr>
                </tbody> -->
                    <tbody
                      v-for="content in assessment_contents"
                      :key="content.contents"
                    >
                      <tr
                        v-if="
                          content.heading_id ==
                          assessment_heading_two.heading_id
                        "
                      >
                        <td style="font-weight: bold !important">
                          <span :id="'assessment-content-right' + content.id">
                            {{ content.contents }}
                          </span>
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 1"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 2"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 3"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 4"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                        <td>
                          <img
                            v-if="content.ranting == 5"
                            src="../../assets/check-mark/checkmark-25x25.png"
                            width="10px"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <!-- second column Divider -->
                  <table
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <thead align="center">
                      <th
                        style="font-weight: bold !important"
                        class="align-top"
                        colspan="2"
                      >
                        KEY
                      </th>
                    </thead>
                    <tbody v-for="item in trait_key" :key="item.ranting">
                      <tr>
                        <td style="font-weight: bold !important">
                          {{ item.ranting }}
                        </td>
                        <td>
                          <span :id="'key-trait' + item.id">
                            {{ item.trait }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="3">
                  <table
                    border="1"
                    cellpadding="5"
                    cellspacing="5"
                    bordercolor="#CCCCCC"
                  >
                    <tbody v-for="item in comments" :key="item.id">
                      <tr>
                        <td style="font-weight: bold !important">
                          <span :id="'comment-section' + item.id">
                            {{ item.comment_key }}</span
                          >:
                        </td>
                        <td v-if="item.comment_value" colspan="2">
                          {{ item.comment_value }}
                        </td>
                        <td v-if="item.comment_image" colspan="2">
                          <img :src="item.comment_image" width="100px" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>

            <!-- End Teacher Comment Section -->
          </td>
        </tr>
      </table>
    </div>
    <!-- End ResultToPDF  -->
    <hr />
    <div
      class="row"
      style="
        margin-top: 20px;
        padding: 20px;
        border: 1px;
        border-color: #cccccc;
      "
    >
      <div class="col-md-2 col-sm-2 col-xs-12"></div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <div class="form-group">
          <label for="select-format">Select Result Format</label>
          <select
            class="form-control"
            name="select-format"
            id="select-format"
            v-model="select_format"
          >
            <option class="form-control" value="format_a" selected>
              Format A
            </option>
            <option class="form-control" value="format_b">Format B</option>
          </select>
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-xs-12">
        <p align="center" style="margin-top: 25px">
          <button class="btn btn-success" @click="printDocument()"
          :disabled="is_expired" >
            Send Result to Parent
          </button>
        </p>
      </div>
      <div class="col-md-2 col-sm-2 col-xs-12"></div>
    </div>

    <p v-if="is_expired" style="font-weight:bold" align="center">
      <a style="color: red" href="http://www.ijcschool.com.ng/subscription/" target="blank">
      To Enable your Button, Please Renew your Subscription, Click here...
      </a></p>

    <p v-if="isSendingResultPDF" style="color: red" align="center">
      <br /><b>{{ isSendingResultPDF }}</b>
    </p>
  </div>
</template>

<script>
/* eslint-disable */
import "bootstrap-vue";
import axios from "axios";
import { API_URL } from "../../common/config";
import http from "../../common/http-common";

// import pdfMake from "pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import htmlToPdfmake from "html-to-pdfmake";
// import { jsPDF } from "jspdf";

export default {
  name: "veiw-result-details",
  components: {},
  data() {
    return {
      template_id: this.$route.query.template_id,
      student_id: this.$route.query.student_id,
      top_left: "",
      top_right: "",
      subject: "",
      subject_heading: "",
      subject_contents: "",
      assessment_heading_one: "",
      assessment_heading_two: "",
      assessment_contents: "",
      trait_key: "",
      comments: "",
      grades: "",
      school_id: "",
      school_logo: "",
      school_name: "",
      session_term: "",
      sch_details: "",
      headers: "",
      student_user: "",
      select_format: "",
      isSendingResultPDF: null,
      expire_status: '',
      is_expired: true,
    };
  },
  created() {
    const { school_id, school_logo, school_name } = JSON.parse(
      localStorage.getItem("domainDetails")
    );
    this.school_id = school_id;
    this.school_logo = school_logo;
    this.school_name = school_name;

    var {token, expire_status} = JSON.parse(localStorage.getItem("userData"));
    this.headers = { Authorization: `Token ${token}` };

    if(expire_status == "Active"){
      this.is_expired = false
    }

    this.getTemplate();
    this.isTriatComplete = true;
    this.getMoreSchoolDetails();
  },
  methods: {
    getTemplate() {
      axios
        .get(
          API_URL +
            "api/school-result/get-student-result-details/" +
            this.template_id +
            "/" +
            this.student_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          console.log("== res.data.assess_heading ", res.data)
          this.top_left = res.data.top_left;
          this.top_right = res.data.top_right;
          this.subject = res.data.subject;
          this.subject_heading = res.data.subject_heading;
          this.subject_contents = res.data.subject_content;
          this.session_term = res.data.session_term[0];
          this.assessment_heading_one = res.data.assess_heading[0];
          this.assessment_heading_two = res.data.assess_heading[1];
          this.assessment_contents = res.data.assess_content;
          this.trait_key = res.data.trait;
          this.comments = res.data.comments;
          this.grades = res.data.grades;
          this.student_user = res.data.student_user;
          
        });
    },
    getMoreSchoolDetails() {
      if (this.school_id) {
        axios
          .get(
            API_URL +
              "api/result-template/get-more-school-details/" +
              this.school_id +
              "/",
            { headers: this.headers }
          )
          .then((res) => {
            res.data.data.map((data) => {
              this.sch_details = data;
            });
          });
      }
    }, //admin-veiw-results
    printDocument() {
      this.isSendingResultPDF =
        "Sending Result as PDF to Parent Email, Please wait...";

      const pdfTable = document.getElementById("result-to-pdf");
      let htmlData = {
        html_data: pdfTable.innerHTML,
        select_format: this.select_format,
      };

      console.log("htmlData ", htmlData);

      if (this.school_id) {
        http
          .post(
            "school-result/export-html-to-pdf/" +
              this.template_id +
              "/" +
              this.student_id +
              "/",
            htmlData,
            { headers: this.headers }
          )
          .then((res) => {
            console.log("res htmlData ", res.data.htmlData);
            if(res.data.htmlData == false){
              alert("This Result have Not been Approved!")
            }
            this.isSendingResultPDF = null;
          });
      }
    },
  },
};
</script>

<style scoped>
#admin-veiw-accounts {
  background-color: white !important;
  padding: 30px !important;
}
#logo_school_name {
  margin-bottom: 30px !important;
  border: 0.5px solid #cfcfcf !important;
  padding: 20px;
}
#student_details_section {
  border: 0.5px solid #cfcfcf !important;
  padding: 1px;
}
table {
  border: 1;
  border-color: #cccccc;
  padding: 10px !important;
}
td {
  font-size: 16px !important;
  border: 1px solid #b0b0b0 !important;
  padding: 10px !important;
  border-color: #cccccc;
}
th {
  font-size: 16px !important;
}
#div_size {
  font-size: 18px !important;
  padding: 5px;
}
.text-primary {
  color: #000000 !important;
}
.glyphicon {
  font-size: 10px !important;
}
.bold-text {
  font-weight: bold !important;
}
i {
  font-size: 12px !important;
  margin-right: 5px !important;
  margin-left: -2px !important;
  cursor: pointer !important;
}
input {
  height: 30px !important;
  background-color: white !important;
}
</style>
