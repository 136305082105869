<template>
  <div id="backend">
<head>
  <meta charset="utf-8" />
  <link rel="apple-touch-icon" sizes="76x76" href="../assets/img/apple-icon.png">
  <link rel="icon" type="image/png" href="../assets/img/favicon.png">
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <title>
    Admin School Manager Dashboard - ijcschool.com.ng
  </title>
  <meta content='width=device-width, initial-scale=1.0, shrink-to-fit=no' name='viewport' />
  <!--     Fonts and icons     -->
  <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons" />
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css">
  <!-- CSS Files -->
  <link href="../assets/css/material-dashboard.css?v=2.1.2" rel="stylesheet" />
  <!-- CSS Just for demo purpose, don't include it in your project -->
  <link href="../assets/demo/demo.css" rel="stylesheet" />
</head>
    <body id="top" class="has-header-search">
  <div class="wrapper "><!-- start wrapper -->
   <!-- start sideBar -->
   <BackendSideBar />
    <!-- end sideBar -->
  <div class="main-panel"> <!-- start main-panel -->
   <!-- start Navbar -->
   <BackendNav />
    <!-- end Navbar -->
  <div class="content">
  <div class="container-fluid">
    <router-view :key="$route.path"></router-view>
  </div></div>
  <BackendFooter />
  </div><!-- start main-panel -->
  </div><!-- start wrapper -->

  </body>
  </div>
</template>

<script>


import BackendFooter from '../components/Partials/backend-footer';
import BackendNav from '../components/Partials/nav-backend'
import BackendSideBar from '../components/Partials/sidebar-backend'

export default {
    name: 'adminBackend',
  components: {
    BackendFooter, BackendNav, BackendSideBar
  },
  created(){
    console.log('user1', this.$store.getters.is_admin)
    /* if (!this.$store.getters.is_admin) {
       this.$router.push('/user')
    } */
  },
  updated: function() {
    console.log('user2', this.$store.getters.is_admin)
    /* if (!this.$store.getters.is_admin) {
       this.$router.push('/user')
    } */
  }
}
</script>

<style scoped>
.content {
  padding-left: 45px !important;
  margin-left: 45px !important;
}
</style>