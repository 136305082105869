<template>
  <div class="about-us">
    <!--page title start-->
    <section
      class="
        page-title page-title-center
        cover-1
        padding-top-220 padding-bottom-120
        overlay
        purple-5
        fixed-bg
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="white-text font-40 text-bold">About Us</h2>
            <ol class="breadcrumb">
              <li><a href="#">Home</a></li>
              <li class="active">About Us</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="section-padding">
      <div class="container">
        <div itemprop="articleBody">
          <div v-html="sch_details.about_school" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import http from "../common/http-common";

export default {
  /* eslint-disable */
  name: "AboutUs",
  data() {
    return {
      sch_details: "",
      school_id: "",
    };
  },
  created() {
    this.getSchoolDetails();
  },
  methods: {
    getSchoolDetails() {
      if (localStorage.getItem("domainDetails")) {
        const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
        this.school_id = school_id;

        http
          .get(
            "result-template/get-more-school-details/" + this.school_id + "/"
          )
          .then((res) => {
            res.data.data.map((data) => {
              this.sch_details = data;
              this.email = res.data.email;
            });
          });
      }
    },
  },
};
</script>

<style scoped>
li {
  color: #fff !important;
}
</style>
