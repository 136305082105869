<template>
  <div id="admin-create-results">
    <div class="row">
      <h1>Create Parent Account</h1>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="card" style="margin-top:50px !important;">
        <div class="card-header card-header-primary" style="margin-top:30px !important;">
          <h3 >Create Parent Accounts</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="submitProfile" id="form1">
            <br><br>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">Userame</label>
                  <input type="text" name="username" v-model="username" required class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">Password</label>
                  <input type="text" name="password" v-model="password" required class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">Fist Name</label>
                  <input type="text" name="first_name" v-model="first_name" required class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">Last Name</label>
                  <input type="text" name="last_name" v-model="last_name" required class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="">Adress</label>
                  <input type="text" name="address" v-model="address" required class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="">LGA</label>
                  <input type="text" name="lga" v-model="lga" required class="form-control">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="">City / State</label>
                  <input type="text" name="city_state" v-model="city_state" required class="form-control">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="">Country</label>
                  <input type="text" name="country" v-model="country" required class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">Phone number / GSM</label>
                  <input type="text" name="phone_number" v-model="phone_number" required class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="">Whatsapp</label>
                  <input type="text" name="whatsapp" v-model="whatsapp" class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="form-group">
                    <label class=""> Student/Pupil Name(s) </label>
                    <textarea class="form-control"  name="student_names" v-model="student_names" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div><div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="form-group">
                    <label class=""> Student/Pupil Class(es) </label>
                    <textarea class="form-control"  name="student_classes" v-model="student_classes" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-success">Submit</button>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'AdminCreateResutls',
    components: {
      // BackendScripts
    }
}
</script>

<style>
  label {
    font-size: 14px !important;
  }
</style>