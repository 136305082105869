<template>
  <div id="backend-footer">
      <footer class="footer">
        <div class="container-fluid">
          <nav class="float-left" style="color:#000;">
            <ul>
              <li>
                <a href="/" target="_blank">
                  Home
                </a>
              </li>
              <li>
                <a href="/about-us" target="_blank">
                  About Us
                </a>
              </li>
              <li>
                <a href="/contact-us" target="_blank">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
          <div class="copyright float-right">
            &copy;
            <span id="footer-date">2020</span>, All Rights Reserved <i class="material-icons">favorite</i>
            <a href="/" target="_blank">Vestrade</a>
          </div>
  
        </div>
      </footer>

  </div>
</template>

<script>

export default {
    name: "BackendFooter",
    components: {

    },
}

 

</script>

<style>

</style>