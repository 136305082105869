<template>
  <div id="admin-veiw-parent-accounts">
    <div class="row">
      <h1>Admin Veiw Parent Accounts</h1>
  </div>
  <!-- <BackendScripts /> -->
  <div class="row">
     <div class="col-xs-12">       
        <div class="card">
          <div class="card-header card-header-primary" style="margin-top:15px !important;">
            <h3>View & Edit Account</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th >Student ID</th>
                <th >Class</th>
                <th >Year</th>
                <th>Edit Account</th>
                <th>View Account</th>
              </thead>
              <tbody>
                <tr v-for="item in resultDetails" v-bind:key="item.id">
                  <td>{{ item.student_id }}</td>
                  <td>$ {{ item.calss }}</td>
                  <td>{{ item.year }}</td>
                  <td><a :href="item.view_result">Edit</a></td>
                  <td><a :href="item.view_result">View</a></td>
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>
  </div>
</template>

<script>

export default {
    name: 'admin-veiw-parent-accounts',
    components: {
      // BackendScripts
    },
    data() {
      return {
        go_back: '<<< Back',
        template_id : this.$route.query.id,
        
      }
    },
    created() {
      const { school_id, } = JSON.parse(localStorage.getItem('domainDetails'));
      this.school_id = school_id;
    },
    methods: {
      
      goBack(){
        this.$router.push('/admin/pre-create-student-account')
      }
    },
}
</script>

<style>

</style>