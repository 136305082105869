import axios from "axios";
import API_URL from '../../common/config';

/* eslint-disable no-unused-vars */
// /* eslint-disable vue/no-parsing-error */
export default {
    state: {

    },
    mutations: {

    },
    actions: {
        async addTopLeft({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-top-left/', payload, { headers: headers });
            return result.data;
        },
        async addTopRight({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-top-right/', payload, { headers: headers });
            return result.data;
        },
        async addSubjectContents({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-subject-content/', payload, { headers: headers });
            return result.data;
        },
        async getGradesDetails({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/get-grades-details/', payload, { headers: headers });
            return result.data;
        },
        async addAssessmentContentLeft({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-assessment-content-left/', payload, { headers: headers });
            return result.data;
        },
        async addAssessmentContentRight({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-assessment-content-right/', payload, { headers: headers });
            return result.data;
        },
        async addTrait({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-key-trait/', payload, { headers: headers });
            return result.data;
        },
        async addcommentSection({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/add-comment-sect/', payload, { headers: headers });
            return result.data;
        }
    },
    getters: {

    }
}