<template>
  <div id="admin-view-parent-details"></div>
</template>

<script>
export default {
  name: "admin-view-parent-details",
  data() {
    return {};
  },
};
</script>

<style></style>
