<template>
<div id="backend-nav">
<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div class="container-fluid">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="javascript:;">Admin Dashboard</a>
          </div>
          <button ref="dashboard_menu" @click.prevent="menuToggle()" class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end">
            <form class="navbar-form">
              <div class="input-group no-border">
                <input type="text" value="" class="form-control" placeholder="Search...">
                <button type="submit" class="btn btn-white btn-round btn-just-icon">
                  <i class="material-icons">search</i>
                  <div class="ripple-container"></div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </nav>
</div>
  
</template>

<script>
/* eslint-disable */
export default {
    name: "BackendNav",

  created() {
    //this.menuToggle()
  },
  methods: {
    menuToggle(){
      // const menu = this.$refs.dashboard_menu
      // console.log('== menu ', menu)
    }
  },
}
</script>

<style scoped>
  .navbar-wrapper {
    padding-left: 47px !important;
    margin-left: 47px !important;
  }
</style>