<template>
  <div class="">
        <!--page title start-->
        <section class="page-title page-title-center cover-1 padding-top-220 padding-bottom-120 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="white-text font-40 text-bold">Contact Us</h2>
                        <ol class="breadcrumb">
                            <li><a href="#">Home</a></li>
                            <li class="active">Contact Us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!--page title end-->




        <!-- map-section -->
        <!-- <section id="myMap" class="height-350"></section> -->
        <!-- /.map-section -->

        
        <!-- contact-form-section -->
        <section class="section-padding" style="margin-top:-70px;">
          
          <div class="container">
            <div class="row">
                <div class="col-md-4 contact-info">

                    <address>
                      <i class="material-icons brand-color">&#xE8B4;</i>
                      <div class="address">
                        <p>2313 Browning Lane, Mclean, 13102, New York</p>
                      </div>

                      <i class="material-icons brand-color">&#xE61C;</i>
                      <div class="phone">
                        <p>Call or SMS: +18653023499<br>
                        Whatsapp Us: +18653023499</p>
                      </div>

                      <i class="material-icons brand-color">&#xE0B7;</i>
                      <div class="mail">
                        <p><a href="mailto:vestrade@outlook.com">vestrade@outlook.com</a><br>
                        <a href="http://www.vestrade.com">www.vestrade.com</a></p>
                      
                        <hr>
                        <p class="mail"><a>
                          Monday - Friday : 9:00 am - 10:00 pm<br>
                          Saturday - Sunday : Closed</a></p>
                      </div>
                      
                    </address>
                </div><!-- /.col-md-4 -->

                <div class="col-md-8">
                    <form name="contact-form" id="contactForm" method="POST">

                      <div class="row">
                        <div class="col-md-6">
                          <div class="input-field">
                            <input type="text" name="name" class="validate" id="name">
                            <label for="name">Name</label>
                          </div>

                        </div><!-- /.col-md-6 -->

                        <div class="col-md-6">
                          <div class="input-field">
                            <label class="sr-only" for="email">Email</label>
                            <input id="email" type="email" name="email" class="validate" >
                            <label for="email" data-error="wrong" data-success="right">Email</label>
                          </div>
                        </div><!-- /.col-md-6 -->
                      </div><!-- /.row -->

                      <div class="row">
                        <div class="col-md-6">
                          <div class="input-field">
                            <input id="phone" type="tel" name="phone" class="validate" >
                            <label for="phone">Phone Number</label>
                          </div>
                        </div><!-- /.col-md-6 -->

                        <div class="col-md-6">
                          <div class="input-field">
                            <input id="website" type="text" name="website" class="validate" >
                            <label for="website">Your Website</label>
                          </div>
                        </div><!-- /.col-md-6 -->
                      </div><!-- /.row -->

                      <div class="input-field">
                        <textarea name="message" id="message" class="materialize-textarea" ></textarea>
                        <label for="message">Message</label>
                      </div>

                      <button type="submit" name="submit" class="waves-effect waves-light btn brand-bg pull-right mt-30">Send Message</button>
                    </form>
                </div><!-- /.col-md-8 -->

            </div><!-- /.row -->
          </div>

        </section>
        <!-- contact-form-section End -->

  </div>
</template>

<script>
export default {
    name: "ContactUs"
}
</script>

<style>

</style>