<template>
  <div id="admin-veiw-results">
    <div class="row">
      <div class="col">
        <h2>Admin Veiw Results</h2>
        <h3 style="color: red">
          <b>
            Note: These are Result Template Sample Data, <br />Here, Heading &
            Key information(s) will represent the Template/Layout of your School
            Result.</b
          ><br />This Template is designed help you create how you want your
          Result to look like.<br />Also to note that altering this template
          after you have stated using it will affect any Existing Students
          Results/Records on it, you can also Reuse this template for another
          class or session by simiply Cloning the Template, you can go to the
          Clone Template section to do so.
        </h3>
        <br />
      </div>
    </div>
    <!-- Start School Logo & School Details Section -->
    <section id="logo_school_name">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div align="center">
              <img :src="sch_details.school_banner" style="max-width: 80%" />
            </div>
            <div align="center" style="margin-top: 2% !important">
              <!-- <h1 style="font-size: 42px !important; font-weight: bold !important;">
          {{school_name}}</h1> -->
              <div id="div_size">
                {{ sch_details.street_address }}, {{ sch_details.city }},
                {{ sch_details.lga }}, {{ sch_details.landmark }},
                {{ sch_details.postal_code }}, {{ sch_details.state }},
                {{ sch_details.country }}.
              </div>
              <div v-if="sch_details.school_motto" id="div_size">
                MOTTO: {{ sch_details.school_motto }}
              </div>
              <hr />
              <div id="div_size">
                REPORT SHEET FOR
                <span class="bold-text">{{ session_term.sch_terms }}</span> |
                <span class="bold-text">{{ session_term.sch_session }}</span>
                SESSION
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End School Logo & School Details Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Student Details Section -->
    <section id="student_details_section">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <table class="table table-bordered table-hover" style="">
                <tr>
                  <td style="width: 60% !important">
                    <tbody>
                      <tr v-for="left in top_left" :key="left.id">
                        <td>
                          <i
                            v-if="left.is_origin == 'No'"
                            @click="topLeftTemplate(left.id, left.left_key)"
                            :id="left.id"
                            class="fa fa-pencil-square-o green"
                            title="Edit"
                          ></i>

                          <i
                            v-if="left.is_origin == 'No'"
                            @click="
                              deleteTemplateTopLeft(left.id, left.left_key)
                            "
                            class="fa fa-times red"
                            title="Remove"
                          ></i>
                          <span :id="'top-left' + left.id">
                            {{ left.left_key }}</span
                          >:
                        </td>
                      </tr>
                    </tbody>
                  </td>
                  <td style="width: 40% !important">
                    <tbody>
                      <tr v-for="left in top_left" :key="left.id">
                        <td>{{ left.left_value }}:</td>
                      </tr>
                    </tbody>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <strong>
                      <i
                        @click="turnOnTopLeftForm"
                        class="fa fa-plus-square-o"
                        style="
                          font-size: 16px !important;
                          color: green;
                          margin-left: 5px !important;
                        "
                      >
                        Add more...</i
                      >
                    </strong>
                  </td>
                </tr>
              </table>
              <!-- Start Add More (Top Left) -->
              <form @submit.prevent="addTemplateTopLeft()" v-if="isTopLeftForm">
                <table>
                  <tr>
                    <td>
                      <input
                        required
                        v-model="input.left_key"
                        class="form-control"
                        placeholder="Add Heading"
                      />
                    </td>
                    <td>
                      <input
                        required
                        v-model="input.left_value"
                        class="form-control"
                        placeholder="Add Sample Details"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <button type="submit" class="btn btn-success">Add</button>
                    </td>
                  </tr>
                </table>
              </form>
              <!-- Start Add More (Top Left) -->
            </div>
            <b-modal
              :ok-only="true"
              ok-variant="dark"
              :ok-title="'Cancel'"
              id="modal-prevent-closing-left"
              ref="editTopLeftModal"
              :header-text-variant="'dark'"
              hide-backdrop
              :header-bg-variant="'primary'"
              title="Edit Result Template"
            >
              <LeftTemplateModel
                @clicked="leftTemplateUpdated"
                :data="top_left_model"
              ></LeftTemplateModel>
            </b-modal>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <table class="table table-bordered table-hover">
                <tr>
                  <td style="width: 70% !important">
                    <tbody>
                      <tr v-for="right in top_right" :key="right.id">
                        <td>
                          <i
                            v-if="right.is_origin == 'No'"
                            @click.prevent="
                              topRightTemplate(right.id, right.right_key)
                            "
                            :id="right.id"
                            class="fa fa-pencil-square-o green"
                            title="Edit"
                          ></i>

                          <i
                            v-if="right.is_origin == 'No'"
                            @click="
                              deleteTemplateTopRight(right.id, right.right_key)
                            "
                            class="fa fa-times red"
                            title="Remove"
                          ></i>
                          <span :id="'right-rignt' + right.id">
                            {{ right.right_key }}</span
                          >:
                        </td>
                      </tr>
                    </tbody>
                  </td>
                  <td style="width: 30% !important">
                    <tbody>
                      <tr v-for="right in top_right" :key="right.id">
                        <td>{{ right.right_value }}:</td>
                      </tr>
                    </tbody>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <strong>
                      <i
                        @click="turnOnTopRightForm"
                        class="fa fa-plus-square-o"
                        style="
                          font-size: 16px !important;
                          color: green;
                          margin-left: 5px !important;
                        "
                      >
                        Add more...</i
                      >
                    </strong>
                  </td>
                </tr>
              </table>
              <!-- Start Add More (Top Right) -->
              <form
                @submit.prevent="addTemplateTopRight()"
                v-if="isTopRightForm"
              >
                <table>
                  <tr>
                    <td>
                      <input
                        required
                        v-model="input.right_key"
                        class="form-control"
                        placeholder="Add Heading"
                      />
                    </td>
                    <td>
                      <input
                        required
                        v-model="input.right_value"
                        class="form-control"
                        placeholder="Add Sample Details"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <button type="submit" class="btn btn-success">Add</button>
                    </td>
                  </tr>
                </table>
              </form>
              <!-- Start Add More (Top Right) -->
            </div>
            <b-modal
              :ok-only="true"
              ok-variant="dark"
              :ok-title="'Cancel'"
              id="modal-prevent-closing"
              ref="editTopRightModal"
              :header-text-variant="'dark'"
              hide-backdrop
              :header-bg-variant="'primary'"
              title="Edit Result Template"
            >
              <RightTemplateModel
                @clicked="templateUpdated"
                :data="top_right_model"
              ></RightTemplateModel>
            </b-modal>
          </div>
        </div>
      </div>
    </section>
    <!-- End Student Details Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Subjects Section -->
    <section id="student_details_section" style="margin-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <b-modal
                :ok-only="true"
                ok-variant="dark"
                :ok-title="'Cancel'"
                id="modal-prevent-closing-subject-headings"
                ref="editSubjectHeadingModal"
                :header-text-variant="'dark'"
                hide-backdrop
                :header-bg-variant="'primary'"
                title="Edit Result Template"
              >
                <SubjectHeadingsModal
                  @clicked="subjectHeadingUpdated"
                  :data="subject_heading_modal"
                ></SubjectHeadingsModal>
              </b-modal>
              <table class="table table-hover table-bordered">
                <thead
                  class="text-primary"
                  align="center"
                  v-for="sub_head in subject_heading"
                  :key="sub_head.sub_head"
                >
                  <th style="font-weight: bold !important" class="align-top">
                    SUBJECTS
                  </th>

                  <th style="font-weight: bold !important" class="align-top">
                    <span id="ca_score">{{ sub_head.ca_score }} </span>
                    <i
                      @click="
                        subjectHeading({
                          key: 'ca_score',
                          value: sub_head.ca_score,
                        })
                      "
                      class="fa fa-pencil-square-o green"
                      title="Edit"
                    ></i>
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    <span id="exam_score">{{ sub_head.exam_score }} </span>
                    <i
                      @click="
                        subjectHeading({
                          key: 'exam_score',
                          value: sub_head.exam_score,
                        })
                      "
                      class="fa fa-pencil-square-o green"
                      title="Edit"
                    ></i>
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    <span id="term_total">{{ sub_head.term_total }} </span>
                    <i
                      @click="
                        subjectHeading({
                          key: 'term_total',
                          value: sub_head.term_total,
                        })
                      "
                      class="fa fa-pencil-square-o green"
                      title="Edit"
                    ></i>
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    <span id="highest_in_class"
                      >{{ sub_head.highest_in_class }}
                    </span>
                    <i
                      @click="
                        subjectHeading({
                          key: 'highest_in_class',
                          value: sub_head.highest_in_class,
                        })
                      "
                      class="fa fa-pencil-square-o green"
                      title="Edit"
                    ></i>
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    <span id="grade">{{ sub_head.grade }} </span>
                    <i
                      @click="
                        subjectHeading({ key: 'grade', value: sub_head.grade })
                      "
                      class="fa fa-pencil-square-o green"
                      title="Edit"
                    ></i>
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="contents in subject_contents"
                    :key="contents.subject"
                  >
                    <td style="font-weight: bold !important">
                      <i
                        @click.prevent="
                          subjectContents(contents.id, contents.subject)
                        "
                        class="fa fa-pencil-square-o green"
                        title="Edit"
                      ></i>

                      <i
                        @click="
                          deleteSubjectContents(contents.id, contents.subject)
                        "
                        class="fa fa-times red"
                        title="Remove"
                      ></i>
                      <span :id="'subject-content' + contents.id">{{
                        contents.subject
                      }}</span>
                    </td>
                    <td>{{ contents.ca_score }}</td>
                    <td>{{ contents.exam_score }}</td>
                    <td>{{ contents.term_total }}</td>
                    <td>{{ contents.highest_in_class }}</td>
                    <td>{{ contents.grade }}</td>
                  </tr>
                  <b-modal
                    :ok-only="true"
                    ok-variant="dark"
                    :ok-title="'Cancel'"
                    id="modal-prevent-closing-subject-contents"
                    ref="editSubjectContModal"
                    :header-text-variant="'dark'"
                    hide-backdrop
                    :header-bg-variant="'primary'"
                    title="Edit Result Template"
                  >
                    <SubjectContentsModal
                      @clicked="subjectContentUpdated"
                      :data="subject_cont_modal"
                    ></SubjectContentsModal>
                  </b-modal>
                  <tr>
                    <td colspan="6">
                      <strong>
                        <i
                          @click="turnOnSubjectContForm"
                          class="fa fa-plus-square-o"
                          style="
                            font-size: 16px !important;
                            color: green;
                            margin-left: 5px !important;
                          "
                        >
                          Add more Subjects...</i
                        ></strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <!-- Start Add More (Top Right) -->
                      <form
                        @submit.prevent="addSubjectContents()"
                        v-if="isSubjectContForm"
                      >
                        <table>
                          <tr>
                            <td>
                              <input
                                required
                                v-model="input.subject_content"
                                class="form-control"
                                placeholder="Add Subject e.g Mathematics"
                              />
                            </td>
                            <!-- <td ><input v-model="input.right_value" class="form-control" placeholder="Add Sample Details" > </td> -->
                          </tr>
                          <tr>
                            <td colspan="2">
                              <button type="submit" class="btn btn-success">
                                Add
                              </button>
                            </td>
                          </tr>
                        </table>
                      </form>
                      <!-- Start Add More (Top Right) -->
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" style="font-weight: bold !important">
                      GRADING:-
                      <span v-for="grade in grades" :key="grade.id">
                        <span
                          >{{ grade.grade }}: {{ grade.mark_from }} -
                          {{ grade.mark_to }} = {{ grade.comment }},
                        </span>
                      </span>
                      <!-- F = 0-39, P = 40-49, M = 50-59, B = 60-69, B+ = 70-79, A = 80-89, A+ = 90-100    -->
                    </td>
                    <td>
                      <strong>
                        <i
                          @click.prevent="editGrades()"
                          class="fa fa-pencil-square"
                          style="
                            font-size: 24px !important;
                            color: green;
                            margin-left: 5px !important;
                          "
                          title="Edit"
                        ></i
                      ></strong>
                    </td>
                  </tr>
                  <b-modal
                    :ok-only="true"
                    ok-variant="dark"
                    :ok-title="'Cancel'"
                    id="modal-prevent-closing-edit-grades"
                    ref="editGradesModal"
                    :header-text-variant="'dark'"
                    hide-backdrop
                    :header-bg-variant="'primary'"
                    title="Edit Grades Template"
                  >
                    <EditGradesModal
                      @clicked="editGradesUpdated"
                      :data="edit_grades_modal"
                    ></EditGradesModal>
                  </b-modal>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Subjects Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Bottom A Section -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-7 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <table class="table table-hover table-bordered">
                <thead class="text-primary" align="center">
                  <th style="font-weight: bold !important" class="align-top">
                    {{ assessment_heading_one.assessment }}
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    1
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    2
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    3
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    4
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    5
                  </th>
                </thead>
                <tbody
                  v-for="content in assessment_contents"
                  :key="content.contents"
                >
                  <tr
                    v-if="
                      content.heading_id == assessment_heading_one.heading_id
                    "
                  >
                    <td style="font-weight: bold !important">
                      <i
                        @click.prevent="
                          assessmentContent(
                            content.id,
                            content.heading_id,
                            content.contents
                          )
                        "
                        class="fa fa-pencil-square-o green"
                        title="Edit"
                      ></i>

                      <i
                        @click="
                          deleteAssessmentContentLeft(
                            content.id,
                            content.heading_id,
                            content.contents
                          )
                        "
                        class="fa fa-times red"
                        title="Remove"
                      ></i>
                      <span :id="'assessment-content' + content.id">
                        {{ content.contents }}</span
                      >
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 1"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 2"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 3"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 4"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 5"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-modal
                :ok-only="true"
                ok-variant="dark"
                :ok-title="'Cancel'"
                id="modal-prevent-closing-assessment-contents"
                ref="editAssessmentContModal"
                :header-text-variant="'dark'"
                hide-backdrop
                :header-bg-variant="'primary'"
                title="Edit Result Template"
              >
                <AssessmentContentModal
                  @clicked="assessmentContentUpdated"
                  :data="assessment_cont_modal"
                ></AssessmentContentModal>
              </b-modal>
              <table>
                <tr>
                  <td colspan="6">
                    <strong>
                      <i
                        @click="turnOnAssessmentContLeftForm"
                        class="fa fa-plus-square-o"
                        style="
                          font-size: 16px !important;
                          color: green;
                          margin-left: 5px !important;
                        "
                      >
                        Add more Assessment...</i
                      ></strong
                    >
                  </td>
                </tr>

                <tr v-if="isAssessmentContLeftForm">
                  <td colspan="6">
                    <!-- Start Add More (Top Right) -->
                    <form
                      @submit.prevent="
                        addAssessmentContentLeft(
                          assessment_heading_one.heading_id
                        )
                      "
                    >
                      <table>
                        <tr>
                          <td>
                            <input
                              required
                              v-model="input.assessment_content"
                              class="form-control"
                              placeholder="Add Assessment Title"
                            />
                          </td>
                          <!-- <td ><input v-model="input.right_value" class="form-control" placeholder="Add Sample Details" > </td> -->
                        </tr>
                        <tr>
                          <td colspan="2">
                            <button type="submit" class="btn btn-success">
                              Add
                            </button>
                          </td>
                        </tr>
                      </table>
                    </form>
                    <!-- Start Add More (Top Right) -->
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-md-5 col-sm-5 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <table class="table table-hover table-bordered">
                <thead class="text-primary" align="center">
                  <th style="font-weight: bold !important" class="align-top">
                    {{ assessment_heading_two.assessment }}
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    1
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    2
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    3
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    4
                  </th>
                  <th style="font-weight: bold !important" class="align-top">
                    5
                  </th>
                </thead>
                <tbody
                  v-for="content in assessment_contents"
                  :key="content.contents"
                >
                  <tr
                    v-if="
                      content.heading_id == assessment_heading_two.heading_id
                    "
                  >
                    <td style="font-weight: bold !important">
                      <i
                        @click.prevent="
                          assessmentContentRight(
                            content.id,
                            content.heading_id,
                            content.contents
                          )
                        "
                        class="fa fa-pencil-square-o green"
                        title="Edit"
                      ></i>

                      <i
                        @click="
                          deleteAssessmentContentRight(
                            content.id,
                            content.heading_id,
                            content.contents
                          )
                        "
                        class="fa fa-times red"
                        title="Remove"
                      ></i>
                      <span :id="'assessment-content-right' + content.id">
                        {{ content.contents }}
                      </span>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 1"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 2"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 3"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 4"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                    <td>
                      <i
                        v-if="content.ranting == 5"
                        class="glyphicon glyphicon-ok"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-modal
                :ok-only="true"
                ok-variant="dark"
                :ok-title="'Cancel'"
                id="modal-prevent-closing-assessment-contents-right"
                ref="editAssessmentContRightModal"
                :header-text-variant="'dark'"
                hide-backdrop
                :header-bg-variant="'primary'"
                title="Edit Result Template"
              >
                <AssessmentContentRightModal
                  @clicked="assessmentContentRightUpdated"
                  :data="assessment_cont_right_modal"
                ></AssessmentContentRightModal>
              </b-modal>
              <table>
                <tr>
                  <td colspan="6">
                    <strong>
                      <i
                        @click="turnOnAssessmentContRightForm"
                        class="fa fa-plus-square-o"
                        style="
                          font-size: 16px !important;
                          color: green;
                          margin-left: 5px !important;
                        "
                      >
                        Add more Assessment...</i
                      ></strong
                    >
                  </td>
                </tr>
                <tr v-if="isAssessmentContRightForm">
                  <td colspan="6">
                    <!-- Start Add More (Top Right) -->
                    <form
                      @submit.prevent="
                        addAssessmentContentRight(
                          assessment_heading_two.heading_id
                        )
                      "
                    >
                      <table>
                        <tr>
                          <td>
                            <input
                              required
                              v-model="input.assessment_content_right"
                              class="form-control"
                              placeholder="Add Psychomotor Title"
                            />
                          </td>
                          <!-- <td ><input v-model="input.right_value" class="form-control" placeholder="Add Sample Details" > </td> -->
                        </tr>
                        <tr>
                          <td colspan="2">
                            <button type="submit" class="btn btn-success">
                              Add
                            </button>
                          </td>
                        </tr>
                      </table>
                    </form>
                    <!-- Start Add More (Top Right) -->
                  </td>
                </tr>
              </table>
            </div>
            <div class="card-body table-responsive">
              <br />
              <table class="table table-hover table-bordered">
                <thead class="text-primary" align="center">
                  <th
                    style="font-weight: bold !important"
                    class="align-top"
                    colspan="2"
                  >
                    KEY
                  </th>
                </thead>
                <tbody v-for="item in trait_key" :key="item.ranting">
                  <tr>
                    <td style="font-weight: bold !important">
                      {{ item.ranting }}
                    </td>
                    <td>
                      <i
                        @click.prevent="keyTrait(item.id, item.trait)"
                        class="fa fa-pencil-square-o green"
                        title="Edit"
                      ></i>

                      <i
                        @click="deleteTraitUpdated(item.id, item.trait)"
                        class="fa fa-times red"
                        title="Remove"
                      ></i>
                      <span :id="'key-trait' + item.id"> {{ item.trait }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-modal
                :ok-only="true"
                ok-variant="dark"
                :ok-title="'Cancel'"
                id="modal-prevent-closing-key-trait"
                ref="editKeyTraitModal"
                :header-text-variant="'dark'"
                hide-backdrop
                :header-bg-variant="'primary'"
                title="Edit Result Template"
              >
                <KeyTraitModal
                  @clicked="keyTraitUpdated"
                  :data="key_trait_modal"
                ></KeyTraitModal>
              </b-modal>
              <table>
                <tr>
                  <td colspan="2">
                    <strong>
                      <i
                        @click="turnOnKeyTraitForm"
                        class="fa fa-plus-square-o"
                        style="
                          font-size: 16px !important;
                          color: green;
                          margin-left: 5px !important;
                        "
                      >
                        Add New Key...</i
                      ></strong
                    >
                  </td>
                </tr>
                <tr v-if="isKeyTraitForm">
                  <td colspan="6">
                    <!-- Start Add More (Top Right) -->
                    <form @submit.prevent="addKeyTrait()">
                      <table>
                        <tr>
                          <td>
                            <label>Select Key Trait</label>
                            <!-- v-on:change="onSelectChange($event)" -->
                            <div class="form-group">
                              <select
                                class="form-control"
                                id="select_key_trait"
                                name="select_key_trait"
                                v-model="select_key_trait"
                                required="yes"
                              >
                                <option value="" disabled>
                                  Select key trait...
                                </option>

                                <option
                                  v-for="item in trait_key_len"
                                  :key="item"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              required
                              v-model="input.key_trait"
                              class="form-control"
                              placeholder="Add Trait Details"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div v-if="isTriatComplete">
                              <button type="submit" class="btn btn-success">
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </form>
                    <!-- Start Add More (Top Right) -->
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Bottom A Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Teacher Comment Section -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-1 col-sm-1 col-xs-1">
            <p></p>
          </div>
          <div class="col-md-10 col-sm-10 col-xs-10">
            <div class="card-body table-responsive">
              <br />
              <table class="table table-hover table-bordered">
                <tbody v-for="item in comments" :key="item.id">
                  <tr>
                    <td style="font-weight: bold !important">
                      <i
                        @click.prevent="
                          commentSection(item.id, item.comment_key)
                        "
                        class="fa fa-pencil-square-o green"
                        title="Edit"
                      ></i>

                      <i
                        @click="deletecommentSection(item.id, item.comment_key)"
                        class="fa fa-times red"
                        title="Remove"
                      ></i>
                      <span :id="'comment-section' + item.id">
                        {{ item.comment_key }}</span
                      >:
                    </td>
                    <td v-if="item.comment_value" colspan="2">
                      {{ item.comment_value }}
                    </td>
                    <td v-if="item.comment_image" colspan="2">
                      <img id="update_signature" :src="item.comment_image" width="100px" />
                      <hr />
                        <label style="color:#000;" :for="item.id">
                          Upload/Change Signature
                        </label>
                        <input
                          name="file"
                          :id="item.id"
                          type="file"
                          accept="image/*"
                          ref="file"
                          @change="selectImage"
                        />
                      <p>
                          <span v-if="previewImage">
                            <img
                              class="preview my-3"
                              :src="previewImage"
                              alt=""
                              width="15%"
                            /> </span
                          ><br />

                          <i
                            @click="uploadSignature(item.id)"
                            class="fa fa-plus-square-o"
                              style="color:green;, margin-top:-15px !important; padding-bottom:10px; font-size:16px !important; font-weight:bold;"
                            >
                              Update Signature
                            </i>
                          <br />
                          <span
                            v-if="is_comment_img_updating"
                            style="padding: 10px; color: red; font-size: 15px"
                            align="center"
                            >{{ is_comment_img_updating }}</span
                          >
                        </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-modal
                :ok-only="true"
                ok-variant="dark"
                :ok-title="'Cancel'"
                id="modal-prevent-closing-comment-sect"
                ref="editCommentSectionModal"
                :header-text-variant="'dark'"
                hide-backdrop
                :header-bg-variant="'primary'"
                title="Edit Result Template"
              >
                <CommentSectionModal
                  @clicked="commentSectionUpdated"
                  :data="comment_sect_modal"
                ></CommentSectionModal>
              </b-modal>
              <table>
                <tr>
                  <td colspan="6">
                    <strong>
                      <i
                        @click="turnOnCommentSectionForm"
                        class="fa fa-plus-square-o"
                        style="
                          font-size: 16px !important;
                          color: green;
                          margin-left: 5px !important;
                        "
                      >
                        Add more Comments...</i
                      ></strong
                    >
                  </td>
                </tr>
                <tr v-if="isCommentSectionForm">
                  <td colspan="6">
                    <!-- Start Add More (Top Right) -->
                    <form @submit.prevent="addcommentSection()">
                      <table>
                        <tr>
                          <td>
                            <input
                              required
                              v-model="input.comment_section"
                              class="form-control"
                              placeholder="Add Comment Title"
                            />
                          </td>
                          <!-- <td ><input v-model="input.right_value" class="form-control" placeholder="Add Sample Details" > </td> -->
                        </tr>
                        <tr>
                          <td colspan="2">
                            <button type="submit" class="btn btn-success">
                              Add
                            </button>
                          </td>
                        </tr>
                      </table>
                    </form>
                    <!-- Start Add More (Top Right) -->
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-md-1 col-sm-1 col-xs-1">
            <p></p>
          </div>
        </div>
      </div>
    </section>
    <div></div>
    <!-- End Teacher Comment Section -->
  </div>
</template>

<script>
/* eslint-disable */
import "bootstrap-vue";
import axios from "axios";
import { API_URL } from "../../common/config";
import RightTemplateModel from "../../modals/Update-top-right-template";
import LeftTemplateModel from "../../modals/Update-top-left-template";
import SubjectContentsModal from "../../modals/Update-subject-contents-modal";
import EditGradesModal from "../../modals/update-grades-modal";
import AssessmentContentModal from "../../modals/Update-assessment-content-template";
import AssessmentContentRightModal from "../../modals/Update-assessment-contents-right-template";
import KeyTraitModal from "../../modals/Update-key-trait-template";
import CommentSectionModal from "../../modals/Update-comment-section-template";
import SubjectHeadingsModal from "../../modals/update-subject-headings-template";

export default {
  name: "AdminVeiwResults",
  components: {
    RightTemplateModel,
    LeftTemplateModel,
    SubjectContentsModal,
    AssessmentContentModal,
    AssessmentContentRightModal,
    KeyTraitModal,
    CommentSectionModal,
    EditGradesModal,
    SubjectHeadingsModal,
  },
  data() {
    return {
      template_id: this.$route.query.template_id,
      top_left: "",
      top_right: "",
      subject: "",
      subject_heading: "",
      subject_contents: "",
      assessment_heading_one: "",
      assessment_heading_two: "",
      assessment_contents: "",
      trait_key: "",
      comments: "",
      grades: "",
      school_id: "",
      school_logo: "",
      school_name: "",
      session_term: "",
      top_right_model: [],
      top_left_model: [],
      subject_cont_modal: [],
      assessment_cont_modal: [],
      assessment_cont_right_modal: [],
      key_trait_modal: [],
      comment_sect_modal: [],
      input: {
        left_key: "",
        left_value: "",
        right_key: "",
        right_value: "",
        subject_content: "",
        assessment_content: "",
        assessment_content_right: "",
        comment_section: "",
        key_trait: "",
      },
      isTopLeftForm: false,
      isTopRightForm: false,
      isSubjectHeadingForm: false,
      isSubjectContForm: false,
      isAssessmentContLeftForm: false,
      isAssessmentContRightForm: false,
      isCommentSectionForm: false,
      isKeyTraitForm: false,
      trait_key_len: [],
      select_key_trait: "",
      isTriatComplete: false,
      edit_grades_modal: [],
      subject_heading_modal: [],
      sch_details: "",
      currentImage: null,
      is_comment_img_updating: false,
      previewImage: null,
      headers: "",
    };
  },
  created() {
    const { school_id, school_logo, school_name } = JSON.parse(
      localStorage.getItem("domainDetails")
    );
    this.school_id = school_id;
    this.school_logo = school_logo;
    this.school_name = school_name;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getTemplate();
    this.isTriatComplete = true;
    this.getMoreSchoolDetails();
  },
  methods: {
    getTemplate() {
      axios
        .get(
          API_URL +
            "api/result-template/get-new-result-template/" +
            this.template_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          this.top_left = res.data.top_left;
          this.top_right = res.data.top_right;
          this.subject = res.data.subject;
          this.subject_heading = res.data.subject_heading;
          this.subject_contents = res.data.subject_content;
          this.session_term = res.data.session_term[0];
          this.assessment_heading_one = res.data.assess_heading[0];
          this.assessment_heading_two = res.data.assess_heading[1];
          this.assessment_contents = res.data.assess_content;
          this.trait_key = res.data.trait;
          this.comments = res.data.comments;
          this.grades = res.data.grades;
        });
    },
    getMoreSchoolDetails() {
      if (this.school_id) {
        axios
          .get(
            API_URL +
              "api/result-template/get-more-school-details/" +
              this.school_id +
              "/",
            { headers: this.headers }
          )
          .then((res) => {
            res.data.data.map((data) => {
              this.sch_details = data;
            });
          });
      }
    },
    // Update Top Right Side
    topLeftTemplate(id, key) {
      this.top_left_model = [];
      this.$refs.editTopLeftModal.show();
      this.top_left_model = [id, key, this.template_id];
    },
    leftTemplateUpdated(value) {
      const { name, id } = value;
      if (name) {
        const ids = "top-left" + id;
        document.getElementById(ids).textContent = name;
      }
    },
    // Delete
    async deleteTemplateTopLeft(id, left_key) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + left_key,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deleteTopLeft", { id: id, template_id: this.template_id })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },
    // Update Top Left Side
    topRightTemplate(id, key) {
      this.top_right_model = [];
      this.$refs.editTopRightModal.show();
      this.top_right_model = [id, key, this.template_id];
    },
    templateUpdated(value) {
      const { name, id } = value;
      if (name) {
        const ids = "right-rignt" + id;
        document.getElementById(ids).textContent = name;
      }
    },
    // Delete
    async deleteTemplateTopRight(id, left_key) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + left_key,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deleteTopRight", { id: id, template_id: this.template_id })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },
    // Update Subject Contents Left Side
    subjectContents(id, subject) {
      this.subject_cont_modal = [];
      this.$refs.editSubjectContModal.show();
      this.subject_cont_modal = [id, subject, this.template_id];
    },
    subjectContentUpdated(value) {
      const { subject_content, id } = value;
      if (subject_content) {
        const ids = "subject-content" + id;
        document.getElementById(ids).textContent = subject_content;
      }
    },
    // Delete Subject Contents
    async deleteSubjectContents(id, subject) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + subject,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deleteSubjectContents", {
            id: id,
            template_id: this.template_id,
          })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },
    // Grades Updates
    editGrades() {
      this.edit_grades_modal = [];
      this.$refs.editGradesModal.show();
      this.edit_grades_modal = [this.template_id];
    },
    // Update this Template Grades
    editGradesUpdated({grades_details_data}) {
      console.log('grades_details_data', grades_details_data)
      this.grades = grades_details_data
    },
    // Update Assessment Contents Left Side
    assessmentContent(id, heading_id, contents) {
      this.assessment_cont_modal = [];
      this.$refs.editAssessmentContModal.show();
      this.assessment_cont_modal = [id, heading_id, contents, this.template_id];
    },
    assessmentContentUpdated(value) {
      const { assessment_content, id } = value;
      if (assessment_content) {
        const ids = "assessment-content" + id;
        document.getElementById(ids).textContent = assessment_content;
      }
    },
    // Delete Assessment Content Left
    async deleteAssessmentContentLeft(id, heading_id, content) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + content,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deleteAssessmentContentLeft", {
            id: id,
            heading_id: heading_id,
            template_id: this.template_id,
          })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },
    // Update Assessment Contents Right Side
    assessmentContentRight(id, heading_id, contents) {
      this.assessment_cont_right_modal = [];
      this.$refs.editAssessmentContRightModal.show();
      this.assessment_cont_right_modal = [
        id,
        heading_id,
        contents,
        this.template_id,
      ];
    },
    assessmentContentRightUpdated(value) {
      const { assessment_content, id } = value;
      if (assessment_content) {
        const ids = "assessment-content-right" + id;
        document.getElementById(ids).textContent = assessment_content;
      }
    },
    // Delete Assessment Content Right
    async deleteAssessmentContentRight(id, heading_id, content) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + content,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deleteAssessmentContentRight", {
            id: id,
            heading_id: heading_id,
            template_id: this.template_id,
          })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },
    // Update Key Trait
    keyTrait(id, trait) {
      this.key_trait_modal = [];
      this.$refs.editKeyTraitModal.show();
      this.key_trait_modal = [id, trait, this.template_id];
    },
    keyTraitUpdated(value) {
      const { key_trait, id } = value;
      if (key_trait) {
        const ids = "key-trait" + id;
        document.getElementById(ids).textContent = key_trait;
      }
    },
    // Delete Key Trait
    async deleteTraitUpdated(id, trait) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + trait,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deleteTraitUpdated", {
            id: id,
            template_id: this.template_id,
          })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },
    // Update Comments Section
    selectImage() {
      this.currentImage = this.$refs.file[0].files[0];
      this.previewImage = URL.createObjectURL(this.currentImage);
    },
    uploadSignature(id) {
      if (this.currentImage == null) {
        alert(
          "Please your Signature before your Click on " +
            '"UPDATE SIGNATURE"' +
            " button"
        );
        return;
      }

      this.is_comment_btn = true;
      this.is_comment_img_updating = "Updating, Please wait...";

      // Initialize the form data
      let formData = new FormData();

      //  Add the form data we need to submit
      formData.append("id", id);
      formData.append("file", this.currentImage);
      formData.append("template_id", this.template_id);

      axios
        .post(
          API_URL + "api/result-template/update-comments-signature/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.getTemplate();
          if (res.data.img) {
            let URL = API_URL.slice(0, -1);
            document.getElementById("update_signature").src =
              URL + res.data.img;
          }

          setTimeout(() => {
            this.is_comment_img_updating = "";
            this.is_comment_btn = false;
            this.previewImage = null;
            this.currentImage = null;
          }, 3000);
        });
    },
    commentSection(id, trait) {
      this.comment_sect_modal = [];
      this.$refs.editCommentSectionModal.show();
      this.comment_sect_modal = [id, trait, this.template_id];
    },
    commentSectionUpdated(value) {
      const { comment_sect, id } = value;
      if (comment_sect) {
        const ids = "comment-section" + id;
        document.getElementById(ids).textContent = comment_sect;
      }
    },
    // Delete Key Trait
    async deletecommentSection(id, comment_sect) {
      const value = await this.$bvModal.msgBoxConfirm(
        "Are you sure, you want to Delete: " + comment_sect,
        {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Yes",
          okVariant: "success",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      );
      if (value) {
        this.$store
          .dispatch("deletecommentSection", {
            id: id,
            template_id: this.template_id,
          })
          .then((res) => {
            if (res) this.getTemplate();
          });
      }
    },

    ////// Add Content Form Submits Functions
    turnOnTopLeftForm() {
      if (this.isTopLeftForm) {
        this.isTopLeftForm = false;
      } else {
        this.isTopLeftForm = true;
      }
    },
    // add TopLeft
    addTemplateTopLeft() {
      this.$store
        .dispatch("addTopLeft", {
          top_left_key: this.input.left_key,
          top_left_value: this.input.left_value,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(this.input.left_key + " was Added Successfull!");
            this.input.left_key = "";
            this.input.left_value = "";
            this.isTopLeftForm = false;
          }
        });
    },
    turnOnTopRightForm() {
      if (this.isTopRightForm) {
        this.isTopRightForm = false;
      } else {
        this.isTopRightForm = true;
      }
    },
    // add Top Right
    addTemplateTopRight() {
      this.$store
        .dispatch("addTopRight", {
          top_right_key: this.input.right_key,
          top_right_value: this.input.right_value,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(this.input.right_key + " was Added Successfull!");
            this.input.right_key = "";
            this.input.right_value = "";
            this.isTopRightForm = false;
          }
        });
    },
    // Update Subject Headings
    subjectHeading({ key, value }) {
      this.subject_heading_modal = [];
      this.$refs.editSubjectHeadingModal.show();
      this.subject_heading_modal = [key, value, this.template_id];
    },
    subjectHeadingUpdated(value) {
      //
      const { subject_heading, key } = value;
      if (subject_heading) {
        document.getElementById(key).textContent = subject_heading;
        this.getTemplate();
      }
    },
    turnOnSubjectContForm() {
      if (this.isSubjectContForm) {
        this.isSubjectContForm = false;
      } else {
        this.isSubjectContForm = true;
      }
    },
    // add Subject Contents
    addSubjectContents() {
      this.$store
        .dispatch("addSubjectContents", {
          subject_content: this.input.subject_content,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(this.input.subject_content + " was Added Successfull!");
            this.input.subject_content = "";
            this.isSubjectContForm = false;
          }
        });
    },
    turnOnAssessmentContLeftForm() {
      if (this.isAssessmentContLeftForm) {
        this.isAssessmentContLeftForm = false;
      } else {
        this.isAssessmentContLeftForm = true;
      }
    },
    // add Assessment Content Left
    addAssessmentContentLeft(heading_id) {
      this.$store
        .dispatch("addAssessmentContentLeft", {
          assessment_content: this.input.assessment_content,
          heading_id: heading_id,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(this.input.assessment_content + " was Added Successfull!");
            this.input.assessment_content = "";
            this.isAssessmentContLeftForm = false;
          }
        });
    },
    turnOnAssessmentContRightForm() {
      if (this.isAssessmentContRightForm) {
        this.isAssessmentContRightForm = false;
      } else {
        this.isAssessmentContRightForm = true;
      }
    },
    // add Assessment Content Right
    addAssessmentContentRight(heading_id) {
      this.$store
        .dispatch("addAssessmentContentRight", {
          assessment_content: this.input.assessment_content_right,
          heading_id: heading_id,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(
              this.input.assessment_content_right + " was Added Successfull!"
            );
            this.input.assessment_content_right = "";
            this.isAssessmentContRightForm = false;
          }
        });
    },
    // turn on Key Trait
    turnOnKeyTraitForm() {
      if (this.isKeyTraitForm) {
        this.isKeyTraitForm = false;
      } else {
        let trait_key = this.trait_key; //get the key_trait details
        let key_trait_data = [];

        for (var item of trait_key) {
          key_trait_data.push(item.ranting);
        }
        const key_trait_datas = {
          key_trait_data: key_trait_data,
        };
        axios
          .post(
            API_URL + "api/result-template/get-trait-keys-balance/",
            key_trait_datas
          )
          .then((res) => {
            this.trait_key_len = res.data;

            if (res.data == 0) {
              this.isTriatComplete = false;
            } else {
              this.isTriatComplete = true;
            }
          });
        this.isKeyTraitForm = true;
      }
    },
    // add Key Trait
    addKeyTrait() {
      console.log(
        "select_key_trait",
        this.select_key_trait,
        this.input.key_trait
      );

      this.$store
        .dispatch("addTrait", {
          select_key_trait: this.select_key_trait,
          key_trait: this.input.key_trait,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(this.input.key_trait + " was Added Successfull!");
            this.input.key_trait = "";
            this.isKeyTraitForm = false;
          }
        });
    },
    turnOnCommentSectionForm() {
      if (this.isCommentSectionForm) {
        this.isCommentSectionForm = false;
      } else {
        this.isCommentSectionForm = true;
      }
    },
    // add Comment Section
    addcommentSection() {
      //console.log('tag', '')
      this.$store
        .dispatch("addcommentSection", {
          comment_section: this.input.comment_section,
          template_id: this.template_id,
        })
        .then((res) => {
          if (res) {
            this.getTemplate();
            alert(this.input.comment_section + " was Added Successfull!");
            this.input.comment_section = "";
            this.comment_section = false;
          }
        });
    },
  },
};
</script>

<style scoped>
#admin-veiw-accounts {
  background-color: white !important;
  padding: 30px !important;
}
#logo_school_name {
  margin-bottom: 30px !important;
  border: 0.5px solid #cfcfcf !important;
  padding: 20px;
}
#student_details_section {
  border: 0.5px solid #cfcfcf !important;
  padding: 1px;
}
td {
  font-size: 16px !important;
  border: 1px solid #b0b0b0 !important;
  padding: 10px !important;
}
th {
  font-size: 16px !important;
}
#div_size {
  font-size: 18px !important;
  padding: 5px;
}
.text-primary {
  color: #000000 !important;
}
.glyphicon {
  font-size: 10px !important;
}
.bold-text {
  font-weight: bold !important;
}
i {
  font-size: 12px !important;
  margin-right: 5px !important;
  margin-left: -2px !important;
  cursor: pointer !important;
}
input {
  height: 30px !important;
  background-color: white !important;
}
</style>
