<template>
  <div id="teacher-view-edit-results">
    <div class="row">
      <h1>Teacher View & Edit Results</h1>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div
            class="card-header card-header-success"
            style="margin-top: 15px !important"
          >
            <h3>View Result</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          <div class="row" align="center">
            <div
              class="col-md-4 col-sm-4 col-xs-12"
              style="margin-left: 25px !important"
            >
              <select
                @change="filterSession"
                v-model="filter_by_session"
                id="filter_by_session"
                class="form-control"
                style="margin-top: 10px"
              >
                <option value="" disabled selected>Filter by Session</option>
                <option value="">Remove Selection</option>
                <option
                  v-for="item in session_data"
                  :key="item.id"
                  :value="item.school_session"
                >
                  {{ item.school_session }}
                </option>
              </select>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <select
                @change="filterClass"
                v-model="filter_by_class"
                id="filter_by_class"
                class="form-control"
                style="margin-top: 10px"
              >
                <option value="" disabled selected>Filter by Class</option>
                <option value="">Remove Selection</option>
                <option
                  v-for="item in classes_data"
                  :key="item.id"
                  :value="item.class_room"
                >
                  {{ item.class_room }}
                </option>
              </select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12" style="margin-top: 10px">
              <!-- <label for="filter_by_terms"></label> -->
              <select
                @change="filterTerms"
                v-model="filter_by_terms"
                id="filter_by_terms"
                class="form-control"
              >
                <option value="" disabled selected>Filter by Terms</option>
                <option value="">Remove Selection</option>
                <option value="First Term">First Term</option>
                <option value="Second Term">Second Term</option>
                <option value="Third Term">Third Term</option>
              </select>
            </div>
          </div>
          <div class="card-body table-responsive">
            <br />
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Student ID</th>
                <th>Full name</th>
                <th>Subject</th>
                <th>CA Score</th>

                <th>Exam Score</th>
                <th>Term Total</th>
                <th>Grade</th>
                <th>Edit Result</th>
                <th>Class/Term/Session</th>
              </thead>
              <tbody>
                <tr v-for="item in subjects_list" v-bind:key="item.id">
                  <td>{{ item.student_user }}</td>
                  <td>
                    {{ item.last_name }} {{ item.first_name }}
                    {{ item.middle_name }}
                  </td>
                  <td>{{ item.subject }}</td>
                  <td>{{ item.ca_score }}</td>
                  <td>{{ item.exam_score }}</td>
                  <td>
                    <span v-if="item.is_teacher_submit == 'Submitted'">
                      {{ item.term_total }}
                    </span>
                  </td>
                  <td>
                    <span v-if="item.is_teacher_submit == 'Submitted'">
                      {{ item.grade }}
                    </span>
                  </td>
                  <td>
                    <button
                      v-if="item.is_teacher_submit == 'Pending'"
                      class="btn btn-warning"
                      @click.prevent="
                        updateSubject(
                          item.id,
                          item.result_id,
                          item.student_id,
                          item.template_id
                        )
                      "
                    >
                      Update
                    </button>
                    <button
                      v-if="item.is_teacher_submit == 'Submitted'"
                      class="btn btn-success"
                      :disabled="true"
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    {{ item.class_room }}/{{ item.sch_terms }}/{{
                      item.sch_session
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { API_URL } from "../common/config";
import http from "../common/http-common";

export default {
  name: "TeacherViewEditResults",
  components: {
    // BackendScripts
  },
  data() {
    return {
      filter_by_class: "",
      filter_by_terms: "",
      filter_by_session: "",
      subjects_list: "",
      headers: "",
      username: "",
      session_data: "",
      classes_data: "",
    };
  },
  created() {
    const { school_id, school_logo, school_name } = JSON.parse(
      localStorage.getItem("domainDetails")
    );
    this.school_id = school_id;
    this.school_logo = school_logo;
    this.school_name = school_name;

    var { token, username } = JSON.parse(localStorage.getItem("userData"));
    this.headers = { Authorization: `Token ${token}` };
    this.username = username;

    this.getClassAndSession();
    this.getStudentSubjects();
  },
  methods: {
    async getClassAndSession() {
      await http
        .get("students-reg/get-class-and-session/" + this.school_id + "/", {
          headers: this.headers,
        })
        .then((res) => {
          this.classes_data = res.data.data;
          this.session_data = res.data.data2;
        });
    },
    getStudentSubjects() {
      axios
        .get(
          API_URL +
            "api/school-result/get-subject-assigned-to-teacher/" +
            this.school_id +
            "/" +
            this.username +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          console.log("subjects_list ", res.data);
          this.subjects_list = res.data.results;
        });
    },
    filterClass() {
      if (this.filter_by_class.length > 0) {
        this.subjects_list = this.subjects_list.filter(
          (res) => res.class_room == this.filter_by_class
        );
      } else {
        this.getStudentSubjects();
      }
    },
    filterTerms() {
      if (this.filter_by_terms.length > 0) {
        this.subjects_list = this.subjects_list.filter(
          (res) => res.sch_terms == this.filter_by_terms
        );
      } else {
        this.getStudentSubjects();
      }
    },
    filterSession() {
      if (this.filter_by_session.length > 0) {
        this.subjects_list = this.subjects_list.filter(
          (res) => res.sch_session == this.filter_by_session
        );
      } else {
        this.getStudentSubjects();
      }
    },
    updateSubject(id, result_id, student_id, template_id) {
      this.$router.push(
        "/teacher/teacher-update-results?id=" +
          id +
          "&student_id=" +
          student_id +
          "&result_id=" +
          result_id +
          "&template_id=" +
          template_id
      );
    },
  },
};
</script>

<style></style>
