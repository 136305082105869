<template>
  <div id="admin-veiw-all-accounts">
    <div class="row">
      <h1>Admin View All Accounts</h1>
  </div>
  <div class="row">
  </div>
  <div class="row">
      <br> <hr> <br>

    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-success">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View/Edit Student Account</b></h4>
          </div>
        </div>  
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/view-student-account"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View/Edit Teacher Account</b></h4>
          </div>
        </div>              
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/view-teacher-account"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-success">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View/Edit Parent Account</b></h4>
          </div>
        </div>  
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/view-parent-account"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
  </div><!-- End Create Account Links -->

  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'AdminVeiwAllAccounts',
    components: {
      // BackendScripts
    }
}
</script>

<style>

</style>