<template>
  <div id="admin-create-results">
    <div class="row">
      <h1>Create Student Account</h1>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="card" style="margin-top:50px !important;">
          <div
            class="card-header card-header-primary"
            style="margin-top:30px !important;"
          >
            <h3>Create Student Accounts</h3>
          </div>
          <div class="card-body">
            <p align="right">
              <button
                class="btn btn-success"
                style="width:150px;"
                @click="goBack()"
              >
                {{ go_back }}
              </button>
            </p>
            <form @submit.prevent="createStudentAccount" id="form1">
              <br /><br />
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>Upload Passport Photo</label><br />
                    <label
                      class="btn btn-primary p-0"
                      style="width:150px; margin-bottom:25px;"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="file"
                        @change="selectImage"
                      />
                      <h3
                        style="color:#fff; content-align:center;"
                        valign="middle"
                      >
                        <strong>Select Photo</strong>
                      </h3>
                    </label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div v-if="previewImage">
                    <img
                      class="preview my-3"
                      :src="previewImage"
                      alt=""
                      width="25%"
                    />
                  </div>
                  <br /><br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Student ID</label>
                    <input
                      type="text"
                      name="username"
                      v-model="username"
                      required
                      class="form-control"
                      placeholder="Student ID"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Parent Email</label>
                    <input
                      type="email"
                      name="parent_email"
                      v-model="parent_email"
                      required
                      class="form-control"
                      placeholder="Parent Email"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Password</label>
                    <input
                      type="text"
                      name="password"
                      v-model="password"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      v-model="first_name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      v-model="last_name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Middel Name</label>
                    <input
                      type="text"
                      name="other_name"
                      v-model="other_name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="">Title</label>
                    <select
                      class="form-control"
                      name="title"
                      v-model="title"
                      required
                    >
                      <option value="" selected disabled
                        >Select Title...</option
                      >
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Miss">Mrs</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="">Gender</label>
                    <select
                      class="form-control"
                      name="gender"
                      v-model="gender"
                      required
                    >
                      <option value="" selected disabled
                        >Select Gender...</option
                      >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="">Date of Birth</label>
                    <input
                      type="date"
                      name="date_of_birth"
                      v-model="date_of_birth"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label style="margin-bottom:10px;">Admitted Session/Year</label>
                    <!-- <input readonly="readonly" type="text" name="entering_session" v-model="entering_session" required class="form-control"> -->
                    <select
                      class="form-control"
                      name="entering_session"
                      v-model="entering_session"
                      required
                    >
                      <option value="" selected disabled
                        >Select Session..</option
                      >
                      <option
                        v-for="item in session_data"
                        :key="item.id"
                        :value="item.school_session"
                        >{{ item.school_session }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label style="margin-bottom:10px;">Admitted Class</label>
                    <!-- <input readonly="readonly" type="text" name="classes" v-model="classes" required class="form-control"> -->
                    <select
                      class="form-control"
                      name="classes"
                      v-model="classes"
                      required
                    >
                      <option value="" selected disabled>Select Class..</option>
                      <option
                        v-for="item in classes_data"
                        :key="item.id"
                        :value="item.class_room"
                        >{{ item.class_room }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label style="margin-bottom:10px;">Admitted Term</label>
                    <!-- <input readonly="readonly" type="text" name="entering_term" v-model="entering_term" required class="form-control"> -->
                    <select
                      class="form-control"
                      name="entering_term"
                      v-model="entering_term"
                      required
                    >
                      <option value="" selected disabled>Select Term...</option>
                      <option value="First Term">First Term</option>
                      <option value="Second Term">Second Term</option>
                      <option value="Third Term">Third Term</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="">Adress</label>
                    <input
                      type="text"
                      name="address"
                      v-model="address"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">City</label>
                    <input
                      type="text"
                      name="city"
                      v-model="city"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Residence LGA</label>
                    <input
                      type="text"
                      name="lga"
                      v-model="residence_lga"
                      required
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">State of Residence</label>
                    <input
                      type="text"
                      name="state_of_residence"
                      v-model="state_of_residence"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">State of Origin </label>
                    <input
                      type="text"
                      name="state_of_origin"
                      v-model="state_of_origin"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Origin LGA</label>
                    <input
                      type="text"
                      name="lga"
                      v-model="origin_lga"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Country</label>
                    <input
                      type="text"
                      name="country"
                      v-model="country"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="">Phone number / GSM</label>
                    <input
                      type="text"
                      name="phone_number"
                      v-model="phone_number"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="">Whatsapp</label>
                    <input
                      type="text"
                      name="whatsapp"
                      v-model="whatsapp"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-12 col-xs-12" v-if="currentImage">
                  <div class="progress" style="width: 100%">
                    <div
                      class="progress-bar progress-bar-success"
                      role="progressbar"
                      :aria-valuenow="progress"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: progress + '%' }"
                    >
                      {{ progress }}%
                    </div>
                  </div>
                </div>
              </div>
              <button button_status type="submit" class="btn btn-success">Submit</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <BackendScripts /> -->
  </div>
</template>

<script>
import http from "../../common/http-common";
import UploadService from "../../services/UploadFilesService";

export default {
  name: "CreateStudentAccount",
  components: {
    // BackendScripts
  },
  data() {
    return {
      go_back: "<<< Back",
      template_id: this.$route.query.template_id,
      username: "",
      parent_email: "",
      password: "",
      first_name: "",
      last_name: "",
      other_name: "",
      classes: "",
      entering_term: "",
      entering_session: "",
      date_of_birth: "",
      gender: "",
      title: "",
      address: "",
      residence_lga: "",
      city: "",
      state_of_residence: "",
      state_of_origin: "",
      origin_lga: "",
      country: "",
      phone_number: "",
      whatsapp: "",

      button_status: null,

      currentImage: undefined,
      previewImage: undefined,
      progress: 0,

      session_class: "",
      classes_data: [],
      session_data: [],
      headers: ''
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem('userData')).token
    this.headers = {'Authorization': `Token ${token}`}

    this.getClassAndSession();
    this.getSchoolSessionClassessList();
  },
  methods: {
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
    },
    async getClassAndSession() {
      await http
        .get("students-reg/get-class-and-session/" + this.school_id + "/", { headers: this.headers })
        .then((res) => {
          this.classes_data = res.data.data;
          this.session_data = res.data.data2;
        });
    },
    async getSchoolSessionClassessList() {
      await http
        .get("students-reg/get-classes-details/" + this.template_id + "/", { headers: this.headers })
        .then((res) => {
          this.session_class = res.data.map(() => {
            // this.classes = data.class_room
            // this.entering_term = data.sch_terms
            // this.entering_session = data.sch_session
          });
        });
    },
    createStudentAccount() {
      if (
        this.username &&
        this.password &&
        this.first_name &&
        this.last_name &&
        this.parent_email &&
        this.phone_number &&
        this.whatsapp &&
        this.other_name &&
        this.date_of_birth &&
        this.address &&
        this.city &&
        this.residence_lga &&
        this.state_of_residence &&
        this.state_of_origin &&
        this.origin_lga &&
        this.country &&
        this.classes &&
        this.entering_term &&
        this.entering_session &&
        this.gender &&
        this.title &&
        this.currentImage
      ) {
        this.button_status = 'disabled'
        this.progress = 0;
        // Initialize the form data
        let formData = new FormData();

        //  Add the form data we need to submit
        formData.append("student_id", this.username);
        formData.append("password", this.password);
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append('parent_email', this.parent_email);
        formData.append("phone_number", this.phone_number);
        formData.append("middel_name", this.other_name);
        formData.append("title", this.title);
        formData.append("whatsapp", this.whatsapp);
        formData.append("date_of_birth", this.date_of_birth);
        formData.append("address", this.address);
        formData.append("city", this.city);
        formData.append("residence_lga", this.residence_lga);
        formData.append("state_of_residence", this.state_of_residence);
        formData.append("origin_lga", this.origin_lga);
        formData.append("state_of_origin", this.state_of_origin);
        formData.append("country", this.country);
        formData.append("gender", this.gender);

        formData.append("current_class", this.classes);
        formData.append("entering_term", this.entering_term);
        formData.append("entering_session", this.entering_session);

        formData.append("template_id", this.template_id);
        formData.append("school_id", this.school_id);

        formData.append("profile_photo", this.currentImage);

        UploadService.upload(formData, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((res) => {
            console.log("res ", res.data.data);
            if (res.data.message == false) {
              alert("Registration was Successfully!");
              this.button_status = null
              this.$router.push("/admin/view-student-account-details?student_id=" + res.data.data[0].student_id);

            } else {
              alert(res.data.message);
            }
          })
          .catch((error) => {
            alert(`Error! " + ${error}`);
          });
      } else {
        alert("Please Enter all Required Fields!");
      }
    },
    goBack() {
      this.$router.push("/admin/admin-craete-account");
    },
  },
};
</script>

<style></style>
