/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import { store } from './store'
// import ApiService from "./common/api.service";

//import 'jquery';
//import $ from 'jquery';
//import 'popper.js';
//import 'bootstrap';
import * as VueSpinnersCss from "vue-spinners-css";

Vue.use(VueSpinnersCss)

//import 'bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// https://bootstrap-vue.org/docs/components/modal#
// Install BootstrapVue
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)






import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

Vue.use(VueMaterial)

import '@/assets/fonts/iconfont/material-icons.css';
import '@/assets/fonts/font-awesome/css/font-awesome.min.css';
import '@/assets/css/animated-headline.css';
import '@/assets/magnific-popup/magnific-popup.css';
import '@/assets/flexSlider/flexslider.css';
import '@/assets/materialize/css/materialize.min.css';
import '@/assets/bootstrap/css/bootstrap.min.css';
import '@/assets/css/shortcodes/shortcodes.css';
import '@/assets/style.css';
import '@/assets/css/skins/creative.css';



Vue.component('adminBackend', require('./layouts/adminBackend.vue').default);
Vue.component('studentBackend', require('./layouts/studentBackend.vue').default);
Vue.component('teacherBackend', require('./layouts/teacherBackend.vue').default);
Vue.component('parentBackend', require('./layouts/parentBackend.vue').default);


Vue.config.productionTip = false



new Vue({

    router,
    store,
    beforeCreate() { this.$store.commit('initialiseStore'); },
    render: h => h(App),
    created() {
        if (localStorage.getItem('userData')) {

            this.$store.dispatch('autoSignIn', JSON.parse(localStorage.getItem('userData')))

            // this.$store.commit('setUser', JSON.parse(localStorage.getItem('userData')))
            // this.$store.commit('setToken', JSON.parse(localStorage.getItem('tokenData')))
            // this.$store.commit('setAdmin', JSON.parse(localStorage.getItem('isAdminData')))
            // console.log('setUser2', localStorage.getItem('userData'))
        }
    },
}).$mount('#app');