<template>
  <div id="assign-teacher-to-result">
    <div class="row">
      <h2>Admin Assign Teacher to Subjuects</h2>
    </div>
    <!-- <BackendScripts /> -->
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div
            class="card-header card-header-primary"
            style="margin-top: 15px !important"
          >
            <h3>
              Assign Teacher to
              <span style="font-weight: bold">
                ({{ users.last_name }} {{ users.first_name }}
                {{ users.middel_name }})
              </span>
              Subjuects
            </h3>
          </div>
          <p style="font-size: 16px; color: blue" align="right">
            <a>
              {{ result_heading.sch_session }} |
              {{ result_heading.class_room }} |
              {{ result_heading.sch_terms }} Results</a
            >
          </p>
          <div class="card-body table-responsive">
            <br />
            <form id="assign-teacher" ref="assign_teacher_ref">
              <table class="table table-hover">
                <thead class="text-primary">
                  <th>Assign Teacher</th>
                  <th>Assign Status</th>
                  <th>Apply</th>
                  <th>Subject</th>
                  <th>CA Score</th>
                  <th>Exam Score</th>
                  <th>Term Total</th>
                  <th>Grade</th>
                </thead>
                <tbody>
                  <tr v-for="item in subject_list" v-bind:key="item.id">
                    <td>
                      <div>
                        <label>Assign Teacher</label>
                        <div>
                          <DropdownList
                            @on-item-selected="dropdownSelection = $event"
                            @on-item-reset="dropdownSelection = {}"
                            :data_id="item.id"
                          />
                          <!-- The selected country name will be visible below, when selected -->
                          <input
                            :id="'teacher_id' + item.id"
                            :name="'teacher' + item.id"
                            :value="dropdownSelection.teacher_id"
                            type="hidden"
                          />
                        </div>
                        <p
                          v-if="item.assign_teacher"
                          :id="'select_teacher' + item.id"
                          style="font-size: 13px !important; color: #262072"
                        >
                          <b>Assigned Teacher:</b> {{ item.assign_teacher }}
                        </p>
                        <p
                          v-else
                          :id="'select_teacher' + item.id"
                          style="font-size: 13px !important; color: #262072"
                        ></p>
                        <!-- 
                        <select
                          :id="'teacher_id' + item.id"
                          :name="'teacher' + item.id"
                          :v-model="'teacher_model' + item.id"
                          class="form-control"
                        >
                          <option v-if="item.assign_teacher" selected>
                            {{ item.assign_teacher }}
                          </option>
                          <option v-else selected value="">
                            Select teacher...
                          </option>
                          <option
                            v-for="teacher in teacher_list"
                            :key="teacher.teacher_id"
                            :id="teacher.teacher_id"
                            :value="teacher.teacher_id"
                          >
                            {{
                              "(" +
                              teacher.username +
                              ")" +
                              " " +
                              teacher.title +
                              " " +
                              teacher.last_name +
                              " " +
                              teacher.first_name
                            }}
                          </option>
                        </select> -->
                      </div>
                    </td>
                    <td>
                      <div>
                        <label :for="'status_id' + item.id"
                          >Assign Status</label
                        >

                        <select
                          :id="'status_id' + item.id"
                          :name="'teacher_status' + item.id"
                          :v-model="'teacher_model_status' + item.id"
                          class="form-control"
                        >
                          <option selected :value="item.is_teacher_submit">
                            {{ item.is_teacher_submit }}
                          </option>
                          <option v-if="item.is_teacher_submit == 'Submitted'">
                            Pending
                          </option>
                          <option v-if="item.is_teacher_submit == 'Pending'">
                            Submitted
                          </option>
                          <option>Remove</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <button
                        @click.prevent="submitAssignedTeacher(item.id)"
                        class="btn btn-success"
                      >
                        Apply
                      </button>
                    </td>
                    <td>
                      <span style="font-weight: bold"> {{ item.subject }}</span>
                      <div>
                        <span
                          :id="'update_subject_status' + item.id"
                          style="color: green; font-weight: bold"
                          v-if="
                            item.assign_teacher &&
                            item.is_teacher_submit == 'Submitted'
                          "
                          ><b>Assigned ({{ item.is_teacher_submit }})</b>
                        </span>
                        <span
                          :id="'update_subject_status' + item.id"
                          style="color: orange; font-weight: bold"
                          v-else-if="
                            item.assign_teacher &&
                            item.is_teacher_submit == 'Pending'
                          "
                          ><b>Assigned ({{ item.is_teacher_submit }})</b>
                        </span>
                        <span
                          :id="'update_subject_status' + item.id"
                          v-else
                          style="color: red; font-weight: bold"
                          ><b>Not Assign</b>
                        </span>
                      </div>
                    </td>
                    <td>
                      {{ item.ca_score }}
                    </td>
                    <td>
                      {{ item.exam_score }}
                    </td>
                    <td>
                      {{ item.term_total }}
                    </td>
                    <td>
                      {{ item.grade }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
      <!-- End Column -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { API_URL } from "../../common/config";
import http from "../../common/http-common";
import DropdownList from "../../components/DropdownList.vue";

export default {
  name: "assign-teacher-to-result",
  components: {
    DropdownList,
  },
  data() {
    return {
      template_id: this.$route.query.template_id,
      student_id: this.$route.query.student_id,
      result_id: this.$route.query.result_id,
      subject_list: [],
      result_heading: "",
      users: "",
      headers: "",
      school_id: "",
      teacher_list: "",
      is_teacher_submit: "",
      teacher_id: "",
      dropdownSelection: {},
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getSubjectList();
    this.getTeacherList();
  },
  methods: {
    getSubjectList() {
      axios
        .get(
          API_URL +
            "api/school-result/get-student-subjects/" +
            this.school_id +
            "/" +
            this.template_id +
            "/" +
            this.result_id +
            "/" +
            this.student_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          //console.log("res.data ", res.data.subject);
          this.subject_list = res.data.subject;
          res.data.result.map((data) => {
            this.result_heading = data;
          });
          this.users = res.data.users;
        });
    },
    getTeacherList() {
      axios
        .get(
          API_URL +
            "api/teachers-reg/get-teachers-list/" +
            this.school_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          //console.log("teacher_list ", res.data.data);
          this.teacher_list = res.data.data;
        });
    },
    submitAssignedTeacher(id) {
      let status_id = "status_id" + id;
      let teacher_id = "teacher_id" + id;
      let sel_teacher = "select_teacher" + id;
      let select_teacher = document.getElementById(sel_teacher);
      const status = document.getElementById(status_id).value;
      const teacher = document.getElementById(teacher_id).value;
      if (teacher == "") {
        alert("Please Select Teacher's Details!");
        return;
      }
      const submitData = {
        id: id,
        status: status,
        teacher_id: teacher,
      };
      axios
        .post(
          API_URL +
            "api/school-result/submit-assigned-teacher/" +
            this.school_id +
            "/" +
            this.result_id +
            "/",
          submitData,
          { headers: this.headers }
        )
        .then((res) => {
          if (res.data.data) {
            let update_subject_status = "update_subject_status" + id;
            let teacher_user = res.data.teacher;
            var element = document.getElementById(update_subject_status);
            element.style.fontWeight = "bold";

            if (status == "Remove") {
              element.textContent = "Not Assign";
              element.style.color = "red";
              document.getElementById(teacher_id).value = "";
              select_teacher.textContent = "";
            } else if (status == "Pending") {
              element.textContent = "Assigned " + "(" + status + ")";
              element.style.color = "orange";
              select_teacher.innerHTML =
                "<b>Assigned Teacher:</b> " + teacher_user;
              select_teacher.style.color = "#262072";
            } else {
              element.textContent = "Assigned " + "(" + status + ")";
              element.style.color = "green";
              select_teacher.innerHTML =
                "<b>Assigned Teacher:</b> " + teacher_user;
              select_teacher.style.color = "#262072";
            }

            alert("Teacher Successfully Assign a Subject!");
          }
        });
    },
  },
};
</script>

<style scoped>
a {
  font-size: 16px !important;
}
td {
  height: 240px !important;
}
</style>
