<template>
  <div>
    <form ref="form" id="form" @submit.prevent="handleSubmit">
      <b-form-group
        label-for="grades-details-input"
        invalid-feedback="grades_details is required"
        :state="grades_detailsState"
      >
        <div class="row" v-for="item in grades_details_data" :key="item.id">
          <input type="hidden" :id="item.id" name="id" :value="item.id" />
          <div class="col-md-3 col-sm-3 col-xs-12">
            <b-form-input
              :id="'grades_id' + item.id"
              name="grade"
              v-model="item.grade"
              :state="grades_detailsState"
              required
            ></b-form-input>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-12">
            <b-form-input
              :id="'mark_from_id' + item.id"
              name="mark_from"
              v-model="item.mark_from"
              :state="grades_detailsState"
              required
            ></b-form-input>
          </div>
          <div class="col-md-2 col-sm-2 col-xs-12">
            <b-form-input
              :id="'mark_to_id' + item.id"
              name="mark_to"
              v-model="item.mark_to"
              :state="grades_detailsState"
              required
            ></b-form-input>
          </div>
          <div class="col-md-5 col-sm-5 col-xs-12">
            <b-form-input
              :id="'comment_id' + item.id"
              name="comment"
              v-model="item.comment"
              :state="grades_detailsState"
              required
            ></b-form-input>
          </div>
        </div>
      </b-form-group>
      <b-button type="submit" @click="onClickGradesButton" variant="primary"
        >Update</b-button
      >
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../common/config";
/* eslint-disable no-unused-vars */
export default {
  name: "update-grades-modal",
  data() {
    return {
      grades_details: [],
      grades_detailsState: null,
      grades_details_data: "",
    };
  },
  props: {
    data: {},
  },
  created() {
    this.getUpdatedTemplate();
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.grades_detailsState = valid;
      return valid;
    },

    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      let form = this.$refs.form
      let formDatas = []
      let x = 0
      for(x; x < form.id.length; x++){
        formDatas.push({ id: form.id[x].value, grade: form.grade[x].value, mark_from: form.mark_from[x].value, mark_to: form.mark_to[x].value, comment: form.comment[x].value })
        if(form.id.length == x+1){
          this.grades_details = formDatas
        }
      }
      this.updateTemplate(formDatas);
      
    },
    getUpdatedTemplate() {
      axios
        .get(
          API_URL +
            "api/result-template/get-grades-details/" +
            this.data[0] +
            "/"
        )
        .then((res) => {
          this.grades_details_data = res.data;
        });
      
    },
    updateTemplate(postData) {
      // console.log('== postData ',postData)
      axios
        .post(API_URL + "api/result-template/update-grades-details/", postData)
        .then((res) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-prevent-closing-edit-grades");
          });
        });
        
    },
    onClickGradesButton(event) {
      const grades_details_data = this.grades_details_data
      this.$emit("clicked", {grades_details_data});
    },
  },
};
</script>

<style>
b-form-input {
  margin-top: 5px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
b-form-group {
  padding: 20px !important;
}
</style>
