<template>
  <div id="admin-veiw-teacher-accounts">
    <div class="row">
      <h1>Admin Veiw Teacher Accounts</h1>
    </div>
    <!-- <BackendScripts /> -->
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div
            class="card-header card-header-primary"
            style="margin-top: 15px !important"
          >
            <h3>View & Edit Account</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>

          <div class="card-body table-responsive">
            <br />
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Teacher ID</th>
                <th>Username</th>
                <th>Full name</th>
                <th>Edit Account</th>
                <th>View Account</th>
              </thead>
              <tbody>
                <tr v-for="item in teachers_list" v-bind:key="item.id">
                  <td>{{ item.teacher_id }}</td>
                  <td>{{ item.username }}</td>
                  <td>
                    {{ item.title }} {{ item.last_name }} {{ item.first_name }}
                  </td>
                  <td>
                    <button
                      class="btn btn-primary"
                      @click="viewTeacherDetails(item.teacher_id)"
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <button
                      class="btn btn-success"
                      @click="editTeacherDetails(item.teacher_id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
    </div>
  </div>
</template>

<script>
import http from "../../common/http-common";

export default {
  name: "admin-veiw-teacher-accounts",
  components: {
    // BackendScripts
  },
  data() {
    return {
      headers: "",
      teachers_list: "",
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getTeacherList();
  },
  methods: {
    async getTeacherList() {
      await http
        .get("teachers-reg/get-teachers-list/" + this.school_id + "/", {
          headers: this.headers,
        })
        .then((res) => {
          this.teachers_list = res.data.data;
        });
    },
    viewTeacherDetails(teacher_id) {
      this.$router.push(
        "/admin/view-teacher-account-details?teacher_id=" + teacher_id
      );
    },
    editTeacherDetails(teacher_id) {
      this.$router.push(
        "/admin/admin-update-teacher-details?teacher_id=" + teacher_id
      );
    },
  },
};
</script>

<style></style>
