<template>
  <div id="admin-create-results">
    <div class="row">
      <h1>Create Teacher Account</h1>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="card" style="margin-top: 50px !important">
          <div
            class="card-header card-header-primary"
            style="margin-top: 30px !important"
          >
            <h3>Create Teacher Accounts</h3>
          </div>
          <div class="card-body">
            <form @submit.prevent="createTeacherAccount" id="form1">
              <br /><br />
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>Upload Teacher's Passport Photo</label><br />
                    <label
                      class="btn btn-primary p-0"
                      style="width: 150px; margin-bottom: 25px"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="file"
                        @change="selectImage"
                      />
                      <h3
                        style="color: #fff; content-align: center"
                        valign="middle"
                      >
                        <strong>Select Photo</strong>
                      </h3>
                    </label>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div v-if="previewImage">
                    <img
                      class="preview my-3"
                      :src="previewImage"
                      alt=""
                      width="25%"
                    />
                  </div>
                  <br /><br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Userame</label>
                    <input
                      @keyup.prevent="ifUsernameExist"
                      type="text"
                      name="username"
                      v-model="username"
                      required
                      class="form-control"
                    />
                    <span
                      v-if="username_exist"
                      style="color: red; font-size: 14px"
                      >{{ username_exist_msg }}</span
                    >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Email</label>
                    <input
                      @keyup.prevent="ifEmailExist"
                      type="email"
                      name="username"
                      v-model="email"
                      required
                      class="form-control"
                    />
                    <span
                      v-if="email_exist"
                      style="color: red; font-size: 14px"
                      >{{ email_exist_msg }}</span
                    >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Password</label>
                    <input
                      type="text"
                      name="password"
                      v-model="password"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Title</label>
                    <select
                      class="form-control"
                      name="title"
                      v-model="title"
                      required
                    >
                      <option value="" selected disabled>
                        Select title...
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Miss">Mrs</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Fist Name</label>
                    <input
                      type="text"
                      name="first_name"
                      v-model="first_name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      v-model="last_name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="margin-top: 8px">
                  <div class="form-group">
                    <label class="">Adress</label>
                    <input
                      type="text"
                      name="address"
                      v-model="address"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">LGA</label>
                    <input
                      type="text"
                      name="lga"
                      v-model="lga"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">City / State</label>
                    <input
                      type="text"
                      name="city_state"
                      v-model="city_state"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="">Country</label>
                    <input
                      type="text"
                      name="country"
                      v-model="country"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="">Phone number / GSM</label>
                    <input
                      type="text"
                      name="phone_number"
                      v-model="phone_number"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="">Whatsapp</label>
                    <input
                      type="text"
                      name="whatsapp"
                      v-model="whatsapp"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <button
                :disabled="button_status"
                type="submit"
                class="btn btn-success"
              >
                Submit
              </button>
              <div class="clearfix"></div>
            </form>
          </div>
          <p v-if="is_signingup" style="color: red">
            Processing, Please Wait....
          </p>
        </div>
      </div>
    </div>
    <!-- <BackendScripts /> -->
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { API_URL } from "../../common/config";
import http from "../../common/http-common";
import UploadService from "../../services/UploadFilesService";

export default {
  name: "AdminCreateResutls",
  components: {
    // BackendScripts
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      title: "",
      address: "",
      lga: "",
      city_state: "",
      country: "",
      phone_number: "",
      whatsapp: "",

      button_status: null,
      email_exist: false,

      currentImage: undefined,
      previewImage: undefined,
      progress: 0,

      headers: "",
      email_exist_msg: "",
      username_exist: false,
      username_exist_msg: "",
      is_signingup: false,
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };
  },
  methods: {
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
    },
    async ifEmailExist() {
      await http
        .post(
          "teachers-reg/check-if-email-exisits/" + this.school_id + "/",
          { email: this.email },
          { headers: this.headers }
        )
        .then((res) => {
          console.log("res ", res.data.data);
          this.email_exist = res.data.data;
          if (this.email_exist) {
            this.email_exist_msg = "Email Exisit Already, use another email!";
          } else {
            this.email_exist_msg = "";
          }
        });
    },
    async ifUsernameExist() {
      await http
        .post(
          "teachers-reg/check-if-username-exisits/" + this.school_id + "/",
          { username: this.username },
          { headers: this.headers }
        )
        .then((res) => {
          this.username_exist = res.data.data;
          console.log("username_exist ", this.username_exist);
          if (this.username_exist) {
            this.username_exist_msg =
              "Username Exisit Already, use another username!";
          } else {
            this.username_exist_msg = "";
          }
        });
    },
    createTeacherAccount() {
      if (
        !this.username_exist &&
        !this.email_exist &&
        this.username &&
        this.password &&
        this.first_name &&
        this.last_name &&
        this.email &&
        this.phone_number &&
        this.whatsapp &&
        this.address &&
        this.city_state &&
        this.lga &&
        this.country &&
        this.title &&
        this.currentImage
      ) {
        this.is_signingup = true;
        this.button_status = "disabled";
        this.progress = 0;
        // Initialize the form data
        let formData = new FormData();

        //  Add the form data we need to submit
        formData.append("username", this.username);
        formData.append("password", this.password);
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("email", this.email);
        formData.append("phone_number", this.phone_number);
        formData.append("title", this.title);
        formData.append("whatsapp", this.whatsapp);
        formData.append("address", this.address);
        formData.append("city_state", this.city_state);
        formData.append("lga", this.lga);
        formData.append("country", this.country);

        formData.append("school_id", this.school_id);

        formData.append("profile_photo", this.currentImage);

        UploadService.uploadTecher(formData, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((res) => {
            console.log("res ", res.data.data);
            if (res.data.message == false) {
              alert("Registration was Successfully!");
              this.is_signingup = false;
              this.button_status = null;
              this.$router.push(
                "/admin/view-teacher-account-details?teacher_id=" +
                  res.data.data[0].teacher_id
              );
            } else {
              alert(res.data.message);
            }
          })
          .catch((error) => {
            alert(`Error! " + ${error}`);
          });
      } else if (this.email_exist) {
        alert("The Email you Enter already exist, kindly use another!");
      } else if (this.username_exist) {
        alert("The Username you Enter already exist, kindly use another!");
      } else {
        alert("Please Enter all Required Fields!");
      }
    },
  },
};
</script>

<style></style>
