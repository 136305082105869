<template>
  <div id="user-dashboard">
    <div class="row">
      <h1>Student Dashboard</h1>
  </div>
  <div class="row">
  </div>
  <div class="row">
      <br> <hr> <br>
    <div class="col-md-4">
      
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View Result</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/student/student-view-result"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-chart">
        <div class="card-header card-header-warning">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Change Password</b></h4>
          </div>
        </div>              
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/student/student-change-password"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Logout</b></h4>
          </div>
        </div>  
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/student/logout"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
/* eslint-disable */
// import BackendScripts from '../components/Partials/backend-scripts'
import {store} from '../store'
export default {
    name: 'UserDashboard',
    components: {
      // BackendScripts
    },
    data() {
      return {
        year: new Date().getFullYear(),
        class_: '',
      }
    },
    computed: {
    /*   user () {
        console.log('is_admin =', store.getters.is_admin)
        return store.getters.is_admin
      } */
    },
    mounted() {
        //console.log('tag', store.getters.is_admin)
      },
      
    created: function() {
      //console.log('tag', this.$store.getters.is_admin)
    }

}
</script>

<style>

</style>