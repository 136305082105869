<template>
  <div id="admin-update-teacher-details">
    <section>
      <div class="row">
        <div
          class="col-md-12 col-xs-12"
          style="margin-top: 15px; overflow-y: hidden; padding: 30px"
        >
          <div class="card-body table-responsive">
            <table
              v-for="item in teacher_details"
              :key="item.id"
              width="100%"
              class="table table-hover"
              border="1"
            >
              <tbody>
                <tr>
                  <td colspan="3">
                    <div>
                      <p align="left">
                        <!-- <strong>Photo</strong><br /><br /> -->
                        <img
                          v-if="item.teacher_photo"
                          :src="item.teacher_photo"
                          width="150PX"
                          style="padding: 25px; border: solid 2px #123985"
                        />

                        <span
                          v-else
                          style="
                            width: 250px;
                            padding: 25px;
                            border: solid 2px #123985;
                          "
                        >
                          <h5 style="color: #000" align="center">
                            <b>No Photo</b>
                          </h5>
                        </span>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><strong>Teacher ID / Username</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.teacher_id }} / {{ user_name.username }}</td>
                </tr>
                <tr>
                  <td><strong>Title</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.title }}</td>
                </tr>
                <tr>
                  <td width="38%"><strong>Teacher Last name</strong></td>
                  <td width="3%">&nbsp;</td>
                  <td width="59%">{{ user_name.last_name }}</td>
                </tr>
                <tr>
                  <td><strong>Teacher First name</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ user_name.first_name }}</td>
                </tr>
                <tr>
                  <td><strong>Phone Number</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.phone_number }}</td>
                </tr>
                <tr>
                  <td><strong>Whatsapp</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.whatsapp_number }}</td>
                </tr>
                <tr>
                  <td><strong>Address</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.street_address }}</td>
                </tr>
                <tr>
                  <td><strong>LGA</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.lga }}</td>
                </tr>
                <tr>
                  <td><strong>City/State</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.city_state }}</td>
                </tr>
                <tr>
                  <td><strong>Country</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.country }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import http from "../../../common/http-common";

export default {
  name: "admin-update-teacher-details",
  data() {
    return {
      go_back: "<<< Back",
      teacher_id: this.$route.query.teacher_id,
      headers: "",
      teacher_details: "",
      user_name: "",
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getTeacherDetails();
  },
  methods: {
    async getTeacherDetails() {
      await http
        .get(
          "teachers-reg/get-teacher-details/" +
            this.school_id +
            "/" +
            this.teacher_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          console.log("getTeacherDetails ", res.data.data);
          this.teacher_details = res.data.data;
          this.user_name = res.data.users;
        });
    },
    goBack() {
      this.$router.push("/admin/view-teacher-account");
    },
  },
};
</script>

<style></style>
