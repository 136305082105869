<template>
  <div>
    <form ref="form" @submit.prevent="handleSubmit">
      <b-form-group
        label-for="assessment_content-input"
        invalid-feedback="assessment_content is required"
        :state="assessment_contentState"
      >
        <b-form-input
          id="assessment_content-input"
          v-model="assessment_content"
          :state="assessment_contentState"
          required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" @click="onClickAssessmentContRightButton" variant="primary">Update</b-button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../common/config';
/* eslint-disable no-unused-vars */
export default {
  name: 'Update-assessment-content-right-template',
  data() {
      return {
          assessment_content: '',
          assessment_contentState: null,
      }
    },
    props: {
      data: {}
    },
    created() {
      this.getUpdatedTemplate()
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.assessment_contentState = valid
        return valid
      },

      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.updateTemplate(this.assessment_content)
      },
      getUpdatedTemplate(){
        // api/result-template/update-assessment-content/template_id/id
        axios.get(API_URL + "api/result-template/get-update-assessment-content-right/" + this.data[3] + '/' + this.data[0] + '/'+ this.data[1] + '/')
          .then((res)=> {
            this.assessment_content = res.data
          })
      },
      updateTemplate(assessment_content){
        const postData = {
            id: this.data[0],
            contents: assessment_content,
            heading_id: this.data[1],
            template_id: this.data[3],
          }
          axios.post(API_URL + "api/result-template/update-assessment-content-right/", postData)
          .then((res)=> {
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing-assessment-contents-right')
            })
          })
      },
      onClickAssessmentContRightButton(event){
        const id = this.data[0]
        const assessment_content = this.assessment_content
        this.$emit('clicked', { assessment_content, id})
      }
  },
}
</script>

<style>
b-form-input{
  margin-top: 5px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
b-form-group{
  padding: 20px !important;
}
</style>