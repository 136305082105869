<template>
  <div id="admin-dashboard">
      <h1>Admin Dashboard</h1>
  <div class="row">
  </div>
  <div class="row">
      <br> <hr> <br>
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Create New Result Template</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/admin-create-results"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-success">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View / Edit Template Results</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/admin-view-all-results"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Create New Account</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/admin-create-account"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-success">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View / Edit Accounts</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/admin-view-all-accounts"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Create, View and Edit School Result Reports</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/view-school-result-reports"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-warning">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Change Password</b></h4>
          </div>
        </div>              
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/admin/admin-change-password"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Logout</b></h4>
          </div>
        </div>  
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/logout"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </div>
</template>

<script>
export default {
    name: "AdminDashboard"
}
</script>

<style>

</style>