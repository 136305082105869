<template>
  <div id="frontend">
    <body id="top" class="has-header-search">
     <Navbar />
    <router-view :key="$route.path"></router-view>
    <Footer />
  </body>
  </div>
  
</template>

<script>
import Navbar from '../components/Partials/nav'
import Footer from '../components/Partials/footer'

export default {
    name: 'Frontend',
  components: {
    Navbar, Footer
  }
}
</script>

<style>
.page-section{
  padding: 30px;
}
.home-container{
  margin: 12px 25px !important;
  padding: 5px;
}
.content_text p {
  margin: 0;
}
</style>