import axios from "axios";
import { API_URL } from '../../common/config'
import router from '../../router/router'



export default {
    state: {
        user: null,
        token: null,
        is_admin: null,
        login_type: null
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setAdmin: (state, payload) => {
            state.is_admin = payload
        },
        setLoginType: (state, payload) => {
            state.login_type = payload
        }
    },
    actions: {
        signUserUp({ commit }, payload) {
            var postData = {
                username: payload.username,
                email: payload.email,
                password: payload.password
            };
            // console.log('== postData', postData)
            commit('setLoading', true)
            axios
                .post(API_URL + 'register/', postData)
                .then(
                    user => {
                        commit('setLoading', false)
                        const newUser = {
                                token: user.data.token,
                                id: user.data.data.id,
                                username: user.data.data.username,
                                email: user.data.data.email
                            }
                            // console.log('== user =', newUser.token)
                        commit('setUser', newUser)
                        commit('setToken', newUser.token)
                        alert('Signup was Successful!');
                    }
                )
                .catch(err => {
                    if (err) {
                        alert('Error! Username Already Exists!');
                    }
                })
        },
        signUserUpReferral({ commit }, payload) {
            var postData = {
                username: payload.username,
                email: payload.email,
                password: payload.password,
                referral_code: payload.referralCode
            };
            // console.log('== postData', postData)
            commit('setLoading', true)
            axios
                .post(API_URL + 'register-referral/', postData)
                .then(
                    user => {
                        // console.log('== user =', user)
                        commit('setLoading', false)
                        const newUser = {
                                token: user.data.token,
                                id: user.data.data.id,
                                username: user.data.data.username,
                                email: user.data.data.email
                            }
                            // console.log('== user =', newUser.token)
                        commit('setUser', newUser)
                        commit('setToken', newUser.token)
                        alert('Signup was Successful!');
                    }
                )
                .catch(err => {
                    if (err) {
                        alert('You have Entered Invalid Referral Link or Existing Username!');
                    }
                })
        },
        async signUserIn({ commit }, payload) {
            commit('setLoading', true)
            var postData = {
                username: payload.username,
                password: payload.password,
                login_type: payload.login_type
            };
            // console.log('postData ', postData)
            await axios
                .post(API_URL + 'api/authenticate/', postData)
                .then((user) => {
                    if (user.data.message == false) {
                        commit('setLoading', false)
                            // console.log('user', user.data)

                        const newUser = {
                            token: user.data.token,
                            id: user.data.user.id,
                            username: user.data.user.username,
                            email: user.data.user.email,
                            is_admin: user.data.user.is_staff,
                            login_type: user.data.login_type,
                            student_id: user.data.student_id,
                            expire_status: user.data.expire_status
                        }
                        commit('setToken', newUser.token)
                        commit('setUser', newUser)
                        commit('setAdmin', newUser.is_admin)
                        commit('setLoginType', newUser.login_type)
                        localStorage.setItem('userData', JSON.stringify(newUser))
                        localStorage.setItem('tokenData', JSON.stringify(newUser.token))
                        localStorage.setItem('isAdminData', JSON.stringify(newUser.is_admin))
                        localStorage.setItem('username', JSON.stringify(newUser.username))
                        localStorage.setItem('loginType', JSON.stringify(newUser.login_type))

                    } else {
                        alert(user.data.message)
                        commit('setUser', null)
                        commit('setAdmin', null)
                    }

                })
                /* .catch(err => {
                    if (err) {
                        alert('Error! Bad Network connection or Something went wrong, Unable to Login!')
                    }
                }) */
        },
        signAdminIn({ commit }, payload) {
            commit('setLoading', true)
            var postData = {
                username: payload.username,
                password: payload.password
            };
            axios
                .post(API_URL + 'authenticate/', postData)
                .then(
                    user => {
                        commit('setLoading', false)
                        const newUser = {
                                token: user.data.token,
                                id: user.data.user.id,
                                username: user.data.user.username,
                                email: user.data.user.email,
                                is_admin: user.data.user.is_staff
                            }
                            // console.log('== user =', user.data)
                        if (user.data.user.is_staff) {
                            commit('setToken', newUser.token)
                            commit('setAdmin', newUser.is_admin)
                                // console.log('== token =', user.data.user.is_staff)
                            localStorage.setItem('userData', JSON.stringify(newUser))
                            localStorage.setItem('tokenData', JSON.stringify(newUser.token))
                            localStorage.setItem('isAdminData', JSON.stringify(newUser.is_admin))
                        } else {
                            commit('setUser', null)
                            commit('setAdmin', null)
                        }
                    }
                )
        },
        autoSignIn({ commit }, payload) {
            payload = JSON.parse(localStorage.getItem('userData'))
                // console.log('autoSignIn payload', payload)
            commit('setUser', {
                token: payload.token,
                id: payload.id,
                username: payload.username,
                email: payload.email,
                is_admin: payload.is_staff
            })
            if (payload.is_staff == false) {
                // console.log('autoSignIn to User')
                router.push('user')
            } else if (payload.is_staff == true) {
                // console.log('autoSignIn to Admin')
                router.push('admin')
            }
        },
        logout({ commit }) {
            localStorage.clear();
            commit('setUser', null)
                //location.href = 'https://vestrade.wiseclub2k.com'
            window.location.reload('/')
        }
    },
    getters: {
        user(state) {
            return state.user
        },
        is_admin(state) {
            return state.is_admin
        },
        login_type(state) {
            return state.login_type
        }
    }
}