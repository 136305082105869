<template>
  <div id="admin-referrals-program">
    <div class="row">
      <h1>Parent View Result(s)</h1>
  </div>
  <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="card card-chart">
          <div class="card-header card-header-success" style="margin-top:15px !important;">
            <h3 >Request for Result</h3>
          </div>
            <div class="card-body">
                <form @submit.prevent="getStudentResult" style="magin-top:30px !important ;">
                <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Student ID</label>
                          <input id="student_id" name="student_id" v-model="student_id" required="required" type="float" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Class</label>
                          <input id="class_" name="class_" v-model="class_" required="required" type="float" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Year</label>
                          <input id="year_" name="year_" v-model="year_" required="required" type="float" class="form-control">
                        </div>
                      </div>
                  </div>
                   <button type="submit" class="btn btn-success" align="center">Submit Request</button>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
      </div><!-- End Column    -->
      </div>
      <div class="row">
     <div class="col-xs-12">       
        <div class="card">
          <div class="card-header card-header-primary" style="margin-top:15px !important;">
            <h3>View Result</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th >Student ID</th>
                <th >Class</th>
                <th >Year</th>
                 <th>View Result</th>
              </thead>
              <tbody>
                <tr v-for="item in resultDetails" v-bind:key="item.id">
                  <td>{{ item.student_id }}</td>
                  <td>$ {{ item.calss }}</td>
                  <td>{{ item.year }}</td>
                  <td><a :href="item.view_result">View & Download</a></td>
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'parentViewResult',
    components: {
      // BackendScripts
    },
     data () {
      return {
        student_id: '',
        class_: '',
        year_: '',
        resultDetails: []
      }
      },
    created() {
      
    },
    methods: {
      getStudentResult(){
        console.log('== student_id', this.student_id, '== class', this.class_, '== year', this.year_)
      }
    }
}
</script>

<style>

</style>