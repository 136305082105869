/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';

import Login from './../components/auth/login.vue';

import contactUs from './../other-pages/ContactUs.vue';
import AboutUs from './../other-pages/AboutUs.vue';

import Frontend from './../layouts/frontend';
import adminBackend from './../layouts/adminBackend';

import StudentBackend from './../layouts/studentBackend';
import studentDashboard from './../users/dashboard';
import StudentViewResults from './../users/view-results';
import VeiwResultDetails from './../users/veiw-result-details'
import StudentChangePassword from './../users/change-password';

import TeacherBackend from './../layouts/teacherBackend';
import TeacherDashboard from './../teacher/dashboard';
import TeacherUpdateResults from './../teacher/teacher-update-results';
import TeacherViewEditResults from './../teacher/view-edit-results';
import TeacherChangePassword from './../teacher/change-password';

import ParentBackend from './../layouts/parentBackend';
import ParentDashboard from './../parent/dashboard';
import ParentViewResults from './../parent/view-results';
import ParentChangePassword from './../parent/change-password';


import AdminDashboard from './../administrator/dashboard';
import AdminChangePassword from './../administrator/change-password';
import AdminCreateAccounts from './../administrator/create-accounts-dashboard';
import AdminVeiwAllAccounts from './../administrator/veiw-all-accounts';
import AdminCreateResults from './../administrator/create-results-dashboard';
import AdminViewAlResults from './../administrator/view-all-results';

import createTeacherAcct from './../administrator/create-accounts/create-teacher-account';
import createStudentAcct from './../administrator/create-accounts/create-student-account';
import createParentAcct from './../administrator/create-accounts/create-parent-account';

import createTemplate from './../administrator/create-results/create-new-result-template';
import EditTemplate from './../administrator/create-results/edit-exisiting-result-template';
import CloneTemplate from './../administrator/create-results/clone-exisiting-result-template';
import ViewTemplate from './../administrator/create-results/veiw-result-template';

import AdminViewStudentAccount from './../administrator/view-accounts/student-account';
import AdminViewParentAccount from './../administrator/view-accounts/parent-account';
import AdminViewTeacherAccount from './../administrator/view-accounts/teacher-account';

import AdminViewStudentDetails from './../administrator/view-accounts/details/student-details';
import AdminViewParentDetails from './../administrator/view-accounts/details/parent-details';
import AdminViewTeacherDetails from './../administrator/view-accounts/details/teacher-details';

import PreviewSchoolResults from './../administrator/school-results-reports/view-results';
import AddStudentToResult from "./../administrator/school-results-reports/add-student-to-result"
import ViewStudentToResult from './../administrator/school-results-reports/view-student-to-result';
import AssignTeacherToResult from './../administrator/school-results-reports/assign-teacher-to-result'

import ViewStudentResult from './../administrator/school-results-reports/veiw-result-details';
import EditStudentResult from './../administrator/school-results-reports/edit-result-details';

import AdminUpdateStudentDetails from './../administrator/view-accounts/update-accounts/student-update';
import AdminUpdateParentDetails from './../administrator/view-accounts/update-accounts/parent-update';
import AdminUpdateTeacherDetails from './../administrator/view-accounts/update-accounts/teacher-update';

import { AdminGuard, TeacherGuard, StudentGuard, ParentGuard } from './auth-guard'
//{ AdminGuard, TeacherGuard, StudentGuard, ParentGuard }
Vue.use(Router)

export default new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [

        {
            path: "",
            component: Frontend,
            children: [{
                    path: '',
                    name: 'home',
                    component: Login,
                    alias: ''
                },
                {
                    path: 'about-us',
                    name: 'about_us',
                    component: AboutUs,
                    alias: '/about-us'
                },
                {
                    path: 'contact-us',
                    name: 'contact_us',
                    component: contactUs,
                    alias: '/contact-us'
                }
            ]
        },
        // import Backend from './../layouts/backend';
        {
            path: '/admin',
            component: adminBackend,
            beforeEnter: AdminGuard,
            children: [{
                    path: '',
                    name: 'admin_dashboard',
                    component: AdminDashboard,
                    alias: '/admin/admin_dashboard'
                },
                {
                    path: 'admin-create-account',
                    name: 'admin_craete_account',
                    component: AdminCreateAccounts,
                    alias: '/admin/admin-create-account'
                },
                {
                    path: 'admin-view-all-accounts',
                    name: 'admin_view_accounts',
                    component: AdminVeiwAllAccounts,
                    alias: '/admin/admin-view-all-accounts'
                },
                {
                    path: 'admin-create-results',
                    name: 'admin_craete_results',
                    component: AdminCreateResults,
                    alias: '/admin/admin-create-results'
                },
                {
                    path: 'admin-view-all-results',
                    name: 'admin_view_results',
                    component: AdminViewAlResults,
                    alias: '/admin-view-all-results'
                },
                {
                    path: 'admin-change-password',
                    name: 'admin_change_password',
                    component: AdminChangePassword,
                    alias: '/admin/admin-change-password'
                },
                // Create all Accounts
                {
                    path: 'create-teacher-account',
                    name: 'create_teacher_account',
                    component: createTeacherAcct,
                    alias: '/admin/create-teacher-account'
                },
                {
                    path: 'create-student-account',
                    name: 'create_student_account',
                    component: createStudentAcct,
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'view-school-result-reports',
                    name: 'view_school_result_reports',
                    component: PreviewSchoolResults,
                    alias: '/admin/view-school-result-reports'
                },
                {
                    path: 'add-student-to-result',
                    name: 'admin_add_student_to_result',
                    component: AddStudentToResult,
                    //alias: '/admin/add-student-to-result',
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'view-student-to-result',
                    name: 'admin_view_student_to_result',
                    component: ViewStudentToResult,
                    //alias: '/admin/view-student-to-result',
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'assign-teacher-to-result',
                    name: 'assign_teacher_to_result',
                    component: AssignTeacherToResult,
                    //alias: '/admin/assign-teacher-to-result',
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'veiw-result-details',
                    name: 'admin_veiw_result_details',
                    component: ViewStudentResult,
                    //alias: '/admin/veiw-result-details',
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'edit-result-details',
                    name: 'admin_edit_result_details',
                    component: EditStudentResult,
                    //alias: '/admin/edit-result-details',
                    props(route) {
                        return route.query || {}
                    }
                },
                {
                    path: 'create-parent-account',
                    name: 'create_parent_account',
                    component: createParentAcct,
                    alias: '/admin/create-parent-account'
                },
                // Create all Result Templates
                {
                    path: 'create-new-result-template',
                    name: 'create-new-result-templatet',
                    component: createTemplate,
                    props(route) {
                        return route.query || {}
                    }
                    //alias: '/admin/create-new-result-template'
                },
                {
                    path: 'edit-exisiting-result-template',
                    name: 'edit-exisiting-result-template',
                    component: EditTemplate,
                    alias: '/admin/edit-exisiting-result-template'
                },
                { //clone-result-template
                    path: 'clone-exisiting-result-template',
                    name: 'clone-exisiting-result-template',
                    component: CloneTemplate,
                    props(route) {
                        return route.query || {}
                    },
                },
                {
                    path: 'veiw-result-template',
                    name: 'veiw-result-template',
                    component: ViewTemplate,
                    props(route) {
                        return route.query || {}
                    },
                    //alias: '/admin/veiw-result-template/:template_id'
                },
                //  Admin View Accounts
                {
                    path: 'view-student-account',
                    name: 'view_student_account',
                    component: AdminViewStudentAccount,
                    alias: '/admin/view-student-account'
                },
                {
                    path: 'view-parent-account',
                    name: 'view_parent_account',
                    component: AdminViewParentAccount,
                    alias: '/admin/view-parent-account'
                },
                {
                    path: 'view-teacher-account',
                    name: 'view_teacher_account',
                    component: AdminViewTeacherAccount,
                    alias: '/admin/view-teacher-account'
                },
                // Admin View Accounts Details
                {
                    path: 'view-student-account-details',
                    name: 'view_student_account_details',
                    component: AdminViewStudentDetails,
                    props(route) {
                        return route.query || {}
                    },
                },
                {
                    path: 'view-teacher-account-details',
                    name: 'view_teacher_account_details',
                    component: AdminViewTeacherDetails,
                    props(route) {
                        return route.query || {}
                    },
                },
                {
                    path: 'view-parent-account-details',
                    name: 'view_parent_account_details',
                    component: AdminViewParentDetails,
                    props(route) {
                        return route.query || {}
                    },
                },

                // Admin Update Accounts Details
                {
                    path: 'admin-update-student-details',
                    name: 'admin_update_student_details',
                    component: AdminUpdateStudentDetails,
                    props(route) {
                        return route.query || {}
                    },
                },
                {
                    path: 'admin-update-teacher-details',
                    name: 'admin_update_teacher_details',
                    component: AdminUpdateTeacherDetails,
                    props(route) {
                        return route.query || {}
                    },
                },
                {
                    path: 'admin-update-parent-details',
                    name: 'admin_update_parent_details',
                    component: AdminUpdateParentDetails,
                    props(route) {
                        return route.query || {}
                    },
                },
            ]
        },
        {
            path: '/student',
            component: StudentBackend,
            name: 'student-backend',
            beforeEnter: StudentGuard,
            children: [{
                    path: '',
                    name: 'student_dashboard',
                    component: studentDashboard,

                },
                {
                    path: 'student-view-result',
                    name: 'student_view_result',
                    component: StudentViewResults,
                    alias: '/student-view-result'
                },
                {
                    path: 'student-change-password',
                    name: 'student_change_password',
                    component: StudentChangePassword,
                    alias: '/student-change-password'
                },
                {
                    path: 'view-student-results-details',
                    name: 'view_student_results_details',
                    component: VeiwResultDetails,
                    props(route) {
                        return route.query || {}
                    }
                },
            ]
        },
        {
            path: '/teacher',
            component: TeacherBackend,
            name: 'teacher-backend',
            beforeEnter: TeacherGuard,
            children: [{
                    path: '',
                    name: 'teacher_dashboard',
                    component: TeacherDashboard,

                },
                {
                    path: 'teacher-view-and-edit-result',
                    name: 'teacher_view_and_edit_result',
                    component: TeacherViewEditResults,
                    alias: '/teacher-view-and-edit-result'
                },
                {
                    path: 'teacher-change-password',
                    name: 'teacher_change_password',
                    component: TeacherChangePassword,
                    alias: '/teacher-change-password'
                },
                {
                    path: 'teacher-update-results',
                    name: 'teacher_update_results',
                    component: TeacherUpdateResults,
                    //alias: '/teacher-update-result',
                    props(route) {
                        return route.query || {}
                    },
                },
            ]
        },
        {
            path: '/parent',
            component: ParentBackend,
            name: 'parent-backend',
            beforeEnter: ParentGuard,
            children: [{
                    path: '',
                    name: 'parent_dashboard',
                    component: ParentDashboard,

                },
                {
                    path: 'parent-view-results',
                    name: 'parent_view_results',
                    component: ParentViewResults,
                    alias: '/parent-change-password'
                },
                {
                    path: 'parent-change-password',
                    name: 'parent_change_password',
                    component: ParentChangePassword,
                    alias: '/parent-change-password'
                },
            ]
        }
    ]
})