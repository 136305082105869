<template>
  <div class="dropdown">
    <input
      v-if="Object.keys(selectedItem).length === 0"
      ref="dropdowninput"
      :id="'dropdown_input' + data_ids"
      v-model="inputValue"
      class="dropdown-input"
      type="text"
      placeholder="Search e.g Mr Tunde..."
    />
    <div v-else @click="resetSelection" class="dropdown-selected">
      ({{ selectedItem.username }}) {{ selectedItem.title }}
      {{ selectedItem.last_name }}
      {{ selectedItem.first_name }}
    </div>
    <div v-show="inputValue && apiLoaded" class="dropdown-list">
      <div
        @click="selectItem(item)"
        v-show="itemVisible(item)"
        v-for="item in itemList"
        :key="item.teacher_id"
        class="dropdown-item"
      >
        ({{ item.username }}) {{ item.title }} {{ item.last_name }}
        {{ item.first_name }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../common/config";
export default {
  data() {
    return {
      selectedItem: {},
      inputValue: "",
      itemList: [],
      apiLoaded: false,
      //   apiUrl: "https://restcountries.eu/rest/v2/all?fields=name;flag",
      school_id: "",
      headers: "",
      data_ids: "",
    };
  },
  props: {
    data_id: {},
  },
  created() {
    // this.getList();
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.data_ids = this.data_id;

    this.getTeacherList();
  },
  methods: {
    resetSelection() {
      this.selectedItem = {};
      this.$nextTick(() => this.$refs.dropdowninput.focus());
      this.$emit("on-item-reset");
    },
    selectItem(theItem) {
      //   console.log("selectItem ", theItem);
      this.selectedItem = theItem;
      this.inputValue = "";
      this.$emit("on-item-selected", theItem);
    },
    itemVisible(item) {
      //   console.log("itemVisible ", item);
      let currentName =
        item.title.toLowerCase() + " " + item.last_name.toLowerCase();
      let currentInput = this.inputValue.toLowerCase();
      return currentName.includes(currentInput);
    },
    /* getList() {
      axios.get(this.apiUrl).then((response) => {
        this.itemList = response.data;
        console.log("getList ==", this.itemList);
        this.apiLoaded = true;
      });
    }, */
    getTeacherList() {
      axios
        .get(
          API_URL +
            "api/teachers-reg/get-teachers-list/" +
            this.school_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          //   console.log("teacher_list ", res.data.data);
          this.itemList = res.data.data;
          this.apiLoaded = true;
        });
    },
  },
};
</script>

<style>
.dropdown {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  z-index: 99;
}
.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}
.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}
.dropdown-list {
  position: absolute;
  width: 300px !important;
  max-height: 500px;
  margin-top: 4px;
  overflow-x: 350px;
  overflow-x: 250px;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  z-index: 999;
}
.dropdown-item {
  display: flex;
  width: 300px !important;
  padding: 11px 16px;
  cursor: pointer;
  background: #ffffff;
}
.dropdown-item:hover {
  background: #edf2f7;
}
.dropdown-item-flag {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>
