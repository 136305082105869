<template>
  <div>
    <form ref="form" @submit.prevent="handleSubmit">
      <b-form-group
        label-for="subject_heading-input"
        invalid-feedback="subject_heading is required"
        :state="subjectHeadingState"
      >
        <b-form-input
          id="subject_heading-input"
          v-model="subject_heading"
          :state="subjectHeadingState"
          required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" @click="onClickKeyTraitButton" variant="primary">Update</b-button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../common/config';
/* eslint-disable no-unused-vars */
export default {
  name: 'update-subject-headings-template',
 data() {
    return {
        subject_heading: '',
        subjectHeadingState: null,
    }
  },
  props: {
    data: {}
  },
  created() {
    this.subject_heading = this.data[1]
  },
  methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.subjectHeadingState = valid
        return valid
      },

      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.updateTemplate(this.subject_heading)
      },
      updateTemplate(subject_heading){
        const postData = {
            key: this.data[0],
            subject_heading: subject_heading,
            template_id: this.data[2],
          }
          axios.post(API_URL + "api/result-template/update-subject-heading/", postData)
          .then((res)=> {
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing-subject-headings')
            })
          })
      },
      onClickKeyTraitButton(event){
        const key = this.data[0]
        const subject_heading = this.subject_heading
        this.$emit('clicked', { subject_heading, key})
      }
  },
}
</script>

<style>
b-form-input{
  margin-top: 5px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
b-form-group{
  padding: 20px !important;
}
</style>