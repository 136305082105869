<template>
  <div>
    <form ref="form7" id="form-assees-two-modal" @submit.prevent="handleSubmit">
      <b-form-group
        label-for="grades-details-input"
        invalid-feedback="grades_details is required"
        :state="grades_detailsState"
      >
        <table>
          <tr width="100%"> 
            <td colspan="5"><p>Select Assessment Rating</p><hr> 
            <p>{{title}}</p><br>
            </td>
          </tr>
          <tr width="100%">
          <td width="20%"> 
              <div>
                <input style="width:30px !important;" id="checkbox1" :value="1" type="radio" name="ranting" v-model="content" ><label for="checkbox1">1</label>
              </div>
              
            </td>
            <td width="20%">
              <div>
                <input style="width:30px !important;" id="checkbox2" :value="2" type="radio" name="ranting" v-model="content" ><label for="checkbox2">2</label>
              </div>
            </td>
            <td width="20%">
              <div>
                <input style="width:30px !important;" id="checkbox3" :value="3"  type="radio" name="ranting" v-model="content" ><label  for="checkbox3">3</label>
              </div>
            </td>
            <td width="20%">
              <div>
                <input style="width:30px !important;" id="checkbox4" :value="4" type="radio" name="'ranting" v-model="content" ><label  for="checkbox4">4</label>
              </div>
            </td>
            <td width="20%">
              <div>
                <input style="width:30px !important;" id="checkbox5" :value="5" type="radio" name="ranting" v-model="content" ><label  for="checkbox5">5</label>
              </div>
            </td>
          </tr>
        </table>
      </b-form-group>
      <b-button type="submit" @click="onClickAssessmentButton" variant="primary"
        >Update</b-button
      >
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../common/config";
/* eslint-disable no-unused-vars */
export default {
  name: "update-result-asseset-two-modal",
  data() {
    return {
      grades_details: [],
      grades_detailsState: null,
      grades_details_data: "",
      id: "",
      heading_id: "",
      ranting: "",
      result_id: '',
      title: '',
      content: '',
      checkbox: '',
    };
  },
  props: {
    data: {},
  },
  created() {
    const [id, heading_id, ranting, title, result_id] = this.data
    this.id = id
    this.heading_id = heading_id
    this.ranting = ranting
    this.title = title
    this.result_id =result_id
    this.checkbox = 'checkbox'+ this.ranting
    this.raidoChange()
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form7.checkValidity();
      this.grades_detailsState = valid;
      return valid;
    },
    raidoChange(){

      setTimeout(() => {
        if(document.getElementById(this.checkbox) != null){
          document.getElementById(this.checkbox).checked = true
        }
      }, 1500);
      
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      let formDatas = { id: this.id, heading_id: this.heading_id, ranting: this.content, result_id: this.result_id }

      this.updateTemplate(formDatas);
      
    },

    updateTemplate(postData) {
      // console.log('== postData ',postData)
      axios
        .post(API_URL + "api/school-result/update-assessment-two/", postData)
        .then((res) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-prevent-closing-update-assess-two");
          });
        });
        
    },
    onClickAssessmentButton(event) {
      const ranting = this.content
      const id = this.id
      console.log('id', this.id)
      this.$emit("clicked", {ranting, id});
    },
  },
};
</script>

<style>
b-form-input {
  margin-top: 5px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
b-form-group {
  padding: 20px !important;
}
p {
  font-size: 15px;
}
</style>
