<template>
  <div>
    <div class="row">
      <h2>Admin View/Edit Students Results</h2>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <p align="right" v-if="isGoBack">
            <button
              class="btn btn-success"
              style="
                width: 150px;
                margin-top: 15px !important;
                margin-right: 20px;
              "
              @click="goBack()"
            >
              {{ go_back }}
            </button>
          </p>
          <br />
          <div
            class="card-header card-header-primary"
            style="margin-top: 5px !important"
          >
            <h2>View/Edit Students Results</h2>
          </div>

          <div
            class="row"
            style="
              margin-top: 20px;
              padding: 20px;
              border: 1px;
              border-color: #cccccc;
            "
          >
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="form-group">
                <label for="select-format">Select Result Format</label>
                <select
                  class="form-control"
                  name="select-format"
                  id="select-format"
                  v-model="select_format"
                >
                  <option value="" disabled selected>Select format...</option>
                  <option value="format_a" >
                    Format A
                  </option>
                  <option value="format_b">
                    Format B
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12">
              <button
                style="margin-top:15px margin-left:30px;"
                @click.prevent="sendResultToParents"
                :disabled="isSendResultSelected || is_expired"
                class="btn btn-success"
              >
                Send Result to Parents
              </button>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- <button
              v-if="template_details.isApprove == 'Approved'"
              class="btn btn-primary"
              @click.prevent="approveResults"
              :disabled="isApprovingResults"
              >Completed / Approved
              </button> -->
              <button
              class="btn btn-primary"
              @click.prevent="approveResults"
              :disabled="isApprovingResults || is_expired"
              >Complete / Approve
              </button>
            </div>
            <p align="right" style="color:red;" v-if="approvingResultMsg">{{approvingResultMsg}}</p>
            
          </div>
          <p align="center" id="error_message" v-show="is_error_msg">
            <!-- Error Message here -->
          </p>
          <p
            v-if="isSendingMessage"
            style="margin-bottom:5px; margin-top:55px margin-left:30px; padding:15px;"
          >
            <br />
            <span
              style="color:red; font-weight:bold;margin-bottom:5px; margin-top:5px margin-left:30px;"
              >Sending Results as PDF to Parents, Please wait...</span
            >
          </p>

          <p align="center" v-if="is_expired" style="color:red;font-weight:bold">
            <a href="http://www.ijcschool.com.ng/subscription/" target="blank">
            To Enable your Buttons, Please Renew your Subscription, Click here...
            </a></p>

          <p style="font-size: 16px; color: blue" align="right">
            <a>
              {{ template_details.sch_session }} |
              {{ template_details.class_room }} |
              {{ template_details.sch_terms }} Results</a
            >
          </p>
          <div class="card-body table-responsive">
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Send Result to Parent's Emails</th>
                <th>Student Photo</th>
                <th>Student ID</th>
                <th>Full name</th>
                <th>View</th>
                <th>Edit</th>
                <th>Assign</th>
                <!-- <th>Approve</th> -->
                <th>Delete</th>
              </thead>
              <tbody>
                <tr>
                  <td colspan="11">
                    <p style="margin-left: 10px; font-size: 28px !importnat">
                      <input
                        id="select_all"
                        type="checkbox"
                        v-model="select_all"
                        @change="selectAll"
                      />
                      <label for="select_all" style="font-size: 28px !importnat"
                        ><b>Select All</b></label
                      >
                    </p>
                  </td>
                </tr>
                <tr v-for="item in student_list" v-bind:key="item.id">
                  <td>
                    <div
                      style="
                        background-color: #e9ecf1;
                        border-radius: 7px;
                        height: 50px !important;
                        width: 40px;
                      "
                    >
                      <p align="center" class="form-group">
                        <input
                          type="checkbox"
                          :id="item.student_id"
                          v-model="send_result_box"
                          :value="item.student_id"
                          style="background-color: #fff"
                          class="custom-control"
                        />
                        <label
                          :for="item.student_id"
                          style="margin: 11px"
                        ></label>
                      </p>
                    </div>
                  </td>

                  <td>
                    <img :src="item.student_photo" width="75px" />
                  </td>
                  <td>{{ item.student_user }}</td>
                  <td>
                    {{ item.last_name }} {{ item.first_name }}
                    {{ item.middel_name }}
                  </td>
                  <td>
                    <button
                      @click.prevent="
                        viewButton(item.student_id, item.result_id)
                      "
                      :disabled="isView"
                      class="btn btn-success"
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <button
                      v-if="item.isApprove == 'Pending'"
                      @click.prevent="
                        updateButton(item.student_id, item.result_id)
                      "
                      :disabled="isUpdate"
                      class="btn btn-warning"
                    >
                      Update
                    </button>
                    <button
                      v-else
                      @click.prevent="
                        updateButton(item.student_id, item.result_id)
                      "
                      :disabled="isUpdate"
                      class="btn btn-primary"
                    >
                      Update
                    </button>
                  </td>

                  <td>
                    <button
                      @click.prevent="
                        assignButton(item.student_id, item.result_id)
                      "
                      :disabled="isAssign"
                      class="btn btn-success"
                    >
                      Assign
                    </button>
                  </td>

                  <td>
                    <button
                      @click.prevent="
                        deleteButton(item.student_id, item.result_id)
                      "
                      :disabled="isDelete"
                      class="btn btn-danger"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
    </div>
    <div v-show="false">
      <PdfResultComponent
        :data="pdf_result_component"
        v-if="pdf_result_comp_status"
      ></PdfResultComponent>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import http from "../../common/http-common";
import { API_URL } from "@/common/config";
import PdfResultComponent from "./pdf-result-component.vue";

export default {
  name: "view-student-to-result",
  components: {
    PdfResultComponent,
  },
  data() {
    return {
      template_id: this.$route.query.template_id,
      go_back: "<<< Back",
      isGoBack: true,
      student_list: "",
      BASE_URL: API_URL.slice(0, -1),
      isUpdate: false,
      isView: false,
      isDelete: false,
      headers: "",
      send_result_box: [],
      select_all: "",
      isSendResultSelected: false,
      isSendingMessage: false,
      pdf_result_component: {},
      pdf_result_comp_status: false,
      timeInverval: "",
      select_format: "",
      template_details: "",
      isAssign: false,
      isApprove: false,
      isApprovingResults: false,
      approvingResultMsg: '',
      is_error_msg: false,
      expire_status: '',
      is_expired: true,
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var {token, expire_status} = JSON.parse(localStorage.getItem("userData"));
    this.headers = { Authorization: `Token ${token}` };

    if(expire_status == "Active"){
      this.is_expired = false
    }

    this.getStudentResultsList();
    this.getTemplateDetails();
  },
  methods: {
    async getTemplateDetails() {
      await http
        .get("school-result/get-template-details/" + this.template_id + "/", {
          headers: this.headers,
        })
        .then((res) => {
          res.data.map((data) => {
            //console.log('getTemplateDetails data', data)
            this.template_details = data;
          });
        });
    },
    getStudentResultsList() {
      http
        .get(
          "school-result/get-student-results-list/" +
            this.school_id +
            "/" +
            this.template_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          this.student_list = res.data.data;
        });
    },
    selectAll() {
      let items = this.student_list.length;
      let xy = 0;
      if (this.select_all) {
        for (xy; xy < items; xy++) {
          let student_ids = this.student_list[xy].student_id;
          document.getElementById(student_ids).checked = true;
          this.send_result_box.push(student_ids);
        }
      } else {
        for (xy; xy < items; xy++) {
          let student_ids = this.student_list[xy].student_id;
          document.getElementById(student_ids).checked = false;
          this.send_result_box = [];
        }
      }
    },
    sendResultToParents() {
      if(this.select_format){
        if (this.send_result_box.length > 0) {
          this.isSendResultSelected = true;
          this.isSendingMessage = true;

          //console.log("send_result_box ", this.send_result_box);

          let x = 0;
          let L = this.send_result_box.length;
          //console.log("b4 setInterval ", x);

          this.timeInverval = setInterval(() => {
            //console.log("setInterval 1", x);

            let student_id = this.send_result_box[x];

            //console.log("student_id ", x, student_id);

            this.pdf_result_component = {
              send_result_students: student_id,
              template_id: this.template_id,
            };
            this.pdf_result_comp_status = true;

            setTimeout(()=>{
              let pdfTable = document.getElementById("result-to-pdf");

            if (pdfTable) {
              let htmlData = {
                html_data: pdfTable.innerHTML,
                select_format: this.select_format,
              };
              //console.log("htmlData 2", htmlData);

              http
                .post(
                  "school-result/export-html-to-pdf/" +
                    this.template_id +
                    "/" +
                    student_id +
                    "/",
                  htmlData,
                  { headers: this.headers }
                )
                .then(() => {
                  this.pdf_result_comp_status = false;
                  //console.log("res htmlData ", res);

                  if (x < L) {
                    x = x + 1;
                  }

                  if (x == L) {
                    //console.log('clearInterval 1', x)
                    clearInterval(this.timeInverval);
                    this.pdf_result_comp_status = false;
                    this.isSendResultSelected = false;
                    this.isSendingMessage = false;
                    this.send_result_box = [];
                  }
                });
            }
            },5000)

            
          }, 20000);
        } else {
          alert("Please Select Results Checkbox before sending...");
        }
      }else{
        alert("Please Select Results Format...");
      }
    },

    updateButton(student_id, result_id) {
      this.$router.push(
        "/admin/edit-result-details?template_id=" +
          this.template_id +
          "&student_id=" +
          student_id +
          "&result_id=" +
          result_id
      );
    },
    viewButton(student_id, result_id) {
      this.$router.push(
        "/admin/veiw-result-details?template_id=" +
          this.template_id +
          "&student_id=" +
          student_id +
          "&result_id=" +
          result_id
      );
    },
    assignButton(student_id, result_id) {
      this.$router.push(
        "/admin/assign-teacher-to-result?template_id=" +
          this.template_id +
          "&student_id=" +
          student_id +
          "&result_id=" +
          result_id
      );
    },
    approveResults(){
      //this.isApprovingResults=true
      this.approvingResultMsg = "Completing & Approving Results, Please wait..."
      const postData = {
        sch_session : this.template_details.sch_session,
        class_room : this. template_details.class_room,
        sch_terms : this.template_details.sch_terms
      }
        http
        .post(
          "school-result/approve-results/" +
            this.school_id +
            "/" +
            this.template_id +
            "/", postData,
          { headers: this.headers }
        )
        .then((res) => {
          console.log('approveResults ', res.data)
          //this.student_list = res.data.data;
          console.log('res.data.result_id ', res.data.result_id)
          console.log('res.data.student_user ', res.data.student_user)
          console.log('res.data.student_id ', res.data.student_id)
          if(res.data.error){
            let error_msg = '<span style="color:red;,font-weight:bold;">' + res.data.message + '</span>' + '! <br> Click here to Update this result: ' + `<a href="/#/admin/edit-result-details?template_id=${this.template_id }&student_id=${res.data.student_id}&result_id=${res.data.result_id}"><b>Update Result(${res.data.student_user})</b></a>`
            console.log('error_msg ', error_msg)
            this.is_error_msg = true

            setTimeout(()=>{
              document.getElementById("error_message").innerHTML = error_msg
            },1500)
            
          }else{
            //alert(res.data.message)
          }
          //this.isApprovingResults=false
          this.approvingResultMsg = ''
        });
    },
    deleteButton(student_id, result_id) {},
    goBack() {
      this.$router.push("/admin/view-school-result-reports");
    },
  },
  beforeDestroy() {
    if (this.timeInverval) {
      clearInterval(this.timeInverval);
    }
  },
};
</script>

<style></style>
