<template>
  <div>
    <form ref="form" @submit.prevent="handleSubmit">
      <b-form-group
        label-for="subject_content-input"
        invalid-feedback="subject_content is required"
        :state="subject_contentState"
      >
        <b-form-input
          id="subject_content-input"
          v-model="subject_content"
          :state="subject_contentState"
          required
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" @click="onClickSubjectContButton" variant="primary">Update</b-button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../common/config';
/* eslint-disable no-unused-vars */
export default {
  name: 'Update-subject-contents-modal',
 data() {
    return {
        subject_content: '',
        subject_contentState: null,
    }
  },
  props: {
    data: {}
  },
  created() {
    this.getUpdatedTemplate()
  },
  methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.subject_contentState = valid
        return valid
      },

      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.updateTemplate(this.subject_content)
      },
      getUpdatedTemplate(){
        // api/result-template/update-subject-content/template_id/id
        axios.get(API_URL + "api/result-template/get-update-subject-content/" + this.data[2] + '/' + this.data[0] + '/')
          .then((res)=> {
            this.subject_content = res.data
          })
      },
      updateTemplate(subject_content){
        const postData = {
            id: this.data[0],
            subject_content: subject_content,
            template_id: this.data[2],
          }
          axios.post(API_URL + "api/result-template/update-subject-content/", postData)
          .then((res)=> {
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing-subject-contents')
            })
          })
      },
      onClickSubjectContButton(event){
        const id = this.data[0]
        const subject_content = this.subject_content
        this.$emit('clicked', { subject_content, id})
      }
  },
}
</script>

<style>
b-form-input{
  margin-top: 5px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
b-form-group{
  padding: 20px !important;
}
</style>