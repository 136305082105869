<template>
  <div id="admin-update-parent-details"></div>
</template>

<script>
export default {
  name: "admin-update-parent-details",
  data() {
    return {};
  },
};
</script>

<style></style>
