<template>
  <div id="app">
    <body id="top" class="has-header-search">
      <!-- <Backend /> -->
      <router-view></router-view>
      <!-- <Frontend /> -->
    </body>
  </div>
</template>

<script>
// import Home from './components/Home.vue'
// import UserBackend from './../layouts/userbackend';
// import Backend from './../layouts/backend';

/* eslint-disable */
export default {
  name: "App",
  components: {
    // Backend, Frontend
  },
};
</script>

<style>
#app {
  margin-top: 0px;
  color: #000 !important;
}

select,
option,
input {
  font-size: 15px !important;
}

select,
input,
table,
tr,
td,
th,
ul,
li {
  height: 40px !important;
  color: #383637 !important;
}

label {
  font-size: 16px !important;
}

.green {
  border-color: white !important;
  color: white !important;
  background-color: green !important;
}
.red {
  border-color: red !important;
  color: white !important;
  background-color: white !important;
}
a {
  color: #fff;
  font-weight: 500 !important;
}
a .btn {
  font-size: 30px !important;
}
</style>
