<template>
  <div id="admin-veiw-all-accounts">
    <div class="row">
      <h2>Create, View and Edit School Result Reports</h2>
  </div>
  <!-- <BackendScripts /> -->
  <div class="row">
     <div class="col-xs-12">       
        <div class="card">
          <div class="card-header card-header-primary" style="margin-top:15px !important; color:#fff;">
            <h3>View & Create Student Account</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>

          <!-- School Session List -->
          <div class="card-body table-responsive" v-if="isSessionList">
            <p align="right" v-if="isGoBack">
            <button class="btn btn-success" style="width:150px;" @click="goBack()">{{go_back}}</button>
            </p>
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th >Select Session</th>
                <th >Session / Year</th>
              </thead>
              <tbody>
                <tr v-for="item in session_list" v-bind:key="item.id">
                  <td><button class="btn btn-primary" @click="getSchoolSessionTermsList(item.id)">Select Session</button> </td>
                  <td>{{ item.sch_session }}</td>
                </tr>                
              </tbody>
            </table>
          </div>

          <!-- School Session Terms List -->
          <div class="card-body table-responsive" v-if="isSessionTermsList">
            <p align="right" v-if="isGoBack">
            <button class="btn btn-success" style="width:150px;" @click="goBack()">{{go_back}}</button>
            </p>
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                
                <th>Select Term</th>
                <th >Session / Year</th>
                <th >Terms</th>
              </thead>
              <tbody>
                <tr v-for="item in session_terms_list" v-bind:key="item.id">
                  <td><button class="btn btn-primary" @click="getSchoolSessionClassessList(item.id)">Select</button></td>
                  <td>{{ item.sch_session }}</td>
                  <td>{{ item.sch_terms }}</td>
                </tr>                
              </tbody>
            </table>
          </div>

          <!-- School Session Classes List -->
          <div class="card-body table-responsive" v-if="isClasses">
            <p align="right" v-if="isClasses">
            <button class="btn btn-success" style="width:150px;" @click="goBackToSchTerms()">{{go_back}}</button>
            </p>
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Class</th>
                <th>Add Student Account</th>
                <th>View Students</th>
                <th >Terms</th>
                <th >Session / Year</th>
              </thead>
              <tbody>
                <tr v-for="item in session_classess_list" v-bind:key="item.id">
                  <td>{{ item.class_room }}</td>
                  <td><button class="btn btn-primary" @click="addStudentToResult(item.template_id)" :disabled="is_expired" >Add Student</button>

                  <p v-if="is_expired" style="color:red;font-weight:bold"><a href="http://www.ijcschool.com.ng/subscription/" target="blank">To Enable your Buttons, Please Renew your Subscription, Click here...</a></p>

                  </td>
                  <td><button class="btn btn-success" @click="viewStudentsResult(item.template_id)" :disabled="is_expired">View / Edit Students</button>
                  
                  </td>
                  <td>{{ item.sch_terms }}</td>
                  <td>{{ item.sch_session }}</td>
                </tr>                
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <!-- End Column -->
  </div>
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'
//http://portal.derev-school.com:8082/#/admin/pre-create-student-account
// import axios from "axios";
import http from "../../common/http-common";
/* eslint-disable */
/* eslint-disable invalid-first-character-of-tag-name */
/* eslint-disable vue/no-parsing-error */
export default {
    name: 'PreCreateStudentAccount',
    components: {
      // BackendScripts
    },
    data() {
        return {
            go_back: '<<< Back',
            isGoBack: false,
            school_id: '',
            session_list: '',
            isSessionList: true,
            session_terms_list: '',
            isSessionTermsList: false,
            isClasses: false,
            session_classess_list: '',
            expire_status: '',
            is_expired: true,
        }
    },
    created() {
      const { school_id, } = JSON.parse(localStorage.getItem('domainDetails'));
      this.school_id = school_id;

      this.expire_status = JSON.parse(localStorage.getItem('userData')).expire_status;
      if(this.expire_status == "Active"){
        this.is_expired = false
      }

      this.getSchoolSessionList()
    },
    methods: {
       async getSchoolSessionList(){
          await http.get("students-reg/get-school-session-list/" + this.school_id + '/'
          //,{ headers: { "Content-Type": "multipart/form-data"  },}
          ).then((res)=>{
            this.session_list = res.data
          });       
        },
       async getSchoolSessionTermsList(id){
          await http.get("students-reg/get-school-session-terms/" + this.school_id + '/' + id + '/'
          //,{ headers: { "Content-Type": "multipart/form-data"  },}
          ).then((res)=>{
            this.isSessionList = false
            this.isSessionTermsList = true
            this.session_terms_list = res.data
            this.isGoBack = true
          }); 
        },
        goBack(){
          if(this.isGoBack == false){
            this.isGoBack = true
          }else{
            this.isGoBack = false
            this.isSessionList = true
            this.isSessionTermsList = false
          }
        },
        async getSchoolSessionClassessList(id){
          this.isSessionList = false
          this.isSessionTermsList = false
          await http.get("students-reg/get-school-session-classes/" + this.school_id + '/' + id + '/'
          //,{ headers: { "Content-Type": "multipart/form-data"  },}
          ).then((res)=>{
            this.session_classess_list = res.data
            this.isClasses = true
          });
          
        },
        goBackToSchTerms(){
          if(this.isClasses == false){
            this.isClasses = true
          }else{
            this.isClasses = false
            this.isSessionTermsList = true
          }
        },
        addStudentToResult(template_id){
          this.$router.push('/admin/add-student-to-result?template_id=' + template_id)
        },
        viewStudentsResult(template_id){
          this.$router.push('/admin/view-student-to-result?template_id=' + template_id)
        }
    },
}
</script>

<style>

</style>