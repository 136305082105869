<template>
  <div id="student-change-password">
    <div class="row">
      <h1>Change Password</h1>
  </div>
  <div class="row">

      <div class="col-lg-12 col-sm-12">
        <div class="card card-chart">
          <div class="card-header card-header-primary" style="margin-top:15px !important;">
            <h4 class="card-title">Change Password</h4>
          </div>
            <div class="card-body">
              <h3>Enter Old Password New Password</h3>
                <form @submit.prevent="changePasswoed" style="magin-top:30px !important ;">
                <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Enter Old Password </label>
                          <input id="old_password" name="old_password" v-model="old_password" required="required" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Enter New Password</label>
                          <input id="new_password" name="new_password" v-model="new_password" required="required" type="text" class="form-control">
                        </div>
                      </div>
                  </div>
                   <button type="submit" class="btn btn-success" align="center">Change Password</button>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
      </div><!-- End Column    -->
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'StudentChangePassword',
    components: {
      // BackendScripts
    },
    data() {
      return {
        old_password: '',
        new_password: ''
      }
    },
    methods: {
      changePasswoed() {
        console.log({old_password: this.old_password, new_password: this.new_password})
        this.$store.dispatch('loadChangePassword', {old_password: this.old_password, new_password: this.new_password})
        .then(() => {
            console.log('is Password change logout')
        })
        
      }
    },
}

/* {
    "status": "success",
    "code": 200,
    "message": "Password updated successfully",
    "data": []
} */
</script>

<style scoped>
button {
  font-size: 18px !important;
  padding: 10px 35px !important;
  width: 250px;
  justify-items: center;
}
label {
  font-size: 14px !important;
}
</style>
