<template>
  <div id="clone-exisiting-result-template">
    <div class="row">
      <h2>Admin Clone Exisiting Result Template</h2>
  </div>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <p style="font-size:20px; margin-top:20px; padding:20px;" align="center">Selected/Current Template Details:  Session: <span class="bold-text">{{session_term_details.sch_session}}</span> |  Class:<span class="bold-text">{{session_term_details.class_room}}</span> | Term: <span class="bold-text">{{session_term_details.sch_terms}}</span> </p>
            </div>
            <div class="col-md-12 col-xs-12" align="center">
              <h3 align="center">
                Select Template Result Session, Term & Class you want to Duplicate
              </h3>
              <hr>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <select v-model=" sch_session" id=" sch_session" class="form-control" style="margin-top:10px;">
                    <option value="" disabled selected>Filter by Session</option>
                    <option
                        v-for="item in session_data"
                        :key="item.id"
                        :value="item.school_session"
                        >{{ item.school_session }}</option
                      >
                  </select>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <select v-model=" class_room" id=" class_room" class="form-control"  style="margin-top:10px;">
                    <option value="" disabled selected>Filter by Class</option>
                    <option
                        v-for="item in classes_data"
                        :key="item.id"
                        :value="item.class_room"
                        >{{ item.class_room }}</option
                      >
                  </select>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12"  style="margin-top:10px;">
                  <!-- <label for=" sch_terms"></label> -->
                  <select v-model=" sch_terms" id=" sch_terms" class="form-control">
                    <option value="" disabled selected>Filter by Terms</option>
                    <option value="First Term">First Term</option>
                    <option value="Second Term">Second Term</option>
                    <option value="Third Term">Third Term</option>
                  </select>
                </div>
                <div class="col-md-12 col-xs-12" align="center" style="margin-top:50px;">
                  <button :disabled="isCreatingButton" class="btn btn-primary" @click.prevent="checkAndCreateCloneTemplate">Create Result Template</button>
                  <p v-if="isCreatingMessage" style="color:red;font-weight:bold" align="center">Create Result Template, Please wait...</p>
                </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from '../../common/config';
import http from "../../common/http-common";

export default {
    name: 'clone-exisiting-result-template',
    components: {
      // BackendScripts
    },
    data() {
      return {
        template_id : this.$route.query.template_id,
        classes_data: '',
        session_data: '',
         class_room: '',
         sch_terms: '',
         sch_session: '',
        school_id: '',
        session_term_details: '',
        isCreatingButton: false,
        isCreatingMessage: false
      }
    },
    created() {
      var token = JSON.parse(localStorage.getItem('userData')).token
      this.headers = {'Authorization': `Token ${token}`}

      const { school_id, } = JSON.parse(localStorage.getItem('domainDetails'));
      this.school_id = school_id;

      this.getClassAndSession()
      this.getTemplateDetails()
    },
    methods: {
    async getClassAndSession() {
      await http
        .get(
          "students-reg/get-class-and-session/" + this.school_id + "/"
          //,{ headers: { "Content-Type": "multipart/form-data"  },}
        )
        .then((res) => {
          this.classes_data = res.data.data;
          this.session_data = res.data.data2;
        });
    },
    getTemplateDetails(){
          axios
           .get(API_URL + 'api/result-template/get-new-result-template/' + this.template_id + '/', { headers: this.headers })
           .then((res)=>{
                this.session_term_details =  res.data.session_term[0]

           })
    },
    checkAndCreateCloneTemplate(){
        if(
          this.class_room &&
          this.sch_terms &&
          this.sch_session
        ){
          this.isCreatingButton = true
          this.isCreatingMessage = true
          const postData = {
              school_id: this.school_id,
              class_room: this.class_room,
              sch_terms: this.sch_terms,
              sch_session: this.sch_session,
              template_id: this.template_id
            }
            axios.post(API_URL + "api/result-template/check-clone-template-exisit/", postData, { headers: this.headers })
            .then((req)=> {
              //console.log('== req ', req)
            if(!req.data.result_check){
              this.autoGenerateTemplate()
            } else {
              alert("This Template Already Exisiting!")
              this.showTemplateDatabase(req.data.template_id)
            }
            })
        } else {
          alert("Please Select All the Template Settings Fields!")
        }
      },
      autoGenerateTemplate(){
        //console.log('==  this.class_room ==',  this.class_room)
        var postData = {
            school_id: this.school_id,
            class_room: this.class_room,
            sch_terms: this.sch_terms,
            sch_session: this.sch_session,
            template_id: this.template_id
        };
        axios
           .post(API_URL + 'api/result-template/auto-generate-clone-template/', postData, { headers: this.headers })
           .then(
              res => {              
                this.isCreatingButton = false
                this.isCreatingMessage = false
                this.showTemplateDatabase(res.data.template_id)
              })
      },
      showTemplateDatabase(template_id){
        this.$router.push('/admin/veiw-result-template?template_id=' + template_id)
      }
    
    },
}
</script>

<style scoped>
.bold-text{
  font-weight: bolder;
}
</style>