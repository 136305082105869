import { store } from '../store'
// import router from '../router/router'

const AdminGuard = (to, from, next) => {
    if (store.getters.login_type == 'Administrator') {
        next()
    } else {
        next('/')
    }
}

const TeacherGuard = (to, from, next) => {
    if (store.getters.login_type == 'Teacher') {
        next()
    } else {
        next('/')
            //router.push('/login')
    }
}

const StudentGuard = (to, from, next) => {
    if (store.getters.login_type == 'Student') {
        next()
    } else {
        next('/')
    }
}

const ParentGuard = (to, from, next) => {
    if (store.getters.login_type == 'Parent') {
        next()
    } else {
        next('/')
    }
}

export { AdminGuard, TeacherGuard, StudentGuard, ParentGuard };