<template>
  <!-- eslint-disable vue/no-parsing-error -->
  <div id="admin-veiw-all-accounts">
    <div class="row" style="margin-bottom:50px !important;">
      <h3>Loading Template....</h3><br>
  </div>
  <!-- Start School Logo & School Details Section -->
  <section id="logo_school_name">
    <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div align="center">
          <p align="center" >
            <FacebookLoader :color="'#54f1d2'" /><FacebookLoader />
          </p>
        </div>
      </div>

      </div>
    </div>
  </section>
  <!-- End Teacher Comment Section -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-parsing-error */
/* eslint-disable vue/no-unused-components */

import axios from "axios";
import { API_URL } from '../../common/config';
//import router from '../../router/router'
import { FacebookLoader  } from 'vue-spinners-css';

export default {
    name: 'AdminVeiwAllAccounts',
    components: {
      FacebookLoader
    },
    data() {
      return {
        year: new Date().getFullYear(),
        class_: '',
        class_room : this.$route.query.class_room,
        school_id: '',
        school_logo: '',
        school_name: '',
        sch_terms: '',
        sch_session: '',
        headers: '',
      }
    },
    created() {
      const { school_id, school_logo, school_name } = JSON.parse(localStorage.getItem('domainDetails'))
      this.school_id = school_id
      this.school_logo = school_logo
      this.school_name = school_name

      // retrieve the class Details when navigate to this page from create result vue
      const { sch_terms, sch_session } = JSON.parse(localStorage.getItem('classDetails'))
      this.sch_terms = sch_terms
      this.sch_session = sch_session

      var token = JSON.parse(localStorage.getItem('userData')).token
      this.headers = {'Authorization': `Token ${token}`}

      this.getTemplateData()
    },
    methods: {
      getTemplateData(){
        const postData = {
            school_id: this.school_id,
            class_room: this.class_room,
            sch_terms: this.sch_terms,
            sch_session: this.sch_session
          }
          axios.post(API_URL + "api/result-template/check-template-exisit/", postData, { headers: this.headers })
          .then((req)=> {
            //console.log('== req ', req)
          if(!req.data.result_check){
            this.autoGenerateTemplate()
          } else {
            this.showTemplateDatabase(req.data.template_id)
          }
          })
      },
      autoGenerateTemplate(){
        //console.log('==  this.class_room ==',  this.class_room)
        var postData = {
            school_id: this.school_id,
            class_room: this.class_room,
            sch_terms: this.sch_terms,
            sch_session: this.sch_session
        };
        axios
           .post(API_URL + 'api/result-template/auto-generate-result-template/', postData, { headers: this.headers })
           .then(
              res => {              
                //console.log('== res', res)
                this.showTemplateDatabase(res.data.template_id)
              })
      },
      showTemplateDatabase(template_id){
        this.$router.push('/admin/veiw-result-template?template_id=' + template_id)
      }
    },
}
</script>

<style scoped>
#admin-veiw-all-accounts {
  background-color: white !important;
  padding: 30px !important;

}
#logo_school_name {
  margin-bottom:30px !important;
  border: 0.5px solid #cfcfcf !important;
  padding: 20px;;
}
#student_details_section {
  border: 0.5px solid #cfcfcf !important;
  padding: 1px;;
}
td {
  font-size: 16px !important;
  border: 1px solid #b0b0b0 !important;
  padding: 10px !important;
}
th {
   font-size: 16px !important;
}
#div_size {
  font-size: 18px !important;
  padding: 5px;
}
.text-primary {
  color: #000000 !important;
}
.glyphicon {
  font-size: 10px !important;
}
.bold-text {
  font-weight: bold !important;
}
i {
  font-size: 12px !important;
  margin-right: 5px !important;
  margin-left: -2px !important;
  cursor: pointer !important;
}
</style>