export default {
  
  state: {
    loading: false,
    error: false,
    token: null,
    success: false
  },    
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },
    setToken (state, payload) {
      state.token = payload
    },
    setSuccess (state, payload) {
      state.success = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    }
  },
  actions: {
    clearError ({commit}) {
      commit('clearError')
    },
  },
  getters: {
    loading (state) {
      return state.loading
    },
    token (state) {
      return state.token
    },
    error (state) {
      return state.error
    },
    success (state) {
      return state.success
    }
  }
}
