<template>
  <div id="admin-view-all-results">
    <div class="row">
      <h2>Admin Veiw All Templates Results</h2>
    </div>
    <!-- <BackendScripts /> -->
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div
            class="card-header card-header-primary"
            style="margin-top: 15px !important"
          >
            <h3>View & Edit Templates Results</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          <!-- Filter Templates Results -->
          <div class="row" align="right">
            <div class="col-md-3 col-sm-3 col-xs-12"></div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <select
                @change="filterSession"
                v-model="filter_by_session"
                id="filter_by_session"
                class="form-control"
                style="margin-top: 10px"
              >
                <option value="" disabled selected>Filter by Session</option>
                <option value="">Remove Selection</option>
                <option
                  v-for="item in session_data"
                  :key="item.id"
                  :value="item.school_session"
                >
                  {{ item.school_session }}
                </option>
              </select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <select
                @change="filterClass"
                v-model="filter_by_class"
                id="filter_by_class"
                class="form-control"
                style="margin-top: 10px"
              >
                <option value="" disabled selected>Filter by Class</option>
                <option value="">Remove Selection</option>
                <option
                  v-for="item in classes_data"
                  :key="item.id"
                  :value="item.class_room"
                >
                  {{ item.class_room }}
                </option>
              </select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12" style="margin-top: 10px">
              <!-- <label for="filter_by_terms"></label> -->
              <select
                @change="filterTerms"
                v-model="filter_by_terms"
                id="filter_by_terms"
                class="form-control"
              >
                <option value="" disabled selected>Filter by Terms</option>
                <option value="">Remove Selection</option>
                <option value="First Term">First Term</option>
                <option value="Second Term">Second Term</option>
                <option value="Third Term">Third Term</option>
              </select>
            </div>
          </div>
          <p v-if="is_deleting" style="color:red">Deleting, Please wait...</p>
          <div class="card-body table-responsive">
            <br />
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Session</th>
                <th>Term</th>
                <th>Class</th>
                <th>View/Edit Template</th>
                <th>Clone Template</th>
                <th>Delete Template</th>
              </thead>
              <tbody>
                <tr v-for="item in template_list" v-bind:key="item.id">
                  <td>
                    <a
                      :href="
                        '/#/admin/veiw-result-template?template_id=' +
                        item.template_id
                      "
                      >{{ item.sch_session }}</a
                    >
                  </td>
                  <td>{{ item.sch_terms }}</td>
                  <td>{{ item.class_room }}</td>

                  <td>
                    <a
                      class="btn btn-success"
                      :href="
                        '/#/admin/veiw-result-template?template_id=' +
                        item.template_id
                      "
                      ><b>View / Edit</b></a
                    >
                  </td>

                  <td>
                    <a
                      class="btn btn-primary"
                      :href="
                        '/#/admin/clone-exisiting-result-template?template_id=' +
                        item.template_id
                      "
                      ><b>Clone</b></a
                    >
                  </td>

                  <td>
                    <a
                      class="btn btn-danger"
                      style="color: #fff !important"
                      :disabled="is_deleting"
                      @click.prevent="deleteTemplate(item.template_id, item.sch_session, item.sch_terms, item.class_room)"
                      ><b>Delete</b></a>
                      
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p v-if="is_deleting" style="color:red">Deleting, Please wait...</p>
        </div>
      </div>
      <!-- End Column -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { API_URL } from "../common/config";
import http from "../common/http-common";

export default {
  name: "AdminVeiwAllResults",
  components: {
    // BackendScripts
  },
  data() {
    return {
      template_list: [],
      headers: "",
      classes_data: "",
      session_data: "",
      filter_by_class: "",
      filter_by_terms: "",
      filter_by_session: "",
      school_id: "",
      is_deleting: false,
    };
  },
  created() {
    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    this.getClassAndSession();
    this.getAllTemplate();
  },
  methods: {
    async getClassAndSession() {
      await http
        .get(
          "students-reg/get-class-and-session/" + this.school_id + "/"
          //,{ headers: { "Content-Type": "multipart/form-data"  },}
        )
        .then((res) => {
          this.classes_data = res.data.data;
          this.session_data = res.data.data2;
        });
    },
    getAllTemplate() {
      axios
        .get(
          API_URL +
            "api/result-template/get-all-template/" +
            this.school_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          this.template_list = res.data;
        });
    },
    deleteTemplate(template_id, sch_session, sch_terms, class_room){

      if(confirm("Are sure you want to Delete " + sch_session + " / " + sch_terms + " / " + class_room +  " Result Template? Deleting this template will cause you to lose your Result Data(s)")){
        this.is_deleting = true
        axios
          .get(API_URL + "api/result-template/delete-template/" + this.school_id + "/" + template_id + "/",
            { headers: this.headers }
          )
          .then((res) => {
            this. getAllTemplate()
            //alert("Result Template was Successfully Deleted!")
            this.is_deleting = false
          });
      }
    },
    filterClass() {
      if (this.filter_by_class.length > 0) {
        this.template_list = this.template_list.filter(
          (res) => res.class_room == this.filter_by_class
        );
      } else {
        this.getAllTemplate();
      }
    },
    filterTerms() {
      if (this.filter_by_terms.length > 0) {
        this.template_list = this.template_list.filter(
          (res) => res.sch_terms == this.filter_by_terms
        );
      } else {
        this.getAllTemplate();
      }
    },
    filterSession() {
      if (this.filter_by_session.length > 0) {
        this.template_list = this.template_list.filter(
          (res) => res.sch_session == this.filter_by_session
        );
      } else {
        this.getAllTemplate();
      }
    },
  },
};
</script>

<style scoped>
a {
  font-size: 16px !important;
}
</style>
