<template>
  <div class="navbar-section">
    <header id="header" class="tt-nav transparent-header">
      <!-- nav-border-bottom -->

      <div class="header-sticky light-header">
        <div class="container">
          <div class="search-wrapper">
            <div class="search-trigger light pull-right">
              <div class="search-btn"></div>
              <i class="material-icons">&#xE8B6;</i>
            </div>

            <!-- Modal Search Form -->
            <i class="search-close material-icons">&#xE5CD;</i>
            <div class="search-form-wrapper">
              <form action="#" class="white-form">
                <div class="input-field">
                  <input type="text" name="search" id="search" />
                  <label for="search" class="">Search Here...</label>
                </div>
                <button
                  class="btn pink search-button waves-effect waves-light"
                  type="submit"
                >
                  <i class="material-icons">&#xE8B6;</i>
                </button>
              </form>
            </div>
          </div>
          <!-- /.search-wrapper -->

          <div id="materialize-menu" class="menuzord" @click="frontMenu()">
            <!--logo start-->
            <div style="flex-direction: column">
              <div>
                <a href="/" class="logo-brand">
                  <img
                    class="logo-dark"
                    :src="domainDetails.school_logo"
                    alt=""
                  />
                  <img
                    class="logo-light"
                    :src="domainDetails.school_logo"
                    alt=""
                  />
                </a>
              </div>
              <div
                style="
                  padding-top: 30px;
                  margin-left: 10px;
                  font-size: 20px;
                  font-weight: bold;
                "
              >
                <div class="light-logo-text" style="display: none">
                  {{ domainDetails.school_name }}
                </div>
                <div class="dark-logo-text" style="display: none">
                  {{ domainDetails.school_name }}
                </div>
              </div>
            </div>

            <!--logo end-->

            <!-- menu start-->
            <ul class="menuzord-menu pull-right light">
              <li id="menu-clicked" class="active">
                <a v-on:click="linkToPortfolio(links.home.value)">{{
                  links.home.name
                }}</a>
              </li>
              <li id="menu-clicked">
                <a v-on:click="linkToPortfolio(links.about_us.value)"
                  >{{ links.about_us.name }}
                </a>
              </li>
              <li id="menu-clicked">
                <a :href="domainDetails.main_school_url" target="_blank"
                  >Main School Website</a
                >
              </li>
              <!-- <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.contact_us.value)">{{ links.contact_us.name }}</a></li> -->
            </ul>
            <!-- menu end-->
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { API_URL } from "../../common/config";
//import store from

export default {
  name: "Navbar",
  data() {
    return {
      links: {
        home: {
          name: "",
          value: "",
        },
        about_us: {
          name: "",
          value: "",
        },
        crypto_trading: {
          name: "",
          value: "",
        },
        forex_trading: {
          name: "",
          value: "",
        },
        binary_trading: {
          name: "",
          value: "",
        },
        guide_page: {
          name: "",
          value: "",
        },
        regulation_page: {
          name: "",
          value: "",
        },
        login: {
          name: "",
          value: "",
        },
        admin_login: {
          name: "",
          value: "",
        },
        contact_us: {
          name: "",
          value: "",
        },
        logout: {
          name: "",
        },
      },
      LoginNavGuard: false,
      LoginNavGuardAdmin: false,
      domainDetails: "",
    };
  },
  computed: {
    user() {
      return true;
    },
  },
  watch: {
    user(value) {
      // console.log('= checkLogin 2 =')
      if (value !== null && value !== undefined) {
        // console.log('= isLogin =')
        //this.$router.push('/user')
      }
    },
  },
  created() {
    this.links.home = {
      name: "Home",
      value: "home",
    };
    this.links.about_us = {
      name: "About Us",
      value: "about_us",
    };
    this.links.crypto_trading = {
      name: "Cryptocurrency Trading",
      value: "crypto_trading",
    };
    this.links.forex_trading = {
      name: "Forex Trading",
      value: "forex_trading",
    };
    this.links.binary_trading = {
      name: "Binary Trading",
      value: "binary_trading",
    };
    this.links.guide_page = {
      name: "Guide",
      value: "guide_page",
    };
    this.links.regulation_page = {
      name: "Regulation",
      value: "regulation_page",
    };
    this.links.contact_us = {
      name: "Contact Us",
      value: "contact_us",
    };
    this.links.login = {
      name: "Login/Signup",
      value: "login",
    };
    (this.links.admin_login = {
      name: "Admin Login",
      value: "admin_login",
    }),
      (this.links.logout = {
        name: "Logout",
      });
    this.isLoginMenu();
    this.getNavLogo();
  },
  methods: {
    getNavLogo() {
      const currentUrlhost = window.location.host;

      axios
        .get(
          API_URL +
            "school-domain/api/get-school-domain/" +
            currentUrlhost +
            "/"
        )
        .then((res) => {
          // console.log("== res domainDetails", res);
          this.domainDetails = {
            school_id: res.data[0].school_id,
            school_logo: res.data[0].school_logo,
            school_name: res.data[0].school_name,
            school_slug: res.data[0].school_slug,
            sub_domain: res.data[0].sub_domain,
            main_school_url: res.data[0].main_school_url,
          };
          localStorage.setItem(
            "domainDetails",
            JSON.stringify(this.domainDetails)
          );
          // console.log('== getNavLogo', localStorage.getItem('domainDetails'))
        });
    },

    linkToPortfolio: function (name) {
      this.$router.push({ name: name });
      //window.location.href('/' + name)
    },
    isLoginMenu() {
      // console.log('= checkLogin 1 =', localStorage.getItem('userData'))
      if (localStorage.getItem("userData")) {
        if (JSON.parse(localStorage.getItem("userData")).is_admin) {
          this.LoginNavGuardAdmin = true;
          // console.log('= LoginNavGuardAdmin 1=', this.LoginNavGuardAdmin)
          return this.LoginNavGuard;
        } else {
          this.LoginNavGuard = true;
          // console.log('= LoginNavGuard 1=', this.LoginNavGuard)
          return this.LoginNavGuard;
        }
      } else {
        // console.log('= LoginNavGuard 2=', this.LoginNavGuard)
        return this.LoginNavGuard;
      }
    },
    onLogout() {
      this.$store.dispatch("logout");
      // console.log('== home logging out')
    },

    frontMenu() {
      this;
      if (this) {
        // console.log('== Toggle Menu ==')//menu-clicked
        $(".menuzord-menu #menu-clicked").click(function (event) {
          event.preventDefault();
          var w = $(window).width();
          // console.log(' Toggle Menu 2 ==', w)
          if (w < 768) {
            if ($(".showhide").is(":visible")) {
              $(".showhide").trigger("click");
            }
          }
        });
      }

      //$("#menu-toggled").toggleClass("toggled");
    },
  },
};
</script>

<style scoped lang="css">
/* @import '../../assets/SCSS/mixin.scss';
@import '../../assets/SCSS/colors.scss';
@import '../../assets/SCSS/fonts.scss';
@import '../../assets/SCSS/variables.scss'; */

.navbar-section {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .light-logo-text {
    color: #000 !important;
    display: block !important;
    margin-left: 10px !important;
    padding-left: 15px !important;
    margin-top: -20px !important;
  }
}

@media only screen and (min-width: 769px) {
  .dark-logo-text {
    color: #c3d4dd !important;
    display: block !important;
    margin-left: 10px !important;
    padding-left: 10px !important;
    margin-top: 8px !important;
  }

}
</style>
