<template>
  <div id="teacher-update-results">
    <div class="row">
      <h1>Teacher Update Subject Result</h1>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div
            class="card-header card-header-success"
            style="margin-top: 15px !important"
          >
            <h3>
              Update
              <span style="font-weight: bold">
                ({{ users.last_name }} {{ users.first_name }}
                {{ users.middel_name }})
              </span>
              Subject Result
            </h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          <p style="font-size: 16px; color: blue" align="right">
            <a>
              {{ result_heading.sch_session }} |
              {{ result_heading.class_room }} |
              {{ result_heading.sch_terms }} Results</a
            >
          </p>
          <div class="card-body table-responsive">
            <br />
            <form
              ref="submit_subject_form"
              @submit.prevent="submitStudentSubject"
            >
              <table class="table table-hover">
                <thead class="text-primary">
                  <th>Student ID</th>
                  <th>Subject</th>
                  <th>CA Score</th>
                  <th>Exam Score</th>
                  <th>Term Total</th>
                  <th>Grade</th>
                  <th>Submit Result</th>
                </thead>
                <tbody>
                  <tr v-for="item in subjects_list" v-bind:key="item.id">
                    <td>{{ item.student_user }}</td>
                    <td>{{ item.subject }}</td>
                    <td>
                      <input
                        v-if="item.is_teacher_submit == 'Submitted'"
                        class="form-control"
                        style="text-align: center !important"
                        id="ca_score"
                        name="ca_score"
                        readonly="readonly"
                        v-model="item.ca_score"
                      />
                      <input
                        v-else
                        class="form-control"
                        style="text-align: center !important"
                        @keyup="caScore(item.ca_score)"
                        id="ca_score"
                        name="ca_score"
                        v-model="item.ca_score"
                      />
                    </td>
                    <td>
                      <input
                        v-if="item.is_teacher_submit == 'Submitted'"
                        class="form-control"
                        style="text-align: center !important"
                        id="exam_score"
                        name="exam_score"
                        readonly="readonly"
                        v-model="item.exam_score"
                      />
                      <input
                        v-else
                        class="form-control"
                        style="text-align: center !important"
                        @keyup="examScore(item.exam_score)"
                        id="exam_score"
                        name="exam_score"
                        v-model="item.exam_score"
                      />
                    </td>
                    <td>
                      <input
                        class="form-control"
                        style="text-align: center !important"
                        id="term_total"
                        name="term_total"
                        readonly="readonly"
                        v-model="item.term_total"
                      />
                    </td>
                    <td>
                      <input
                        class="form-control"
                        style="text-align: center !important"
                        id="grade"
                        name="grade"
                        readonly="readonly"
                        v-model="item.grade"
                      />
                    </td>
                    <td>
                      <button
                        v-if="item.is_teacher_submit == 'Pending'"
                        id="submit_button"
                        class="btn btn-warning"
                      >
                        Submit
                      </button>
                      <button
                        v-if="item.is_teacher_submit == 'Submitted'"
                        id="submit_button"
                        class="btn btn-success"
                        :disabled="true"
                      >
                        Submit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
          <p v-if="is_subject_updating" style="color: red" align="center">
            {{ is_subject_updating }}
          </p>
        </div>
      </div>
      <!-- End Column -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { API_URL } from "../common/config";
import http from "../common/http-common";

export default {
  name: "teacher-update-results",
  components: {
    // BackendScripts
  },
  data() {
    return {
      id: this.$route.query.id,
      student_id: this.$route.query.student_id,
      result_id: this.$route.query.result_id,
      template_id: this.$route.query.template_id,
      subjects_list: "",
      headers: "",
      username: "",
      ca_score: "",
      exam_score: "",
      result_heading: "",
      users: "",
      is_subject_updating: "",
    };
  },
  created() {
    const { school_id, school_logo, school_name } = JSON.parse(
      localStorage.getItem("domainDetails")
    );
    this.school_id = school_id;
    this.school_logo = school_logo;
    this.school_name = school_name;

    var { token, username } = JSON.parse(localStorage.getItem("userData"));
    this.headers = { Authorization: `Token ${token}` };
    this.username = username;

    this.getStudentSubjects();
    this.getSubjectList();
  },
  computed: {},
  methods: {
    caScore(ca_score) {
      this.ca_score = ca_score;
      this.changeTotal();
    },
    examScore(exam_score) {
      this.exam_score = exam_score;
      this.changeTotal();
    },
    changeTotal() {
      if (this.ca_score && this.exam_score) {
        let total = parseInt(this.ca_score, 10) + parseInt(this.exam_score, 10);
        if (total > 100) {
          alert("Error!, Total can't be Higher than 100!");
          document.getElementById("ca_score").value = "";
          document.getElementById("ca_score").placeholder = "Re-Enter CA Score";
          document.getElementById("exam_score").value = "";
          document.getElementById("exam_score").placeholder =
            "Re-Enter Exam Score";
          return;
        }
        document.getElementById("term_total").value = total;
        this.updateGrade(total);
      }
    },
    updateGrade(term_total) {
      if (term_total) {
        axios
          .get(
            API_URL +
              "api/school-result/get-subject-grades/" +
              this.result_id +
              "/" +
              term_total +
              "/",
            { headers: this.headers }
          )
          .then((res) => {
            document.getElementById("grade").value = res.data.grade;
          });
      }
    },
    submitStudentSubject() {
      if (this.ca_score && this.exam_score) {
        this.is_subject_updating = "Subject Result Updating, Please wait...";
        let form = this.$refs.submit_subject_form;
        let length = form.length - 1;
        let x = 0;
        let formData = [];
        for (x; x < length; x++) {
          formData.push({
            id: parseInt(this.id, 10),
            name: form[x].name,
            value: form[x].value,
          });
        }

        axios
          .post(
            API_URL +
              "api/school-result/teacher-submit-subject/" +
              this.school_id +
              "/" +
              this.username +
              "/" +
              this.result_id +
              "/" +
              this.student_id +
              "/",
            { form_data: formData },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.message) {
              alert("Error!, Kindly check your inputs");
            } else {
              const subject = res.data.subject;
              document.getElementById("ca_score").value = subject.ca_score;
              document.getElementById("ca_score").readOnly = true;
              document.getElementById("exam_score").value = subject.exam_score;
              document.getElementById("exam_score").readOnly = true;
              document.getElementById("term_total").value = subject.term_total;
              document.getElementById("grade").value = subject.grade;
              document.getElementById("submit_button").className =
                "btn btn-success";
              alert("Subject Result was successfully Updated");
              this.is_subject_updating = "";

              setTimeout(() => {
                this.$router.push("/teacher/teacher-view-and-edit-result");
              }, 1500);
            }
          });
      } else {
        document.getElementById("ca_score").value = "";
        document.getElementById("ca_score").placeholder =
          "Please Enter CA Score";
        document.getElementById("exam_score").value = "";
        document.getElementById("exam_score").placeholder =
          "Please Enter Exam Score";
        alert("Please Enter the Result Details");
      }
    },
    getSubjectList() {
      axios
        .get(
          API_URL +
            "api/school-result/get-student-subjects/" +
            this.school_id +
            "/" +
            this.template_id +
            "/" +
            this.result_id +
            "/" +
            this.student_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          //console.log("res.data ", res.data.subject);
          this.subject_list = res.data.subject;
          res.data.result.map((data) => {
            this.result_heading = data;
          });
          this.users = res.data.users;
        });
    },
    getStudentSubjects() {
      axios
        .get(
          API_URL +
            "api/school-result/get-subject-assigned-to-teacher/" +
            this.school_id +
            "/" +
            this.username +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          //console.log("subjects_list ", res.data);
          this.subjects_list = res.data.results.filter(
            (res) =>
              res.student_id == this.student_id &&
              res.id == parseInt(this.id, 10) &&
              res.result_id == this.result_id
          );
        });
    },
  },
};
</script>

<style></style>
