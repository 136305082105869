<template>
  <div id="admin-view-student-details" ref="DownloadComp">
    <!-- Start School Logo & School Details Section -->
    <section id="logo_school_name">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div align="center">
              <img :src="sch_details.school_banner" style="max-width: 80%" />
            </div>
            <div align="center" style="margin-top: 2% !important">
              <!-- <h1
                style="font-size: 42px !important; font-weight: bold !important"
              >
                {{ school_name }}
              </h1> -->
              <div id="div_size">
                {{ sch_details.street_address }}, {{ sch_details.city }},
                {{ sch_details.lga }}, {{ sch_details.landmark }},
                {{ sch_details.postal_code }}, {{ sch_details.state }},
                {{ sch_details.country }}.
              </div>
              <div v-if="sch_details.school_motto" id="div_size">
                MOTTO: {{ sch_details.school_motto }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row">
        <div
          class="col-md-12 col-xs-12"
          style="margin-top: 15px; overflow-y: hidden; padding: 30px"
        >
          <div class="card-body table-responsive">
            <table
              v-for="item in student_details"
              :key="item.id"
              width="100%"
              class="table table-hover"
              border="1"
            >
              <tbody>
                <tr>
                  <td colspan="3">
                    <div>
                      <p align="left">
                        <!-- <strong>Photo</strong><br /><br /> -->
                        <img
                          v-if="item.student_photo"
                          :src="item.student_photo"
                          width="150PX"
                          style="padding: 25px; border: solid 2px #123985"
                        />

                        <span
                          v-else
                          style="
                            width: 250px;
                            padding: 25px;
                            border: solid 2px #123985;
                          "
                        >
                          <h5 style="color: #000" align="center">
                            <b>No Photo</b>
                          </h5>
                        </span>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><strong>Student ID</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.student_user }}</td>
                </tr>
                <tr>
                  <td><strong>Title</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.title }}</td>
                </tr>
                <tr>
                  <td width="38%"><strong>Student Last name</strong></td>
                  <td width="3%">&nbsp;</td>
                  <td width="59%">{{ user_name.last_name }}</td>
                </tr>
                <tr>
                  <td><strong>Student First name</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ user_name.first_name }}</td>
                </tr>
                <tr>
                  <td><strong>Student Middle name</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.middel_name }}</td>
                </tr>

                <tr>
                  <td><strong>Admitted Session/Year</strong></td>
                  <td>&nbsp;</td>
                  <td>
                    <b>{{ item.entering_session }}</b>
                  </td>
                </tr>
                <tr>
                  <td><strong>Admitted Class</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.current_class }}</td>
                </tr>
                <tr>
                  <td><strong>Admitted Term</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.entering_term }}</td>
                </tr>
                <tr>
                  <td width="38%"><strong>Gender</strong></td>
                  <td width="3%">&nbsp;</td>
                  <td width="59%">{{ item.gender }}</td>
                </tr>
                <tr>
                  <td><strong>Date of Birth</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.date_of_birth }}</td>
                </tr>
                <tr>
                  <td><strong>Phone Number</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.phone_number }}</td>
                </tr>
                <tr>
                  <td><strong>Whatsapp</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.whatsapp }}</td>
                </tr>
                <tr>
                  <td><strong>Student Address</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.address }}</td>
                </tr>
                <tr>
                  <td><strong>LGA</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.residence_lga }}</td>
                </tr>
                <tr>
                  <td><strong>State of Residence</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.state_of_residence }}</td>
                </tr>
                <tr>
                  <td><strong>State of Origin</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.state_of_origin }}</td>
                </tr>
                <tr>
                  <td><strong>LGA (State of Origin)</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.origin_lga }}</td>
                </tr>
                <tr>
                  <td><strong>Country</strong></td>
                  <td>&nbsp;</td>
                  <td>{{ item.country }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <!-- <button class="btn btn-primary" @click="printDocument()">
      Export To PDF
    </button> -->
  </div>
</template>

<script>
/* eslint-disable */
import http from "../../../common/http-common";

import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { jsPDF } from "jspdf";

export default {
  name: "admin-view-student-details",
  data() {
    return {
      sch_details: "",
      student_details: "",
      user_name: "",
      student_id: this.$route.query.student_id,
      school_id: "",
      school_logo: "",
      school_name: "",
      headers: "",
    };
  },
  created() {
    const { school_id, school_logo, school_name } = JSON.parse(
      localStorage.getItem("domainDetails")
    );
    this.school_id = school_id;
    this.school_logo = school_logo;
    this.school_name = school_name;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getMoreSchoolDetails();
    this.getStudentDetails();
  },
  methods: {
    printDocuments() {
      //get table html
      const pdfTable = document.getElementById("admin-view-student-details");

      //html to pdf format
      var html = htmlToPdfmake(pdfTable.innerHTML);
      //console.log('html ', html)

      let docs = new jsPDF();

      // let js_pdf = docs.html(pdfTable)
      // js_pdf.save()
      // console.log('js_pdf ', js_pdf)

      docs.html(document, {
        callback: function (doc) {
          doc.save();
        },
        x: 10,
        y: 10,
        width: 2480,
      });

      const documentDefinition = { content: html };
      //pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const pdf_Make = pdfMake.createPdf(documentDefinition); //.open();
      //console.log('pdf_Make ', pdf_Make)
    },

    printDocument() {
      let htmlData = { html_data: this.$refs.DownloadComp.innerHTML };
      //console.log("htmlData ", htmlData);

      if (this.school_id) {
        http
          .post(
            "school-result/export-html-to-pdf/" + this.school_id + "/",
            htmlData,
            { headers: this.headers }
          )
          .then((res) => {
            console.log("res htmlData ", res);
          });
      }
    },
    getMoreSchoolDetails() {
      if (this.school_id) {
        http
          .get(
            "result-template/get-more-school-details/" + this.school_id + "/",
            { headers: this.headers }
          )
          .then((res) => {
            res.data.data.map((data) => {
              this.sch_details = data;
            });
          });
      }
    },
    async getStudentDetails() {
      await http
        .get(
          "students-reg/get-student-details/" +
            this.school_id +
            "/" +
            this.student_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          console.log("getStudentDetails ", res.data.data);
          this.student_details = res.data.data;
          this.user_name = res.data.users;
        });
    },
  },
};
</script>

<style scoped>
#admin-view-student-details {
  background-color: white !important;
  padding: 30px !important;
}
#logo_school_name {
  margin-bottom: 30px !important;
  border: 0.5px solid #cfcfcf !important;
  padding: 20px;
}
#student_details_section {
  border: 0.5px solid #cfcfcf !important;
  padding: 1px;
}
td {
  font-size: 16px !important;
  padding: 10px !important;
}
th {
  font-size: 16px !important;
}
#div_size {
  font-size: 18px !important;
  padding: 5px;
}
.text-primary {
  color: #000000 !important;
}
.glyphicon {
  font-size: 10px !important;
}
.bold-text {
  font-weight: bold !important;
}
i {
  font-size: 12px !important;
  margin-right: 5px !important;
  margin-left: -2px !important;
  cursor: pointer !important;
}
input {
  height: 30px !important;
  background-color: white !important;
}
</style>
