<template>
  <div id="teacher-dashboard">
      <h1>Teacher Dashboard</h1>
  <div class="row">
  </div>
  <div class="row">
      <br> <hr> <br>
    <div class="col-md-4">
      
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>View / Edit Result</b></h4>
          </div>
        </div>                
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/teacher/teacher-view-and-edit-result"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-chart">
        <div class="card-header card-header-warning">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Change Password</b></h4>
          </div>
        </div>              
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/teacher/teacher-change-password"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-chart">
        <div class="card-header card-header-primary">
          <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
            <h4 class="card-title"><b>Logout</b></h4>
          </div>
        </div>  
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons">arrow_forward</i>
            <router-link to="/logout"><b> Click here to start</b></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
export default {
    name: "TeacherDashboard",
    data() {
      return {
        
      }
    },
    created() {
      
    },
    methods: {
      
    },
}
</script>

<style>

</style>