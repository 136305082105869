<template>
  <div>
    <div class="row">
      <h2>Admin Add Student To Result</h2>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <p align="right" v-if="isGoBack">
            <button
              class="btn btn-success"
              style="
                width: 150px;
                margin-top: 15px !important;
                margin-right: 20px;
              "
              @click="goBack()"
            >
              {{ go_back }}
            </button>
          </p>
          <br />
          <div
            class="card-header card-header-primary"
            style="margin-top: 5px !important"
          >
            <h2>Select & Add Pupils/Students to Result</h2>
          </div>

          <div class="card-body table-responsive">
            <br />
            <p style="font-size: 16px; color: blue" align="right">
              <a>
                {{ template_details.sch_session }} |
                {{ template_details.class_room }} |
                {{ template_details.sch_terms }} Results</a
              >
            </p>
            <p
              style="
                color: red;
                font-size: 14px;
                margin-left: 40%;
                margin-right: 20px;
              "
              align="right"
            >
              <b
                >Filter below to Select Pupils/Students you what to Add to
                {{ template_details.sch_session }},
                {{ template_details.class_room }},
                {{ template_details.sch_terms }} Results</b
              >
            </p>

            <form role="form" @change="multiplySelectStudent()">
              <div class="row">
                <div class="col-md-5 col-sm-5 col-xs-12">
                  <button
                    @click.prevent="multiplySelectButton"
                    :disabled="isMultiplySelected"
                    class="btn btn-success"
                    style="margin-bottom: 15px"
                  >
                    Select Multiple Students
                  </button>
                  <p v-if="isAddingStudentMultiply" style="color:red;">Add Student to Result, Please wait...</p>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12">
                  <select
                    @change="filterSession"
                    v-model="filter_by_session"
                    id="filter_by_session"
                    class="form-control"
                    style="margin-top: 10px"
                  >
                    <option value="" disabled selected>
                      Filter by Session
                    </option>
                    <option value="">Remove Selection</option>
                    <option
                      v-for="item in session_data"
                      :key="item.id"
                      :value="item.school_session"
                    >
                      {{ item.school_session }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <select
                    @change="filterClass"
                    v-model="filter_by_class"
                    id="filter_by_class"
                    class="form-control"
                    style="margin-top: 10px"
                  >
                    <option value="" disabled selected>Filter by Class</option>
                    <option value="">Remove Selection</option>
                    <option
                      v-for="item in classes_data"
                      :key="item.id"
                      :value="item.class_room"
                    >
                      {{ item.class_room }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-md-2 col-sm-2 col-xs-12"
                  style="margin-top: 10px"
                >
                  <!-- <label for="filter_by_terms"></label> -->
                  <select
                    @change="filterTerms"
                    v-model="filter_by_terms"
                    id="filter_by_terms"
                    class="form-control"
                  >
                    <option value="" disabled selected>Filter by Terms</option>
                    <option value="">Remove Selection</option>
                    <option value="First Term">First Term</option>
                    <option value="Second Term">Second Term</option>
                    <option value="Third Term">Third Term</option>
                  </select>
                </div>
              </div>
              <div>
                <p
                  style="color: red; font-size: 15px"
                  v-for="(err_mgs, index) in error_messages"
                  :key="index"
                >
                  {{ err_mgs }}
                </p>
              </div>
              <table class="table table-hover">
                <thead class="text-primary">
                  <th>Multiple Selection</th>
                  <th>Student Photo</th>
                  <th>Student ID</th>
                  <th>Full name</th>
                  <th>Gender</th>
                  <th>
                    Current Session/Year: <br />Current Class: <br />Current
                    Term
                  </th>
                  <th>Single Select Student</th>
                </thead>
                <tbody>
                  <tr v-for="item in student_list" v-bind:key="item.id">
                    <td>
                      <div
                        style="
                          background-color: #e9ecf1;
                          border-radius: 7px;
                          height: 50px !important;
                          width: 40px;
                        "
                      >
                        <p align="center" class="form-group">
                          <input
                            type="checkbox"
                            :id="item.student_id"
                            v-model="upgrade_box"
                            :value="item.student_id"
                            style="background-color: #fff"
                            class="custom-control"
                          />
                          <label
                            :for="item.student_id"
                            style="margin: 11px"
                          ></label>
                        </p>
                      </div>
                    </td>
                    <td>
                      <img :src="item.student_photo" width="75px" />
                    </td>
                    <td>{{ item.student_user }}</td>
                    <td>
                      {{ item.last_name }} {{ item.first_name }}
                      {{ item.middel_name }}
                    </td>
                    <td>{{ item.gender }}</td>
                    <td>
                      {{ item.student_session }}
                      <br />{{ item.student_class }} <br />{{
                        item.student_term
                      }}
                    </td>

                    <td>
                      <button
                        @click.prevent="singleSelectButton(item.student_id)"
                        :disabled="isSingleSelect"
                        class="btn btn-success"
                      >
                        Select
                      </button>
                      <p v-if="isAddingStudentSingle && is_single_selected_student_id == item.student_id" style="color:red;">Add Student to Result, Please wait...</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
      <!-- End Column -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import http from "../../common/http-common";
import { API_URL } from "@/common/config";

export default {
  name: "add-student-to-result",
  data() {
    return {
      template_id: this.$route.query.template_id,
      go_back: "<<< Back",
      isGoBack: true,
      student_list: "",
      BASE_URL: API_URL.slice(0, -1),
      upgrade_box: [],
      checked: "",
      isMultiplySelected: true,
      isSingleSelect: false,
      template_details: "",
      session_data: [],
      classes_data: [],
      filter_by_session: "",
      filter_by_class: "",
      filter_by_terms: "",
      error_messages: [],
      headers: "",
      isAddingStudentMultiply: false,
      isAddingStudentSingle: false,
      is_single_selected_student_id: null,
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getTemplateDetails();
    this.getClassAndSession();
    this.getStudentList();
  },
  methods: {
    async getTemplateDetails() {
      await http
        .get("school-result/get-template-details/" + this.template_id + "/", {
          headers: this.headers,
        })
        .then((res) => {
          res.data.map((data) => {
            this.template_details = data;
          });
        });
    },
    getStudentList() {
      http
        .get("school-result/get-student-list/" + this.school_id + "/", {
          headers: this.headers,
        })
        .then((res) => {
          this.student_list = res.data.data;
        });
    },
    filterClass() {
      if (this.filter_by_class.length > 0) {
        this.student_list = this.student_list.filter(
          (res) => res.student_class == this.filter_by_class
        );
      } else {
        this.getStudentList();
      }
    },
    filterTerms() {
      if (this.filter_by_terms.length > 0) {
        this.student_list = this.student_list.filter(
          (res) => res.student_term == this.filter_by_terms
        );
      } else {
        this.getStudentList();
      }
    },
    filterSession() {
      if (this.filter_by_session.length > 0) {
        this.student_list = this.student_list.filter(
          (res) => res.student_session == this.filter_by_session
        );
      } else {
        this.getStudentList();
      }
    },
    multiplySelectStudent() {
      if (this.upgrade_box.length) {
        this.isMultiplySelected = false;
        this.isSingleSelect = true;
      } else {
        this.isMultiplySelected = true;
        this.isSingleSelect = false;
      }
    },
    multiplySelectButton() {
      this.isMultiplySelected = true;
      this.isAddingStudentMultiply = true;
      const postData = {
        template_id: this.template_id,
        school_id: this.school_id,
        selected_students: this.upgrade_box,
      };
      http
        .post("school-result/add-student-to-result/", postData, {
          headers: this.headers,
        })
        .then((res) => {
          if (res.data.error_messages.length > 0) {
            this.error_messages = res.data.error_messages;

            setTimeout(() => {
              this.error_messages = [];

              this.$router.push(
                "/admin/view-student-to-result?template_id=" + this.template_id
              );
            }, 3000);
          } else {
            this.isAddingStudentMultiply = false;
            alert("Adding Students Details to Results was Successfully!");

            this.$router.push(
              "/admin/view-student-to-result?template_id=" + this.template_id
            );
          }
          this.isMultiplySelected = false;
        })
        .catch((err) => {
          alert(err);
        });
    },
    singleSelectButton(student_id) {
      this.isSingleSelect = true;
      this.isAddingStudentSingle = true;
      this.is_single_selected_student_id = student_id
      const selected_students = [student_id];
      const postData = {
        template_id: this.template_id,
        school_id: this.school_id,
        selected_students: selected_students,
      };
      http
        .post("school-result/add-student-to-result/", postData, {
          headers: this.headers,
        })
        .then((res) => {
          if (res.data.error_messages.length > 0) {
            this.error_messages = res.data.error_messages;

            setTimeout(() => {
              this.error_messages = [];

              this.$router.push(
                "/admin/view-student-to-result?template_id=" + this.template_id
              );
            }, 3000);
          } else {
            this.isAddingStudentSingle = false;
            this.is_single_selected_student_id = null
            alert("Adding Students Details to Results was Successfully!");

            this.$router.push(
              "/admin/view-student-to-result?template_id=" + this.template_id
            );
          }
          this.isSingleSelect = false;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async getClassAndSession() {
      await http
        .get("students-reg/get-class-and-session/" + this.school_id + "/", {
          headers: this.headers,
        })
        .then((res) => {
          this.classes_data = res.data.data;
          this.session_data = res.data.data2;
        });
    },
    goBack() {
      this.$router.push("/admin/view-school-result-reports");
    },
  },
};
</script>

<style></style>
