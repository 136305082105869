<template>
  <div id="admin-veiw-results">
    <div class="row">
      <div class="col">
        <h2>Admin Veiw Results</h2>
      </div>
    </div>
    <!-- Start School Logo & School Details Section -->
    <section id="logo_school_name">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div align="center">
              <img :src="sch_details.school_banner" style="max-width: 80%" />
            </div>
            <div align="center" style="margin-top: 2% !important">
              <div id="div_size">
                {{ sch_details.street_address }}, {{ sch_details.city }},
                {{ sch_details.lga }}, {{ sch_details.landmark }},
                {{ sch_details.postal_code }}, {{ sch_details.state }},
                {{ sch_details.country }}.
              </div>
              <div v-if="sch_details.school_motto" id="div_size">
                MOTTO: {{ sch_details.school_motto }}
              </div>
              <hr />
              <div id="div_size">
                REPORT SHEET FOR
                <span class="bold-text">{{ session_term.sch_terms }}</span> |
                <span class="bold-text">{{ session_term.sch_session }}</span>
                SESSION
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End School Logo & School Details Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Student Details Section -->
    <section id="student_details_section">
      <div class="container">
        
          <div class="row">
            
            <div class="col-md-7 col-sm-7 col-xs-12">
              <form ref="form" id="top-form" @submit.prevent="submitTopLeftDetails">
              <div class="card-body table-responsive">
                <br />
                <table class="table table-bordered table-hover" style="">
                  <tr>
                    <td style="width: 40% !important">
                      <tbody >
                        <tr v-for="left in top_left" :key="left.id" >
                          <td height="60px" v-if="left.left_key == 'Next Term Begins'">
                            <span :id="'top-left' + left.id">
                              {{ left.left_key }}</span
                            >:
                          </td>
                          <td height="60px" v-else-if="left.is_origin == 'No'">
                            <span :id="'top-left' + left.id">
                              {{ left.left_key }}</span
                            >:
                          </td>
                          <td height="60px" v-else-if="left.left_key == 'Student ID'">
                            <span :id="'top-left' + left.id">
                              {{ left.left_key }}</span
                            >:
                          </td>
                          <td height="60px" v-else-if="left.left_key == 'Number in Class'">
                            <span :id="'top-left' + left.id">
                              {{ left.left_key }}</span
                            >:
                          </td>
                          <td height="60px" v-else>
                            <span :id="'top-left' + left.id">
                              {{ left.left_key }}</span
                            >:
                          </td>
                        </tr>
                      </tbody>
                    </td>
                    <td style="width: 60% !important">
                      <tbody
                      style="
                                color: #373638 !important;
                                padding-top: 8px;
                                padding-bottom: 8px;
                              "
                      >
                        <tr v-for="left in top_left" :key="left.id">
                          <td v-if="left.left_key == 'Next Term Begins'">
                            <input
                              style="color: #373638 !important"
                              name="left_value"
                              :id="left.id"
                              :value="left.left_value"
                              type="text"
                              :placeholder="left.left_key"
                            />
                          </td>

                          <td v-else-if="left.is_origin == 'No'">
                            <input
                              style="color: #373638 !important"
                              name="left_value"
                              :id="left.id"
                              :value="left.left_value"
                              type="text"
                              :placeholder="left.left_key"
                            />
                          </td>

                          <td v-else-if="left.left_key == 'Student ID'">
                            {{ student_user }}:
                          </td>

                          <td v-else-if="left.left_key == 'Number in Class'">
                            <input
                              style="color: #373638 !important"
                              name="left_value"
                              :id="left.id"
                              :value="left.left_value"
                              type="text"
                              placeholder="Number in Class"
                            />
                          </td>

                          <td v-else>
                            {{ left.left_value }}
                          </td>
                        </tr>
                      </tbody>
                    </td>
                  </tr>
                </table>
              </div>
              <div align="center">
              <button
              id="top-form"
              :disabled="is_top_left_heading_btn"
              class="btn btn-success"
              >
                Update Left Top
              </button>
            </div>
            
             </form>
             <p v-if="is_top_left_heading_updating" align="center" style="color: red">
          <b>{{ is_top_left_heading_updating }}</b>
        </p>
            </div>            
            <div class="col-md-5 col-sm-5 col-xs-12">
              <form ref="form_top_right" id="form_top_right" @submit.prevent="submitTopRightDetails">
              <div class="card-body table-responsive">
                <br />
                <table class="table table-bordered table-hover">
                  <tr>
                    <td style="width: 60% !important">
                      <tbody>
                        <tr v-for="right in top_right" :key="right.id">
                          <td>
                            <input
                              style="
                                color: #373638 !important;
                                margin-top: 8px;
                                margin-bottom: 8px;
                              "
                              readonly="readonly"
                              :value="right.right_key + ' :'"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </td>
                    <td style="width: 40% !important">
                      <tbody>
                        <tr v-for="right in top_right" :key="right.id">
                          <td>
                            <input v-if="right.right_key == 'Number of Days Present' || right.right_key == 'Number of Days Absent'"
                              style="color: #373638 !important"
                              name="right_value"
                              :id="right.id"
                              :ref="'right_id'+right.id"
                              :value="right.right_value"
                              type="text"
                            />
                            <input v-else-if="right.is_origin == 'Yes'"
                              style="color: #373638 !important"
                              name="right_value"
                              :id="right.id"
                              :value="right.right_value+'+'"
                              type="text"
                              readonly="readonly"
                            />
                            <input v-else
                              style="color: #373638 !important"
                              name="right_value"
                              :id="right.id"
                              :ref="'right_id'+right.id"
                              :value="right.right_value"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </td>
                  </tr>
                </table>
              </div>
              <div align="center">
                <button
                id="top-form"
                :disabled="is_top_right_heading_btn"
                class="btn btn-success"
                >
                  Update Top right
                </button>
                
              </div>
            </form>
            <p v-if="is_top_right_heading_updating" align="center" style="color: red">
              <b>{{ is_top_right_heading_updating }}</b>
            </p>
            </div>
            
          </div>
          
            
        
      </div>
    </section>
    <!-- End Student Details Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Subjects Section -->
    <section id="student_details_section" style="margin-top: 50px">
      <div class="container">
        <form ref="form2" id="subject" @submit.prevent="submitSubjects">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="card-body table-responsive">
                <br />
                <table class="table table-hover table-bordered">
                  <thead
                    class="text-primary"
                    align="center"
                    v-for="sub_head in subject_heading"
                    :key="sub_head.sub_head"
                  >
                    <th style="font-weight: bold !important" class="align-top">
                      SUBJECTS
                    </th>

                    <th style="font-weight: bold !important" class="align-top">
                      <span>{{ sub_head.ca_score }} </span>
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      <span>{{ sub_head.exam_score }} </span>
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      <span>{{ sub_head.term_total }} </span>
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      <span>{{ sub_head.highest_in_class }} </span>
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      <span>{{ sub_head.grade }} </span>
                    </th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="contents in subject_contents"
                      :key="contents.subject"
                    >
                      <td style="font-weight: bold !important">
                        <span :id="'subject-content' + contents.id">{{
                          contents.subject
                        }}</span>
                      </td>
                      <td>
                        <input
                          style="color: #373638 !important"
                          v-model="contents.ca_score"
                          name="ca_score"
                          :id="contents.id"
                          type="text"
                          @change="caScore(contents.id, contents.ca_score)"
                          :ref="'ca_score' + contents.id"
                        />
                      </td>
                      <td>
                        <input
                          style="color: #373638 !important"
                          v-model="contents.exam_score"
                          name="exam_score"
                          :id="contents.id"
                          type="text"
                          @change="examScore(contents.id, contents.exam_score)"
                          :ref="'exam_score' + contents.id"
                        />
                      </td>
                      <td>
                        <input
                          style="color: #373638 !important"
                          v-model="contents.term_total"
                          name="term_total"
                          :id="contents.id"
                          type="text"
                          :ref="'term_total' + contents.id"
                        />
                      </td>
                      <td>
                        <input
                          style="color: #373638 !important"
                          v-model="contents.highest_in_class"
                          name="highest_in_class"
                          :id="contents.id"
                          type="text"
                          readonly="readonly"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input
                          style="color: #373638 !important"
                          v-model="contents.grade"
                          name="grade"
                          :id="contents.id"
                          type="text"
                          :ref="'grade' + contents.id"
                          readonly="readonly"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td colspan="5" style="font-weight: bold !important">
                        GRADING:-
                        <span v-for="grade in grades" :key="grade.id">
                          <span
                            >{{ grade.grade }}: {{ grade.mark_from }} -
                          {{ grade.mark_to }} = {{ grade.comment }},
                          </span>
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div align="center">
            <button
              id="subject"
              :disabled="is_top_subject_btn"
              class="btn btn-success"
            >
              Update
            </button>
          </div>
        </form>
        <p v-if="is_top_subject_updating" align="center" style="color: red">
          <b>{{ is_top_subject_updating }}</b>
        </p>
      </div>
    </section>
    <!-- End Subjects Section -->

    <!-- ============ ============ ======== =========== ======== ======== =========  -->

    <!-- Start Assessments Section -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <table class="table table-hover table-bordered">
                <thead class="text-primary" align="center">
                  <th
                    style="font-weight: bold !important"
                    class="align-top"
                    colspan="2"
                  >
                    KEY
                  </th>
                </thead>
                <tbody v-for="item in trait_key" :key="item.ranting">
                  <tr>
                    <td style="font-weight: bold !important">
                      {{ item.ranting }}
                    </td>
                    <td>
                      <span :id="'key-trait' + item.id"> {{ item.trait }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card-body table-responsive">
              <br />
              <form ref="form3" id="assessment_heading_one">
                <table class="table table-hover table-bordered">
                  <thead class="text-primary" align="center">
                    <th style="font-weight: bold !important" class="align-top">
                      {{ assessment_heading_one.assessment }}
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      1
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      2
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      3
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      4
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      5
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      Update
                    </th>
                  </thead>
                  <tbody
                    v-for="content in assessment_contents"
                    :key="content.contents"
                  >
                    <tr
                      v-if="
                        content.heading_id == assessment_heading_one.heading_id
                      "
                    >
                      <td
                        style="font-weight: bold !important"
                        :id="'heading_id' + content.id"
                      >
                        <span :id="'assessment-content' + content.id">
                          {{ content.contents }}</span
                        >
                      </td>
                      <td>
                        <div v-if="content.ranting == 1">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox1' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox1' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox1' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox1' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 2">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox2' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox2' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox2' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox2' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 3">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox3' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox3' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox3' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox3' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 4">
                          <input
                            :ref="'checkbox4' + content.id"
                            style="width: 30px !important"
                            :id="'checkbox4' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox4' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox4' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox4' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 5">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox5' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox5' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox5' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox5' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <button
                          @click.prevent="
                            submitAssessentOne(
                              content.id,
                              content.heading_id,
                              content.ranting,
                              content.contents
                            )
                          "
                          class="btn btn-success"
                          :id="content.id"
                          :disabled="is_assest_one_btn"
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
            <b-modal
              :ok-only="true"
              ok-variant="dark"
              :ok-title="'Cancel'"
              id="modal-prevent-closing-update-assess-one"
              ref="updateAssessOneModal"
              :header-text-variant="'dark'"
              hide-backdrop
              :header-bg-variant="'primary'"
              title="Select Assessment"
            >
              <ResultAssestOneModal
                @clicked="assesseOneUpdated"
                :data="update_assesOne_modal"
              ></ResultAssestOneModal>
            </b-modal>
            <p v-if="is_assest_one_updating" align="center" style="color: red">
              <b>{{ is_assest_one_updating }}</b>
            </p>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card-body table-responsive">
              <br />
              <form ref="form4" id="assessment_heading_two">
                <table class="table table-hover table-bordered">
                  <thead class="text-primary" align="center">
                    <th style="font-weight: bold !important" class="align-top">
                      {{ assessment_heading_two.assessment }}
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      1
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      2
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      3
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      4
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      5
                    </th>
                    <th style="font-weight: bold !important" class="align-top">
                      Update
                    </th>
                  </thead>
                  <tbody
                    v-for="content in assessment_contents"
                    :key="content.contents"
                  >
                    <tr
                      v-if="
                        content.heading_id == assessment_heading_two.heading_id
                      "
                      class="form-group"
                      :id="'heading_ids' + content.heading_id"
                    >
                      <td style="font-weight: bold !important">
                        <span :id="'assessment-content-right' + content.id">
                          {{ content.contents }}
                        </span>
                      </td>
                      <td>
                        <div v-if="content.ranting == 1">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox1' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox1' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox1' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox1' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 2">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox2' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox2' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox2' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox2' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 3">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox3' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox3' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox3' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox3' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 4">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox4' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox4' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox4' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox4' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <div v-if="content.ranting == 5">
                          <input
                            style="width: 30px !important"
                            :id="'checkbox5' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                            checked
                          /><label :for="'checkbox5' + content.id"></label>
                        </div>
                        <div v-else>
                          <input
                            style="width: 30px !important"
                            :id="'checkbox5' + content.id"
                            :value="content.ranting"
                            type="radio"
                            :name="'ranting' + content.id"
                            :v-model="content.id"
                          /><label :for="'checkbox5' + content.id"></label>
                        </div>
                      </td>
                      <td>
                        <button
                          @click.prevent="
                            submitAssessentTwo(
                              content.id,
                              content.heading_id,
                              content.ranting,
                              content.contents
                            )
                          "
                          class="btn btn-success"
                          :id="content.id"
                          :disabled="is_assesst_two_btn"
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
              <b-modal
                :ok-only="true"
                ok-variant="dark"
                :ok-title="'Cancel'"
                id="modal-prevent-closing-update-assess-two"
                ref="updateAssessTwoModal"
                :header-text-variant="'dark'"
                hide-backdrop
                :header-bg-variant="'primary'"
                title="Select Assessment"
              >
                <ResultAssestTwoModal
                  @clicked="assesseTwoUpdated"
                  :data="update_assesTwo_modal"
                ></ResultAssestTwoModal>
              </b-modal>
              <p
                v-if="is_assesst_two_updating"
                align="center"
                style="color: red"
              >
                <b>{{ is_assesst_two_updating }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Assessments Section -->

    <!-- ============  ======== =========== ======== ======== =========  -->

    <!-- Start Teacher Comment Section -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-1 col-sm-1 col-xs-1">
            <p></p>
          </div>
          <div class="col-md-10 col-sm-10 col-xs-10">
            <div class="card-body table-responsive">
              <br />
              <form
                ref="from8"
                id="tcomment_form"
                @submit.prevent="submitComments"
              >
                <table class="table table-hover table-bordered">
                  <tbody v-for="item in comments" :key="item.id">
                    <tr>
                      <td style="font-weight: bold !important">
                        <span :id="item.id"> {{ item.comment_key }}</span
                        >:
                      </td>
                      <td v-if="item.comment_value" colspan="2">
                        <textarea
                          :name="'comment' + item.id"
                          :id="item.id"
                          type="text"
                          :value="item.comment_value"
                          rows="2"
                        ></textarea>
                      </td>
                      <td v-if="item.comment_image" colspan="2">
                        <img
                          id="update_signature"
                          :src="item.comment_image"
                          width="100px"
                        />
                        <hr />
                        <label :for="item.id"
                          >Upload/Change Signature</label
                        >
                        <input
                          name="file"
                          :id="item.id"
                          type="file"
                          accept="image/*"
                          ref="file"
                          @change="selectImage"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tr align="center">
                    <td align="center">
                      <p align="center">
                        <button
                          id="tcomment_form"
                          :disabled="is_comment_btn"
                          align="center"
                          class="btn btn-success"
                        >
                          Update Comment(s)
                        </button>
                        <br />
                        <span
                          v-if="is_comment_updating"
                          style="padding: 10px; color: red; font-size: 15px"
                          align="center"
                          >{{ is_comment_updating }}</span
                        >
                      </p>
                    </td>
                    <td align="center">
                      <div v-for="item in comments" :key="item.id">
                        <p align="center" v-if="item.comment_image">
                          <span v-if="previewImage">
                            <img
                              class="preview my-3"
                              :src="previewImage"
                              alt=""
                              width="15%"
                            /> </span
                          ><br />

                          <a
                            @click="uploadSignature(item.id)"
                            viif="is_comment_img_btn"
                            align="center"
                            class="btn btn-primary"
                            ><h4
                              style="color:white;, margin-top:-15px !important; padding-bottom:10px; font-size:18px; font-weight:bold;"
                            >
                              Update Signature
                            </h4></a
                          >
                          <br />
                          <span
                            v-if="is_comment_img_updating"
                            style="padding: 10px; color: red; font-size: 15px"
                            align="center"
                            >{{ is_comment_img_updating }}</span
                          >
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </form>
            </div>
          </div>
          <div class="col-md-1 col-sm-1 col-xs-1">
            <p></p>
          </div>
        </div>
      </div>
    </section>
    <div></div>
    <!-- End Teacher Comment Section -->
  </div>
</template>

<script>
/* eslint-disable */
import "bootstrap-vue";
import axios from "axios";
import { API_URL } from "../../common/config";
import ResultAssestOneModal from "../../modals/update-result-asseset-one-modal";
import ResultAssestTwoModal from "../../modals/update-result-asseset-two-modal";

export default {
  name: "edit-result-details",
  components: {
    ResultAssestOneModal,
    ResultAssestTwoModal,
  },
  data() {
    return {
      template_id: this.$route.query.template_id,
      student_id: this.$route.query.student_id,
      result_id: this.$route.query.result_id,
      top_input: {
        left_value: "",
        right_value: "",
      },
      top_left: "",
      top_right: "",
      subject: "",
      subject_heading: "",
      subject_contents: "",
      assessment_heading_two: "",
      assessment_heading_one: "",
      assessment_contents: "",
      trait_key: "",
      comments: "",
      grades: "",
      school_id: "",
      school_logo: "",
      school_name: "",
      session_term: "",
      sch_details: "",
      is_top_left_heading_updating: "",
      is_top_left_heading_btn: false,
      is_top_right_heading_btn: false,
      is_top_right_heading_updating:'',
      is_top_subject_updating: "",
      is_top_subject_btn: false,
      is_assest_one_btn: false,
      is_assesst_two_btn: false,
      is_assesst_two_updating: "",
      is_assest_one_updating: "",
      update_assesOne_modal: [],
      update_assesTwo_modal: [],
      is_comment_btn: false,
      is_comment_updating: "",
      currentImage: null,
      is_comment_img_updating: false,
      previewImage: null,
      student_user: "",
      headers: "",
      ca_score: "",
      exam_score: "",
      term_total: "",
      id: "",
    };
  },
  computed: {
    isAssestChecked(event) {
      //console.log("ranting", event);
      return true;
    },
  },
  created() {
    const { school_id, school_logo, school_name } = JSON.parse(
      localStorage.getItem("domainDetails")
    );
    this.school_id = school_id;
    this.school_logo = school_logo;
    this.school_name = school_name;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getTemplate();
    this.isTriatComplete = true;
    this.getMoreSchoolDetails();
  },
  methods: {
    caScore(id, ca_score) {
      this.ca_score = ca_score;
      this.id = id;
      this.termTotal();
    },
    examScore(id, exam_score) {
      this.exam_score = exam_score;
      this.id = id;
      this.termTotal();
    },
    termTotal() {
      if (this.ca_score && this.exam_score) {
        this.term_total =
          parseInt(this.ca_score, 10) + parseInt(this.exam_score, 10);
        if (this.term_total > 100) {
          alert("Error!, Total can't be Higher than 100!");
          this.$refs["ca_score" + this.id][0].value = "";
          this.$refs["ca_score" + this.id][0].placeholder = "Re-Enter CA Score";
          this.$refs["exam_score" + this.id][0].value = "";
          this.$refs["exam_score" + this.id][0].placeholder =
            "Re-Enter Exam Score";
          return;
        }
        this.$refs["term_total" + this.id][0].value = this.term_total;
        this.gradeUpdate();
      }
    },
    gradeUpdate() {
      if (this.term_total) {
        axios
          .get(
            API_URL +
              "api/school-result/get-subject-grades/" +
              this.result_id +
              "/" +
              this.term_total +
              "/",
            { headers: this.headers }
          )
          .then((res) => {
            this.updateOneRowSubject(
              this.id,
              this.ca_score,
              this.exam_score,
              this.term_total,
              res.data.grade
            );
            this.$refs["grade" + this.id][0].value = res.data.grade;
          });
      }
    },
    async updateOneRowSubject(id, ca_score, exam_score, term_total, grade) {
      await axios
        .post(
          API_URL + "api/school-result/update-one-row-subjects/",
          {
            result_id: this.result_id,
            id: id,
            ca_score: ca_score,
            exam_score: exam_score,
            term_total: term_total,
            grade: grade,
          },
          { headers: this.headers }
        )
        .then((res) => {
          this.subject_contents = res.data.subject;
          this.term_total = false;
          this.ca_score = "";
          this.exam_score = "";
          this.id = "";
        });
    },
    selectImage() {
      this.currentImage = this.$refs.file[0].files[0];
      this.previewImage = URL.createObjectURL(this.currentImage);
    },
    getTemplate() {
      axios
        .get(
          API_URL +
            "api/school-result/get-student-result-details/" +
            this.template_id +
            "/" +
            this.student_id +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          this.top_left = res.data.top_left;
          this.top_right = res.data.top_right;
          this.subject = res.data.subject;
          this.subject_heading = res.data.subject_heading;
          this.subject_contents = res.data.subject_content;
          this.session_term = res.data.session_term[0];
          this.assessment_heading_one = res.data.assess_heading[0];
          this.assessment_heading_two = res.data.assess_heading[1];
          this.assessment_contents = res.data.assess_content;
          this.trait_key = res.data.trait;
          this.comments = res.data.comments;
          this.grades = res.data.grades;
          this.student_user = res.data.student_user;
        });
    },
    getMoreSchoolDetails() {
      if (this.school_id) {
        axios
          .get(
            API_URL +
              "api/result-template/get-more-school-details/" +
              this.school_id +
              "/",
            { headers: this.headers }
          )
          .then((res) => {
            res.data.data.map((data) => {
              this.sch_details = data;
            });
          });
      }
    },
    submitTopLeftDetails() {
      this.is_top_left_heading_btn = true;
      this.is_top_left_heading_updating = "Updating, Please wait...";

      let form = this.$refs.form;
      let formDatas = [];
      let lenght = form.length - 1
      let x = 0;
      console.log('form form', form)
      for (x; x < lenght; x++) {
        formDatas.push({ id: form[x].id, value: form[x].value });
      }
      axios
        .post(
          API_URL + "api/school-result/update-top-left-heading/",
          { formDatas, result_id: this.result_id },
          { headers: this.headers }
        )
        .then((res) => {
          this.getTemplate();

          setTimeout(() => {
            this.is_top_left_heading_updating = "";
            this.is_top_left_heading_btn = false;
          }, 3000);
        });
    },
    submitTopRightDetails() {
      this.is_top_right_heading_btn = true;
      this.is_top_right_heading_updating = "Updating, Please wait...";

      let form = this.$refs.form_top_right;
      let formDatas = [];
      let lenght = form.length - 1
      let x = 0;
      console.log('form form', form)
      for (x; x < lenght; x++) {
        if (form[x].readOnly == false){
          formDatas.push({ id: form[x].id, value: form[x].value });
        }
      }
      axios
        .post(
          API_URL + "api/school-result/update-top-right-heading/",
          { formDatas, result_id: this.result_id },
          { headers: this.headers }
        )
        .then((res) => {
          this.getTemplate();

          setTimeout(() => {
            this.is_top_right_heading_updating = "";
            this.is_top_right_heading_btn = false;
          }, 3000);
        });
    },
    submitSubjects() {
      this.is_top_subject_btn = true;
      this.is_top_subject_updating = "Updating, Please wait...";

      let form = this.$refs.form2;
      let subject_length = this.subject_contents.length * 5;
      let x = 0;
      let formDatas = [];

      for (x; x < subject_length; x++) {
        formDatas.push({
          id: form[x].id,
          name: form[x].name,
          value: form[x].value,
        });
      }

      axios
        .post(
          API_URL + "api/school-result/update-result-subjects/",
          { formDatas, result_id: this.result_id },
          { headers: this.headers }
        )
        .then((res) => {
          this.getTemplate();

          setTimeout(() => {
            this.is_top_subject_updating = "";
            this.is_top_subject_btn = false;
          }, 3000);
        });
    },
    // Get Assessent One Details
    submitAssessentOne(id, heading_id, ranting, title) {
      this.is_assest_one_btn = true;
      this.is_assest_one_updating = "Updating, Please wait...";

      this.update_assesOne_modal = [];
      this.$refs.updateAssessOneModal.show();
      this.update_assesOne_modal = [
        id,
        heading_id,
        ranting,
        title,
        this.result_id,
      ];

      setTimeout(() => {
        this.is_assest_one_btn = false;
        this.is_assest_one_updating = "";
      }, 3000);
    },
    // Update Assessent One Details
    assesseOneUpdated({ ranting, id }) {
      let checkbox = "checkbox" + ranting + id;
      document.getElementById(checkbox).checked = true;
    },
    // Get Assessent Two Details
    submitAssessentTwo(id, heading_id, ranting, title) {
      this.is_assesst_two_btn = true;
      this.is_assesst_two_updating = "Updating, Please wait...";

      this.update_assesTwo_modal = [];
      this.$refs.updateAssessTwoModal.show();
      this.update_assesTwo_modal = [
        id,
        heading_id,
        ranting,
        title,
        this.result_id,
      ];

      setTimeout(() => {
        this.is_assesst_two_btn = false;
        this.is_assesst_two_updating = "";
      }, 3000);
    },
    // Update Assessent Two Details
    assesseTwoUpdated({ ranting, id }) {
      let checkbox = "checkbox" + ranting + id;
      document.getElementById(checkbox).checked = true;
    },
    submitComments() {
      this.is_comment_btn = true;
      this.is_comment_updating = "Updating, Please wait...";

      let form = this.$refs.from8;
      let x = 0;
      let form_lenght = form.length - 1;
      const formData = [];
      for (x; x < form_lenght; x++) {
        if (form[x].name != "file") {
          formData.push({
            id: form[x].id,
            name: form[x].name,
            value: form[x].value,
          });
        }
      }

      axios
        .post(
          API_URL + "api/school-result/update-comments/",
          { formData, result_id: this.result_id },
          { headers: this.headers }
        )
        .then((res) => {
          this.getTemplate();

          setTimeout(() => {
            this.is_comment_updating = "";
            this.is_comment_btn = false;
          }, 3000);
        });
    },
    uploadSignature(id) {
      if (this.currentImage == null) {
        alert(
          "Please your Signature before your Click on " +
            '"UPDATE SIGNATURE"' +
            " button"
        );
        return;
      }

      this.is_comment_btn = true;
      this.is_comment_img_updating = "Updating, Please wait...";

      // Initialize the form data
      let formData = new FormData();

      //  Add the form data we need to submit
      formData.append("id", id);
      formData.append("file", this.currentImage);
      formData.append("result_id", this.result_id);

      axios
        .post(
          API_URL + "api/school-result/update-comments-signature/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.getTemplate();
          if (res.data.img) {
            let URL = API_URL.slice(0, -1);
            document.getElementById("update_signature").src =
              URL + res.data.img;
          }

          setTimeout(() => {
            this.is_comment_img_updating = "";
            this.is_comment_btn = false;
            this.previewImage = null;
            this.currentImage = null;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
#admin-veiw-accounts {
  background-color: white !important;
  padding: 30px !important;
}
#logo_school_name {
  margin-bottom: 30px !important;
  border: 0.5px solid #cfcfcf !important;
  padding: 20px;
}
#student_details_section {
  border: 0.5px solid #cfcfcf !important;
  padding: 1px;
}
td {
  font-size: 16px !important;
  border: 1px solid #b0b0b0 !important;
  padding: 10px !important;
}
th {
  font-size: 16px !important;
}
#div_size {
  font-size: 18px !important;
  padding: 5px;
}
.text-primary {
  color: #000000 !important;
}
.glyphicon {
  font-size: 10px !important;
}
.bold-text {
  font-weight: bold !important;
}
i {
  font-size: 12px !important;
  margin-right: 5px !important;
  margin-left: -2px !important;
  cursor: pointer !important;
}
input {
  height: 30px !important;
  background-color: white !important;
}
p {
  font-size: 14px;
}
input {
  color: #383637;
}
</style>
