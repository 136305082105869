<template>
  <div id="student-view-result">
    <div class="row">
      <h1>Student View Result</h1>
  </div>
  <!-- <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="card card-chart">
          <div class="card-header card-header-success" style="margin-top:15px !important;">
            <h3 >Request for Result</h3>
          </div>
            <div class="card-body">
                <form @submit.prevent="getStudentResult" style="magin-top:30px !important ;">
                <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Student ID</label>
                          <input id="student_id" name="student_id" v-model="student_id" required="required" type="float" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Class</label>
                          <input id="class_" name="class_" v-model="class_" required="required" type="float" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">Year</label>
                          <input id="year_" name="year_" v-model="year_" required="required" type="float" class="form-control">
                        </div>
                      </div>
                  </div>
                   <button type="submit" class="btn btn-success" align="center">Submit Request</button>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
      </div>
      </div>
      </div> -->
      <div class="row" align="right">
        <div class="col-md-3 col-sm-3 col-xs-12"></div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <select
            @change="filterSession"
            v-model="filter_by_session"
            id="filter_by_session"
            class="form-control"
            style="margin-top: 10px"
          >
            <option value="" disabled selected>Filter by Session</option>
            <option value="">Remove Selection</option>
            <option
              v-for="item in results_list"
              :key="item.id"
              :value="item.sch_session"
            >
              {{ item.sch_session }}
            </option>
          </select>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <select
            @change="filterClass"
            v-model="filter_by_class"
            id="filter_by_class"
            class="form-control"
            style="margin-top: 10px"
          >
            <option value="" disabled selected>Filter by Class</option>
            <option value="">Remove Selection</option>
            <option
              v-for="item in results_list"
              :key="item.id"
              :value="item.class_room"
            >
              {{ item.class_room }}
            </option>
          </select>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12" style="margin-top: 10px">
          <!-- <label for="filter_by_terms"></label> -->
          <select
            @change="filterTerms"
            v-model="filter_by_terms"
            id="filter_by_terms"
            class="form-control"
          >
            <option value="" disabled selected>Filter by Terms</option>
            <option value="">Remove Selection</option>
            <option value="First Term">First Term</option>
            <option value="Second Term">Second Term</option>
            <option value="Third Term">Third Term</option>
          </select>
        </div>
      </div>
      <div class="row">
     <div class="col-xs-12">       
        <div class="card">
          <div class="card-header card-header-primary" style="margin-top:15px !important;">
            <h3>View Result</h3>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th >Session</th>
                <th >Class</th>
                <th >Term</th>
                 <th>View Result</th>
              </thead>
              <tbody>
                <tr v-for="item in results_list" v-bind:key="item.id">
                  <td>{{ item.sch_session }}</td>
                  <td>$ {{ item.class_room }}</td>
                  <td>{{ item.sch_terms }}</td>
                  <td><a style="color: #fff !important;" class="btn btn-success" @click.prevent="viewResult(item.result_id)">View Result</a></td>
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>
  
  </div>
</template>

<script>
/* eslint-disable */

import Vue from "vue";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../common/config";
import http from "../common/http-common";

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'BinaryTrading',
    components: {
      // BackendScripts
    },
    data () {
      return {
      student_id: '',
      class_: '',
      year_: '',
      results_list: [],
      headers: "",
      classes_data: "",
      session_data: "",
      filter_by_class: "",
      filter_by_terms: "",
      filter_by_session: "",
      school_id: "",
      student_id: "",
      username: "",
    };
  },
  created() {
    var { token, student_id, username} = JSON.parse(localStorage.getItem("userData"));
    this.headers = { Authorization: `Token ${token}` };

    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;
    this.student_id = student_id
    this.username = username

      this.getAllStudentResult()
      this. getClassAndSession()
    },
    methods: {
      async getClassAndSession() {
        await http
          .get(
            "students-reg/get-class-and-session/" + this.school_id + "/"
            //,{ headers: { "Content-Type": "multipart/form-data"  },}
          )
          .then((res) => {
            this.classes_data = res.data.data;
            this.session_data = res.data.data2;
          });
      },
      getAllStudentResult() {
        axios
          .get(
            API_URL +
              "api/school-result/get-all-student-result-list/" +
              this.school_id +
              "/" + this.student_id + "/",
            { headers: this.headers }
          )
          .then((res) => {
            this.results_list = res.data;
          });
      },
      viewResult(result_id){
        console.log('== result_id ',  result_id)
        this.$router.push('/student/view-student-results-details?result_id=' + result_id)
      },
      filterClass() {
        if (this.filter_by_class.length > 0) {
          this.results_list = this.results_list.filter(
            (res) => res.class_room == this.filter_by_class
          );
        } else {
          this.getAllStudentResult();
        }
      },
      filterTerms() {
        if (this.filter_by_terms.length > 0) {
          this.results_list = this.results_list.filter(
            (res) => res.sch_terms == this.filter_by_terms
          );
        } else {
          this.getAllStudentResult();
        }
      },
      filterSession() {
        if (this.filter_by_session.length > 0) {
          this.results_list = this.results_list.filter(
            (res) => res.sch_session == this.filter_by_session
          );
        } else {
          this.getAllStudentResult();
        }
      },
    },
  }
    

</script>

<style>
th {
  font-size: 18px !important;
}
tbody {
  font-size: 14px;
}

button {
  padding: 10px 35px !important;
  font-weight: bold !important;
  min-width: 120px ;
  font-size: 18px !important;
}

label {
  font-size: 14px !important;
  color: #5c5756 !important;
}
a {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>