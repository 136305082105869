import axios from "axios";
import API_URL from '../../common/config';

/* eslint-disable no-unused-vars */
// /* eslint-disable vue/no-parsing-error */
export default {
    state: {

    },
    mutations: {

    },
    actions: {
        async deleteTopLeft({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-top-left/', payload, { headers: headers });
            return result.data;
        },
        async deleteTopRight({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-top-right/', payload, { headers: headers });
            return result.data;
        },
        async deleteSubjectContents({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-subject-content/', payload, { headers: headers });
            return result.data;
        },
        async deleteAssessmentContentLeft({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-assessment-content-left/', payload, { headers: headers });
            return result.data;
        },
        async deleteAssessmentContentRight({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-assessment-content-right/', payload, { headers: headers });
            return result.data;
        },
        async deleteTraitUpdated({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-key-trait/', payload, { headers: headers });
            return result.data;
        },
        async deletecommentSection({ commit }, payload) {
            var token = JSON.parse(localStorage.getItem('userData')).token
            var headers = { 'Authorization': `Token ${token}` }

            const result = await axios.post(API_URL + 'api/result-template/delete-comment-sect/', payload, { headers: headers });
            return result.data;
        }
    },
    getters: {

    }
}