<template>
  <div id="admin-create-accounts">
    <div class="row">
      <h1>Admin Create Accounts</h1>
    </div>
    <div class="row"></div>
    <div class="row">
      <br />
      <hr />
      <br />
      <div class="col-md-6">
        <div class="card card-chart">
          <div class="card-header card-header-success">
            <div
              class="ct-chart"
              style="padding: 20px !important; margin-bottom: -15px !important"
            >
              <h4 class="card-title"><b>Create Student Account</b></h4>
            </div>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/admin/create-student-account"
                ><b> Click here to start</b></router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-chart">
          <div class="card-header card-header-primary">
            <div
              class="ct-chart"
              style="padding: 20px !important; margin-bottom: -15px !important"
            >
              <h4 class="card-title"><b>Create Teacher Account</b></h4>
            </div>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/admin/create-teacher-account"
                ><b> Click here to start</b></router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-chart">
          <div class="card-header card-header-success">
            <div
              class="ct-chart"
              style="padding: 20px !important; margin-bottom: -15px !important"
            >
              <h4 class="card-title"><b>Create Parent Account</b></h4>
            </div>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/admin/create-parent-account"
                ><b> Click here to start</b></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Create Account Links -->
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
  name: "AdminCreateAccounts",
  components: {
    // BackendScripts
  },
};
</script>

<style>
p {
  font-size: 14 !important;
}
</style>
