<template>
  <div id="admin-create-results">
    <div class="row">
      <h1>Admin Create Resutls</h1>
    </div>
    <div class="row"></div>
    <div class="row">
      <br />
      <hr />
      <br />
      <div class="col-md-12">
        <div class="card card-chart">
          <div class="card-header card-header-success">
            <div
              class="ct-chart"
              style="padding: 20px !important; margin-bottom: -15px !important"
            >
              <h4 class="card-title"><b>Create New Result Template</b></h4>
            </div>
          </div>
          <div class="card-footer" style="width: 100% !important">
            <div class="row" style="width: 100% !important">
              <!-- Outer Row -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="row">
                  <!-- Inner Row -->
                  <div class="colo-md-1 col-xs-12"></div>
                  <div class="col-md-10 col-xs-12">
                    <form @submit.prevent="createTemplate">
                      <div class="form-group">
                        <label class="">Select Class</label>
                        <select
                          class="form-control"
                          @click="onSelect()"
                          name="class_"
                          v-model="class_"
                          required
                        >
                          <option value="" disabled>Select Class...</option>
                          <option
                            v-for="item in class_list"
                            :key="item.class_room"
                            :value="item.class_room"
                          >
                            {{ item.class_room }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <br />
                        <label class="">Select Term</label>
                        <select
                          class="form-control"
                          name="class_"
                          v-model="sch_terms"
                          required
                        >
                          <option value="" disabled>Select Term...</option>
                          <option value="First Term">First Term</option>
                          <option value="Second Term">Second Term</option>
                          <option value="Third Term">Third Term</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <br />
                        <label class="">Select Session</label>
                        <select
                          class="form-control"
                          name="class_"
                          v-model="sch_session"
                          required
                        >
                          <option value="" disabled>Select Session...</option>
                          <option
                            v-for="item in session_list"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-block btn-primary"
                          style="padding-left: 15px !important"
                        >
                          Create Template
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="colo-md-1 col-xs-12"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="row">
                  <!-- Inner Row -->
                  <!-- start add classes -->
                  <div class="colo-md-1 col-xs-12"></div>
                  <div class="col-md-10 col-xs-12">
                    <form @submit.prevent="addMoreClasses">
                      <div
                        class="form-group"
                        style="
                          margin-top: 25px;
                          padding-bottom: 10px !important;
                        "
                      >
                        <p style="color: red">
                          Please Note that Classes Added here are Case
                          Sensitive.
                        </p>
                        <label
                          for="add-classess"
                          style="padding-bottom: 10px !important"
                          >Add Classes</label
                        >
                        <input
                          id="add-classess"
                          class="form-control"
                          name="add_class"
                          v-model="add_class"
                          required
                          placeholder="Entter Additional Class..."
                        />
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-success"
                          style="padding-left: 15px !important"
                        >
                          Add More Class
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="colo-md-1 col-xs-12"></div>
                  <!-- end add classes -->
                  <!-- start remove classes -->
                  <div class="colo-md-1 col-xs-12"></div>
                  <div class="colo-md-10 col-xs-12">
                    <hr />
                    <p style="color: red">
                      Please Note that classes Removed here can cause you to
                      loose some Fliteration Features in other places in this
                      portal.
                    </p>
                    <label for="remove-calsses">
                      Select and Remove Classes
                    </label>
                    <select
                      class="form-control"
                      id="remove-calsses"
                      v-model="remove_classess"
                    >
                      <option value="" selected disabled>
                        Select Class...
                      </option>
                      <option
                        v-for="(item, index) in class_list"
                        :key="index"
                        :value="item.class_room"
                      >
                        {{ item.class_room }}
                      </option>
                    </select>
                    <hr />
                    <button
                      class="btn btn-primary"
                      @click.prevent="removeClasses"
                    >
                      Remove Class
                    </button>
                  </div>
                  <div class="colo-md-1 col-xs-12"></div>
                  <!-- end remove classes -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xs-12">
        <div class="card card-chart">
          <div class="card-header card-header-success">
            <div
              class="ct-chart"
              style="padding: 20px !important; margin-bottom: -15px !important"
            >
              <h4 class="card-title">
                <b>View, Edit & Clone Exisiting Result Template</b>
              </h4>
            </div>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <!-- <router-link to="/admin/edit-exisiting-result-template"><b> Click here to start</b></router-link> -->
              <!-- <router-link to="/admin/veiw-result-template"><b> Click here to start</b></router-link> -->
              <router-link to="/admin/admin-view-all-results"
                ><b> Click here to start</b></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import { API_URL } from "../common/config";

export default {
  name: "AdminCreateResutls",
  components: {
    // BackendScripts
  },
  data() {
    return {
      year: new Date().getFullYear(),
      class_: "",
      sch_terms: "",
      sch_session: "",
      add_class: "",
      school_id: "",
      is_template_exisit: null,
      class_list: [],
      session_list: [],
      school_logo: "",
      school_name: "",
      school_slug: "",
      sub_domain: "",
      main_school_url: "",
      domainDetails: "",
      headers: "",
      remove_classess: "",
    };
  },
  created() {
    const { school_id } = JSON.parse(localStorage.getItem("domainDetails"));
    this.school_id = school_id;

    var token = JSON.parse(localStorage.getItem("userData")).token;
    this.headers = { Authorization: `Token ${token}` };

    this.getClassesList();
    //this.checkResultTemplateExisit()
  },
  methods: {
    getClassesList() {
      axios
        .get(
          API_URL + "api/class-room/get-classes-list/" + this.school_id + "/",
          { headers: this.headers }
        )
        .then((res) => {
          this.class_list = res.data.data;
          this.session_list = res.data.session_list;
        });
    },
    removeClasses() {
      console.log("remove_classess ", this.remove_classess);
    },
    onSelect() {
      if (this.class_) {
        //this.checkResultTemplateExisit()
      }
    },
    checkResultTemplateExisit() {
      axios
        .get(
          API_URL +
            "api/result-template/check-template-exisit/" +
            this.school_id +
            "/" +
            this.class_ +
            "/",
          { headers: this.headers }
        )
        .then((res) => {
          //console.log('== template exisit ', res.data.result_check)
          this.is_template_exisit = res.data;
        });
    },
    createTemplate() {
      if (this.class_ && this.sch_terms && this.sch_session) {
        console.log("==post Loading...");
        const postData = {
          school_id: this.school_id,
          class_room: this.class_,
          sch_terms: this.sch_terms,
          sch_session: this.sch_session,
        };
        axios
          .post(
            API_URL + "api/result-template/check-template-exisit/",
            postData,
            { headers: this.headers }
          )
          .then((res) => {
            //
            if (res.data.result_check) {
              const template_id = res.data.template_id;

              alert("This Template already Exist, Do you want to Edit it?");
              this.$router.push(
                "/admin/edit-exisiting-result-template/" + template_id + "/"
              );
            } else {
              const classDetails = {
                sch_terms: this.sch_terms,
                sch_session: this.sch_session,
              };
              // i should have use session here here but let me use localStorage
              // for now to hold the class details
              localStorage.setItem(
                "classDetails",
                JSON.stringify(classDetails)
              );

              this.$router.push(
                "/admin/create-new-result-template?" +
                  "class_room=" +
                  this.class_
              );
            }
          });
      }
    },
    addMoreClasses() {
      //console.log('== addMoreClasses ', this.add_class)
      var postData = {
        class_room: this.add_class,
        school_id: this.school_id,
      };
      axios
        .post(API_URL + "api/class-room/add-class-list/", postData, {
          headers: this.headers,
        })
        .then((res) => {
          //console.log('== add-class-list Z', res.data.data)
          // result: Class was Successfully Added!
          alert(res.data.data);
          this.getClassesList();
          this.add_class = "";
        });
    },
  },
};
</script>

<style>
label {
  font-size: 16px !important;
}
</style>
